import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../include/Navbar";
import Label from "../common/Label";
import Input from "../common/Input";
import Footer from "../include/Footer";
import LoaderComponent from "../common/LoaderComponent";
import { postApi } from "../../near/utils/localUtils";

function OTP(props) {
  const navigate = useNavigate();

  const buttonPadding = {
    padding: "1.4rem 0",
    borderRadius: "4px",
    margin: "2rem 0 5rem 0",
    minWidth: "35rem",
  };

  const handlSendOtp = async () => {
    // send otp to user email
    const formData = new FormData();
    formData.append("email", useremail);

    let otpGenApi = "frontapi/generate_otp_fan";

    if (isMember && !isFan) {
      otpGenApi = "frontapi/generate_otp_member";
    }

    postApi(otpGenApi, formData)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(async () => {
    if (!useremail.length) {
      navigate("/");
      return;
    }

    handlSendOtp();
  }, []);

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  const [useremail, setuseremail] = useState(
    localStorage.getItem("otpemail") || sessionStorage.getItem("otpemail")
  );

  const [loading, setloading] = useState(false);

  const [otp, setotp] = useState([]);

  const handleChange = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case "otp0":
        var newotp = otp;
        newotp[0] = value || "";
        setotp(newotp);
        break;
      case "otp1":
        var newotp = otp;
        newotp[1] = value || "";
        setotp(newotp);
        break;
      case "otp2":
        var newotp = otp;
        newotp[2] = value || "";
        setotp(newotp);
        break;
      case "otp3":
        var newotp = otp;
        newotp[3] = value || "";
        setotp(newotp);
        break;
      case "otp4":
        var newotp = otp;
        newotp[4] = value || "";
        setotp(newotp);
        break;
      case "otp5":
        var newotp = otp;
        newotp[5] = value || "";
        setotp(newotp);

        // verify with last input
        if (otp.join("").length == 6) {
          handleVerifyOtp();
        }
        break;
      default:
        break;
    }
  };

  const handleVerifyOtp = async (e) => {
    if (e) {
      e.preventDefault();
    }
    setloading(true);
    const formData = new FormData();
    formData.append("otp", otp.join(""));
    formData.append("email", useremail);

    let otpVerifyApi = "frontapi/check_otp_fan";
    let navigateTo = "/fan";
    if (isMember && !isFan) {
      otpVerifyApi = "frontapi/check_otp_member";
      navigateTo = "/member";
    }

    postApi(otpVerifyApi, formData)
      .then((res) => {
        setloading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "success") {
          alert("Welcome");

          if (isMember) {
            const {
              musicians,
              subscribers,
              mus_playlist,
              mus_playlist_group,
              tips_anonymous,
              tips_registered_fan,
              followers,
              token,
              subscribe_package,
            } = data[0];

            const userinfoData = musicians[0];
            const otherinfoData = {
              subscribers,
              mus_playlist,
              mus_playlist_group,
              tips_anonymous,
              tips_registered_fan,
              followers,
              token,
              subscribe_package,
            };

            // set session storage only
            sessionStorage.setItem("userinfo", JSON.stringify(userinfoData));
            sessionStorage.setItem("otherinfo", JSON.stringify(otherinfoData));
            sessionStorage.setItem("isMember", true);
            sessionStorage.setItem("isLoggedIn", true);
            sessionStorage.setItem("isFan", false);

            if (localStorage.getItem("otpemail")) {
              localStorage.setItem("userinfo", JSON.stringify(userinfoData));
              localStorage.setItem("otherinfo", JSON.stringify(otherinfoData));
              localStorage.setItem("isMember", true);
              localStorage.setItem("isLoggedIn", true);
              localStorage.setItem("isFan", false);
            }
          } else if (isFan) {
            sessionStorage.setItem("userinfo", JSON.stringify(data[0].fan[0]));
            sessionStorage.setItem(
              "otherinfo",
              JSON.stringify({ token: data[0].token })
            );
            sessionStorage.setItem("isFan", true);
            sessionStorage.setItem("isMember", false);
            sessionStorage.setItem("isLoggedIn", true);

            if (localStorage.getItem("otpemail")) {
              localStorage.setItem("userinfo", JSON.stringify(data[0].fan[0]));
              localStorage.setItem(
                "otherinfo",
                JSON.stringify({ token: data[0].token })
              );
              localStorage.setItem("isFan", true);
              localStorage.setItem("isMember", false);
              localStorage.setItem("isLoggedIn", true);
            }
          }
          navigate(navigateTo);
        } else {
          alert(data[0].message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function tabChange(val) {
    let ele = document.querySelectorAll("input");
    if (ele[val - 1].value != "") {
      ele[val].focus();
    } else if (ele[val - 1].value == "") {
      if (ele[val - 2]) {
        ele[val - 2].focus();
      }
    }
  }

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="otp-container container">
          <div className="section-title"> OTP Verification</div>

          <img src="/images/otp-secure.svg" alt="" />

          <div className="description">
            Enter the OTP sent to{" "}
            <span className="email-address"> {useremail} </span>
          </div>
          <div className="otp-wrapper">
            <form onSubmit={handleVerifyOtp}>
              <div className="d-flex-jc">
                <input
                  type="text"
                  className="form-control"
                  onKeyUp={() => tabChange(1)}
                  placeholder=""
                  maxLength="1"
                  id="otp0"
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  onKeyUp={() => tabChange(2)}
                  placeholder=""
                  maxLength="1"
                  id="otp1"
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  onKeyUp={() => tabChange(3)}
                  placeholder=""
                  maxLength="1"
                  id="otp2"
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  onKeyUp={() => tabChange(4)}
                  placeholder=""
                  maxLength="1"
                  id="otp3"
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  onKeyUp={() => tabChange(5)}
                  placeholder=""
                  maxLength="1"
                  id="otp4"
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  onKeyUp={() => tabChange(6)}
                  placeholder=""
                  maxLength="1"
                  id="otp5"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="description">
                Didn't you receive the OTP?{" "}
                <a className="link" onClick={handlSendOtp}>
                  {" "}
                  Resend OTP{" "}
                </a>
              </div>
              <div className="d-flex-jc">
                {loading ? (
                  <Link
                    to="/otp-verification"
                    className="btn-primary-full mr-0 text-center"
                    style={buttonPadding}
                  >
                    <LoaderComponent />
                  </Link>
                ) : (
                  <Link
                    to="/otp-verification"
                    className="btn-primary-full mr-0 text-center"
                    style={buttonPadding}
                    onClick={handleVerifyOtp}
                  >
                    Verify
                  </Link>
                )}
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default OTP;
