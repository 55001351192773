import React, { Component } from "react";
import Navbar from "../../include/Navbar";
import About from "../home/About";
import Members from "../home/Members";
import Events from "../home/Events";
import Footer from "../../include/Footer";
import Banner from "../home/Banner";
import { useNavigate } from "react-router";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn:
        JSON.parse(localStorage.getItem("isLoggedIn")) ||
        JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
        false,
      isMember:
        JSON.parse(localStorage.getItem("isMember")) ||
        JSON.parse(sessionStorage.getItem("isMember")) ||
        false,
      isFan:
        JSON.parse(localStorage.getItem("isFan")) ||
        JSON.parse(sessionStorage.getItem("isFan")) ||
        false,
      bannerInfo: [],
      membersList: [],
      eventsInfo: [],
      aboutUs: "",
    };
    this.updateState = this.updateState;
  }

  updateState(isLoggedIn) {
    this.state({ ...this.state, isLoggedIn });
  }

  componentDidMount() {
    let userinfopersonal =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));
    let userotherinfo =
      JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"));

    const loggedIn =
      JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn"));
    const isMember =
      JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember"));

    if (!isMember || !loggedIn) {
      window.location.href = "/member/sign-in";
      //  window.location.href this.navigate();
    }

    fetch("https://dev.tipjarlove.com/frontapi/index")
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          ...this.state,
          bannerInfo: data[0],
          membersList: data[1],
          eventsInfo: data[2],
          aboutUs: data[3],
        });
        // console.log(JSON.parse(data[0][0].lnk));
        // const abc = data[0][1].title;
        // console.log(abc, data[0][1]);
      })
      .catch((err) => {
        console.log("err : ", err);
      });
  }

  render() {
    return (
      <div>
        <Navbar
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
          updateState={this.updateState}
        />
        <Banner
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
          bannerInfo={this.state.bannerInfo}
        />
        <Members
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
          membersList={this.state.membersList}
        />
        <Events
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
          eventsInfo={this.state.eventsInfo}
        />
        <About
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
          aboutUs={this.state.aboutUs}
        />
        <Footer />
      </div>
    );
  }
}

export default Index;
