import React from "react";
import LoadingScreen from "react-loading-screen";
// const logo = require("../../../public/logo192.png");

// import tipjarlogo from "../../../public/images/tipjarlive_logo.svg";

const LoadingScreenComponent = ({
  text = "Please wait! We are processing your payment...",
}) => {
  return (
    //...
    <LoadingScreen
      loading={true}
      bgColor="#f1f1f1"
      spinnerColor="#9ee5f8"
      textColor="#676767"
      logoSrc="/images/tip-jar-live_black.png"
      text={text}
    >
      {/* // ... // here loadable content // for example, async data // */}
      <div></div>
    </LoadingScreen>
  );
};

export default LoadingScreenComponent;

// import React from "react";
// import Loading from "react-fullscreen-loading";

// function LoadingScreen() {
//   return (
//     <>
//       <Loading loading background="#344910" loaderColor="#eb3461" />
//     </>
//   );
// }
// export default LoadingScreen;
