import React, { useEffect, useState } from "react";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";
import { useNavigate } from "react-router-dom";
import { postApi } from "../../../near/utils/localUtils";

function FollowerCampaignHistory(props) {
  const navigate = useNavigate();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  useEffect(() => {
    if (!isMember || !isLoggedIn) {
      navigate("/member/sign-in");
      return;
    }
  }, []);

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="supporters-dashboard-wrapper container">
          <div className="section-header">
            <h1 className="section-title"> Campaign History </h1>
          </div>
          {FollowerCampaignHistoryTable()}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export const FollowerCampaignHistoryTable = () => {
  // campaign list
  const [campaigns, setcampaigns] = useState([]);

  useEffect(() => {
    const userinfo =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));
    const userotherinfo =
      JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"));

    const formData = new FormData();
    // auth
    formData.append("token", userotherinfo.token);
    formData.append("user_id", userinfo.id);
    
    postApi("frontapi/follower_campaign", formData)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setcampaigns(data[0].campaigns);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  }, []);

  return (
    <table className="table table-red">
      <thead>
        <tr>
          <th> Subject</th>
          <th> Total Messages</th>
          <th className="text-center"> Message Status</th>
        </tr>
      </thead>
      <tbody>
        {campaigns.length ? (
          <>
            {campaigns.map((cmpn) => (
              <tr key={cmpn.id}>
                <td>{cmpn.subject}</td>
                <td> {cmpn.text}</td>
                <td className="text-right">
                  {cmpn.queue_status == "1" ? (
                    <a href="#" className="status-active">
                      {" "}
                      Active{" "}
                    </a>
                  ) : (
                    <a href="#" className="status-inprogress">
                      {" "}
                      In Progress{" "}
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </>
        ) : null}
      </tbody>
    </table>
  );
};

export default FollowerCampaignHistory;
