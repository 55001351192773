import React, { useEffect, useState } from "react";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";

import {
  API_BASE_URL,
  fetchMoonPay,
  getApi,
  post,
  postApi,
} from "../../../near/utils/localUtils";
import LoaderComponent from "../../common/LoaderComponent";
import ModalComponent from "../../common/ModalComponent";
import { sendJargon, sendNear } from "../../../near/utils/browserUtils";
import {
  calculateNearToJargon,
  jargonDecimals,
} from "../../../near/utils/exchangRates";

import { EventsListView } from "../Events";
import StripeComponent from "../../../stripe/Stripe";
import LoadingScreenComponent from "../../common/LoadingScreen";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

var QRCode = require("qrcode.react");

function EventDetails(props) {
  const { id } = useParams();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  const [userinfo, setuserinfo] = useState(
    JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"))
  );
  const [usertherinfo, setusertherinfo] = useState(
    JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"))
  );

  // notification modal
  const [modalInfo, setModalInfo] = useState({});

  // ticker pricing
  const [ticketinNear, setticketinNear] = useState("00");
  const [ticketinJargon, setticketinJargon] = useState("00");

  // event information
  const [eventDetails, seteventDetails] = useState({});

  // request song modal list
  const [reqSongList, setReqSongList] = useState([]);
  const [requestedSong, setrequestedSong] = useState([]);
  const [reqsongcurrency, setreqsongcurrency] = useState("near");
  const [nearToUsdRates, setNearToUsdRates] = useState(0);

  // for popular events
  const [eventsList, seteventsList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [paymentLoading, setpaymentLoading] = useState(false); // for trx only

  // stripe public key from server
  const [stripePubKey, setstripePubKey] = useState("");

  // for map
  const [selected, setSelected] = useState({});
  const onSelect = (item) => {
    setSelected(item);
  };

  useEffect(async () => {
    const userinfo =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));

    // popular events lis
    // list of all events
    getApi("frontapi/event_list_fan")
      .then((data) => {
        if (data[0].status != "error") {
          seteventsList(data[0].records);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // =================  for event details  ==============
    // for logged in user events
    if (userinfo && isFan) {
      const userotherinfo =
        JSON.parse(localStorage.getItem("otherinfo")) ||
        JSON.parse(sessionStorage.getItem("otherinfo"));

      const formData = new FormData();
      formData.append("user_id", userinfo.id || userinfo.user_id);
      formData.append("token", userotherinfo.token);
      formData.append("event_id", id);

      postApi(`frontapi/EventDetails`, formData)
        .then((res) => res.json())
        .then((data) => {
          const currentEventInfo = data[0].row;
          seteventDetails(currentEventInfo);
          // near trx dist
          handleNearPaymentsRefChain(currentEventInfo);
          const ticketinusd = currentEventInfo.ticketPrice || "5";
          ticketRateConversion(ticketinusd);

          // request songs/playlist list after event loaded
          const reqSongFormData = new FormData();
          reqSongFormData.append("mid", currentEventInfo.mid);
          reqSongFormData.append("fan_id", userinfo.id || userinfo.user_id);

          reqSongFormData.append("user_id", userinfo.id || userinfo.user_id);
          reqSongFormData.append("token", userotherinfo.token);
          postApi("frontapi/playlist_fan", reqSongFormData)
            .then((res) => res.json())
            .then((data) => {
              // console.log(data);
              setReqSongList(data[0].mus_playlist);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const formData = new FormData();
      formData.append("event_id", id);

      postApi(`frontapi/EventDetailsPublic`, formData)
        .then((res) => res.json())
        .then((data) => {
          seteventDetails(data[0].row);
          handleNearPaymentsRefChain(data[0].row);
          const ticketinusd = data[0].row.live_show_amount || "10";
          ticketRateConversion(ticketinusd);
        });
    }
    // fetch stripe pub key from server
    const stripeKey = await getApi("frontapi/get_stripe_key");
    setstripePubKey(stripeKey[0].stripe_public_key);
  }, [id]);

  // request a free song
  const handleRequestSongSubmit = async (e) => {
    e.preventDefault();

    if (requestedSong.amount == 0) {
      setLoading(true);

      const formData = new FormData();
      formData.append("mid", eventDetails.mid);
      formData.append("email", userinfo.email);
      formData.append("page", "request_for_song");
      formData.append("request_id", requestedSong.playId);
      formData.append("mus_amount", "0");
      formData.append("tip_amount", "0");
      formData.append("total_vote", 0);
      formData.append("fid", userinfo.id);
      formData.append("user_id", userinfo.id);
      formData.append("token", usertherinfo.token);

      postApi("frontapi/free_request_for_song", formData)
        .then((res) => {
          setLoading(false);
          return res.json();
        })
        .then((data) => {
          handlStripeModalClose();
          if (data.status == "success" || data.status == "succeeded") {
            alert("Thank you! Transaction Successful!");
          } else if (data.status == "error") {
            alert(data.message);
          }
        })
        .catch((err) => {
          console.log("Err", err);
        });
    }
  };

  // for near trx chain
  const handleNearPaymentsRefChain = async (eventinfo) => {
    if (window.location.href.includes("transactionHashes")) {
      const txHash = window.location.href.split("transactionHashes=")[1];

      // send reward if trx was a tip
      if (
        localStorage.getItem("trxtype") == "ticket" ||
        localStorage.getItem("trxtype") == "requestsongnear"
      ) {
        localStorage.removeItem("trxtype");

        setpaymentLoading(true);

        const refFormdata = new FormData();
        refFormdata.append("userId", userinfo.id || userinfo.user_id);
        refFormdata.append("fanName", `${userinfo.fname} ${userinfo.lname}`);
        refFormdata.append("token", usertherinfo.token);
        refFormdata.append("memberId", eventinfo.mid);
        refFormdata.append("refCode", eventinfo.reffral_code);
        refFormdata.append("memberSlug", eventinfo.slug);
        if (localStorage.getItem("trxtype") == "requestsongnear") {
          refFormdata.append("purpose", "Request Song");
        }
        refFormdata.append("txHash", txHash);
        refFormdata.append("memberNearId", eventinfo.near_id);
        refFormdata.append(
          "accountId",
          window.walletConnection.account().accountId
        );

        // send ref chain inputs and near trx hash for reward distribution
        post(refFormdata, "near-tip")
          .then((res) => {
            setpaymentLoading(false);
            return res.json();
          })
          .then((data) => {
            if (data.status == "success" || data.status == "successful") {
              window.location.href = window.location.href.split("?")[0];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (localStorage.getItem("trxtype") == "jargonticket") {
        const jargonAmount = localStorage.getItem("jargonamount");

        setpaymentLoading(true);

        const refFormdata = new FormData();
        refFormdata.append("userId", userinfo.id || userinfo.user_id);
        refFormdata.append("fanName", `${userinfo.fname} ${userinfo.lname}`);
        refFormdata.append("token", usertherinfo.token);
        refFormdata.append("memberId", eventinfo.mid);
        refFormdata.append("refCode", eventinfo.reffral_code);
        refFormdata.append("memberSlug", eventinfo.slug);
        refFormdata.append("memberId", eventinfo.mid);
        refFormdata.append("txHash", txHash);
        refFormdata.append("jargonamount", jargonAmount);
        refFormdata.append("memberNearId", eventinfo.near_id);
        refFormdata.append(
          "accountId",
          window.walletConnection.account().accountId
        );

        // send ref chain inputs and near trx hash for reward distribution
        post(refFormdata, "jargon-tip")
          .then((res) => {
            setpaymentLoading(true);
            return res.json();
          })
          .then((data) => {
            window.location.href = window.location.href.split("?")[0];
          })
          .catch((err) => {
            console.log(err);
          });
        localStorage.removeItem("trxtype");
        localStorage.removeItem("jargonamount");
      }
    } else if (window.location.href.includes("errorCode=")) {
      window.location.href = window.location.href.split("?")[0];
      localStorage.removeItem("tip");
      localStorage.removeItem("jargontip");
      localStorage.removeItem("jargonticket");
      localStorage.removeItem("ticket");
      localStorage.removeItem("jargonamount");
    }
  };

  // set event ticket price
  const ticketRateConversion = async (usdTicket) => {
    const usdToNear = await fetchMoonPay();
    setNearToUsdRates(usdToNear);
    const liveNearToJargonRate = await calculateNearToJargon(usdToNear);

    setticketinNear(usdTicket / usdToNear);
    setticketinJargon((usdTicket / usdToNear) * liveNearToJargonRate);
  };

  SwiperCore.use([Navigation]);

  const handlStripeModalClose = () => {
    document.getElementById("usd-tip-modal-close-btn").click();
    document.getElementById("reqsong-close-btn").click();
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "reqsong":
        setrequestedSong(reqSongList.find((song) => value == song.id));
        break;
      case "reqsongcurrency":
        setreqsongcurrency(value);
        break;
      default:
        break;
    }
  };

  // ===================== buy tickers ===================== //
  const handleBuyTicketInNear = async (e) => {
    e.preventDefault();
    setLoading(true);
    // for tip and jargon purchase
    localStorage.setItem("trxtype", "ticket");
    sendNear(window.near.config.masterAccount, ticketinNear.toString());
  };

  const handleBuyTicketInJagon = async (e) => {
    e.preventDefault();

    localStorage.setItem("trxtype", "jargonticket");
    localStorage.setItem(
      "jargonamount",
      Math.round(ticketinJargon * jargonDecimals)
    );

    setLoading(true);
    sendJargon(
      // to be replaced by member's account id
      window.near.config.masterAccount,
      Math.round(ticketinJargon * jargonDecimals)
    );
  };

  const handleRequestSongNEAR = async () => {
    if (!window.walletConnection.isSignedIn()) {
      alert("Please connect you NEAR account!");
      return;
    }

    localStorage.setItem("trxtype", "requestsongnear");
    sendNear(
      window.near.config.masterAccount,
      (
        Math.round((requestedSong.amount / nearToUsdRates) * 100) / 100
      ).toString()
    );
  };

  // ======================================================== //

  const handleModalClose = () => {
    setModalInfo(false);
  };

  const showNotification = (err = "", loading = true) => {
    if (err) {
      if (loading) {
        setLoading(false);
      }
      setModalInfo({
        show: true,
        type: "danger",
        heading: "Failed",
        body: err,
      });
    } else {
      if (loading) {
        setLoading(false);
      }
      setModalInfo({
        show: true,
        type: "primary",
        heading: "Thank you!",
        body: "Tip Transferred",
      });
    }
    setTimeout(() => {
      handleModalClose();
    }, 1000);
  };

  if (paymentLoading) {
    return <LoadingScreenComponent />;
  } else {
    if (eventDetails.name) {
      return (
        <div>
          <ModalComponent
            modalInfo={modalInfo}
            handleClose={handleModalClose}
          />
          <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
          <main className="main-container">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="swiper event-detail-swipper">
                    <div className="swiper-wrapper">
                      <Swiper
                        navigation={true}
                        loop={true}
                        autoplay={{
                          delay: 5000,
                        }}
                      >
                        <SwiperSlide>
                          <img
                            src={
                              eventDetails.featured_img.includes("http")
                                ? `${eventDetails.featured_img}`
                                : `${API_BASE_URL}/${eventDetails.featured_img}`
                            }
                            alt=""
                          />
                        </SwiperSlide>

                        {eventDetails.pics.split(",").map((eventPic, idx) => (
                          <SwiperSlide key={idx}>
                            <img
                              src={
                                eventPic.includes("https")
                                  ? eventPic
                                  : `${API_BASE_URL}/${eventPic}`
                              }
                              alt=""
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                  <div className="share">
                    <div className="d-flex">
                      <p> Share </p>
                      <a
                        href=""
                        onClick={() => {
                          window.open(
                            "https://www.facebook.com/sharer/sharer.php?u=" +
                              encodeURIComponent(window.location.href),
                            "facebook-share-dialog",
                            "width=626,height=436"
                          );
                        }}
                      >
                        {" "}
                        <i className="fab fa-facebook"></i>{" "}
                      </a>
                      <a href="">
                        {" "}
                        <i className="fab fa-instagram-square"></i>{" "}
                      </a>
                      <a href="">
                        {" "}
                        <i className="fab fa-twitter-square"></i>{" "}
                      </a>
                    </div>
                    <div>
                      {eventDetails.request_song == "1" ? (
                        <>
                          {/* Request song is only for fans */}
                          {isFan ? (
                            <a
                              href="#"
                              className="request-song"
                              data-toggle="modal"
                              data-target="#request_song"
                              data-backdrop="static"
                            >
                              <i className="fas fa-music"></i> Request a Song
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="request-song"
                              data-toggle="modal"
                              // data-target="#request_song"
                              // data-backdrop="static"
                              onClick={() => {
                                alert("Please login as fan to request a song!");
                              }}
                            >
                              <i className="fas fa-music"></i> Request a Song
                            </a>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="event-details-content">
                    <div className="card-content">
                      <div className="card-description">
                        <h1 className="card-title">{eventDetails.title}</h1>
                        <p className="card-text">{eventDetails.content}</p>
                      </div>
                      <div className="d-flex-sb d-flex-ai">
                        <p className="location">
                          <i className="fa fa-calendar-alt"></i>
                          <span>{eventDetails.datetime.split(" ")[0]}</span>
                        </p>
                        <p className="time">
                          <i className="fa fa-clock"></i>
                          <span>{eventDetails.time}</span>
                        </p>
                      </div>
                      <p className="date">
                        <i className="fa fa-map-marker-alt"></i>
                        <span>{eventDetails.venue}</span>
                      </p>
                      <div className="card-action">
                        <div className="amount">
                          {/* <img src={eventDetails.qrcode} alt="" />ß */}

                          <QRCode value={window.location.href} />

                          <p> Scan the QR code to share this event </p>

                          {eventDetails.live_show_amount ? (
                            <>
                              <div className="line-with-text">
                                <p className="mb-0 d-block">
                                  <span>or</span>
                                </p>
                              </div>
                              <a
                                href="#"
                                className="btn-secondary-full"
                                data-toggle="modal"
                                data-target="#enter_amount_usd"
                                data-backdrop="static"
                              >
                                Tip ($5)
                                {/* {eventDetails.live_show_amount > 0
                                  ? "Get Ticket ($)"
                                  : "Tip ($5)"} */}
                              </a>

                              {eventDetails.near_id ? (
                                <>
                                  {window.walletConnection.isSignedIn() ? (
                                    <div>
                                      <a
                                        className="btn-secondary-full"
                                        data-toggle="modal"
                                        data-target="#enter_amount_near"
                                        data-backdrop="static"
                                      >
                                        Tip (0.5 NEAR)
                                        {/* {eventDetails.live_show_amount > 0
                                          ? "Get Ticket (NEAR)"
                                          : "Tip (0.5 NEAR)"} */}
                                      </a>
                                      <a
                                        className="btn-secondary-full"
                                        data-toggle="modal"
                                        data-target="#enter_amount_jargon"
                                        data-backdrop="static"
                                      >
                                        Tip (10 Jargon)
                                        {/* {eventDetails.live_show_amount > 0
                                          ? "Get Ticket (Jargon)"
                                          : "Tip (10 Jargon)"} */}
                                      </a>
                                    </div>
                                  ) : (
                                    <div>
                                      <a
                                        href="#"
                                        className="btn-secondary-full"
                                        data-toggle="modal"
                                        onClick={() => {
                                          showNotification(
                                            "Please connect your near account!",
                                            false
                                          );
                                        }}
                                        data-backdrop="static"
                                      >
                                        Tip (0.5 NEAR)
                                        {/* {eventDetails.live_show_amount > 0
                                          ? "Get Ticket (NEAR)"
                                          : "Tip (0.5 NEAR)"} */}
                                      </a>
                                      <a
                                        href="#"
                                        className="btn-secondary-full"
                                        data-toggle="modal"
                                        onClick={() => {
                                          showNotification(
                                            "Please connect your near account!",
                                            false
                                          );
                                        }}
                                        data-backdrop="static"
                                      >
                                        Tip (10 Jargon)
                                        {/* {eventDetails.live_show_amount > 0
                                          ? "Get Ticket (Jargon)"
                                          : "Tip (10 Jargon)"} */}
                                      </a>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  <a
                                    // href=""
                                    className="btn-secondary-full"
                                    data-toggle="modal"
                                    onClick={() => {
                                      showNotification(
                                        "We are sorry. NEAR Transactions are not available for this event!",
                                        false
                                      );
                                    }}
                                    data-backdrop="static"
                                  >
                                    NEAR Transfer
                                  </a>
                                </>
                              )}
                            </>
                          ) : null}
                        </div>
                        <div className="maps">
                          <LoadScript googleMapsApiKey="AIzaSyA2VohtbyuoHxWCxvU8QAb1RfgEvtY2xL8">
                            <GoogleMap
                              zoom={5}
                              center={{
                                lat: parseFloat(eventDetails.lat),
                                lng: parseFloat(eventDetails.lng),
                              }}
                            >
                              {/* {
                                lat: eventDetails.lat,
                                lng: eventDetails.lng,
                              } */}
                              asdkjljl
                              <div
                                style={{
                                  minHeight: "400px",
                                  maxHeight: "100vh",
                                  minWidth: "300px",
                                  maxWidth: "100vw",
                                }}
                              ></div>
                              <Marker
                                key={eventDetails.id}
                                position={{
                                  lat: parseFloat(eventDetails.lat),
                                  lng: parseFloat(eventDetails.lng),
                                }}
                                onClick={() => onSelect(eventDetails)}
                              />
                              {/* {membersList.map((mmbr) => {
                                return (
                                  
                                );
                              })} */}
                              {selected.lat && (
                                <InfoWindow
                                  position={{
                                    lat: parseFloat(selected.lat),
                                    lng: parseFloat(selected.lng),
                                  }}
                                  clickable={true}
                                  onCloseClick={() => setSelected({})}
                                >
                                  <p> {selected.title}</p>
                                </InfoWindow>
                              )}
                            </GoogleMap>
                          </LoadScript>
                          {/* <iframe
                            src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyA2VohtbyuoHxWCxvU8QAb1RfgEvtY2xL8&center=${eventDetails.lat},${eventDetails.lng}&zoom=11&maptype=satellite`}
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                          ></iframe> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="popular-events">
                <h1 className="section-title">Popular Events</h1>
                <EventsListView
                  eventsList={eventsList}
                  isLoggedIn={isLoggedIn}
                  isMember={isMember}
                  isFan={isFan}
                />
              </div>
              <br />
              <br />
            </div>
          </main>

          {/* buy ticket for usd */}
          <div
            className="modal fade"
            id="enter_amount_usd"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close p-lg-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="usd-tip-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-wrapper">
                  {stripePubKey.length ? (
                    <StripeComponent
                      memberDetails={{
                        id: eventDetails.mid,
                        name: eventDetails.name,
                      }}
                      handlStripeModalClose={handlStripeModalClose}
                      stripePubKey={stripePubKey}
                      // 5 usd tip
                      amount={
                        eventDetails.live_show_amount > 0
                          ? Number(eventDetails.live_show_amount)
                          : 5
                      }
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* ticket payment in near */}
          <div
            className="modal fade"
            id="enter_amount_near"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close p-lg-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-wrapper">
                  <form onSubmit={handleBuyTicketInNear}>
                    <h2 className="heading">
                      Get Your Ticket Now For
                      <span> {ticketinNear} </span>
                      NEAR Only!{" "}
                    </h2>

                    {loading ? (
                      <div className="mt-lg-5 d-flex-fe">
                        <button className="btn-success-full mr-0">
                          {" "}
                          <LoaderComponent show={true} />
                        </button>
                      </div>
                    ) : (
                      <div className="mt-lg-5 d-flex-fe">
                        <button className="btn-success-full mr-0" type="submit">
                          {" "}
                          Get Now{" "}
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* ticket payment in Jargon */}
          <div
            className="modal fade"
            id="enter_amount_jargon"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close p-lg-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-wrapper">
                  {/* tip form */}
                  <form onSubmit={handleBuyTicketInJagon}>
                    <h2 className="heading">
                      Get Your Ticket Now For
                      <span> {ticketinJargon} </span>
                      Jargon Only!{" "}
                    </h2>

                    {loading ? (
                      <div className="mt-lg-5 d-flex-fe">
                        <button className="btn-success-full mr-0">
                          {" "}
                          <LoaderComponent show={true} />
                        </button>
                      </div>
                    ) : (
                      <div className="mt-lg-5 d-flex-fe">
                        <button className="btn-success-full mr-0" type="submit">
                          {" "}
                          Get Now{" "}
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* request a song  */}
          <div
            className="modal fade"
            id="request_song"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close p-lg-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="reqsong-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {/* <form onSubmit={}></form> */}

                <div className="modal-wrapper pt-0">
                  <h1 className="section-title"> Request a Song </h1>
                  <div className="form-row">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="reqsong" className="text-black-50">
                          {" "}
                          Song{" "}
                        </label>
                        <select
                          id="reqsong"
                          className="form-control"
                          onChange={handleChange}
                          required
                        >
                          <option
                            defaultValue={"Select"}
                            readOnly={true}
                            hidden={true}
                          >
                            Select
                          </option>
                          {reqSongList.map((song) => (
                            <option value={song.id} key={song.id}>
                              {song.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="reqsongcurrency"
                          className="text-black-50"
                        >
                          {" "}
                          Currency{" "}
                        </label>
                        <RadioGroup
                          value={reqsongcurrency}
                          onChange={(value) => {
                            handleChange({
                              target: { id: "reqsongcurrency", value },
                            });
                          }}
                          horizontal
                        >
                          <RadioButton
                            // rootColor={"#62f5bc"}
                            pointColor={"#1e989e"}
                            value="usd"
                          >
                            USD
                          </RadioButton>
                          {eventDetails.near_id && requestedSong.amount > 0 ? (
                            <RadioButton pointColor={"#1e989e"} value="near">
                              NEAR
                            </RadioButton>
                          ) : (
                            <RadioButton pointColor={"#1e989e"} disabled>
                              NEAR
                            </RadioButton>
                          )}
                        </RadioGroup>
                      </div>
                    </div>
                  </div>

                  {requestedSong.amount ? (
                    <>
                      {requestedSong.amount > 0 ? (
                        <div className="form-group">
                          {reqsongcurrency == "near" ? (
                            <div>
                              <h1>
                                Buy this song for just{" "}
                                {Math.round(
                                  (requestedSong.amount / nearToUsdRates) * 100
                                ) / 100}
                                {} NEAR
                              </h1>
                              <button
                                className="btn-lg-primary-full mr-0"
                                onClick={handleRequestSongNEAR}
                              >
                                Request Now
                              </button>
                            </div>
                          ) : (
                            <StripeComponent
                              memberDetails={{
                                id: eventDetails.mid,
                                name: `Playlist: ${requestedSong.title}`,
                              }}
                              handlStripeModalClose={handlStripeModalClose}
                              stripePubKey={stripePubKey}
                              // 10 usd is placeholder
                              amount={Number(requestedSong.amount)}
                              btntext={"Pay Now"}
                              trxData={{
                                page: "request_for_song",
                                id: requestedSong.playId,
                              }}
                            />
                          )}

                          {/* free songs */}
                        </div>
                      ) : (
                        <div>
                          <h1>You can request this song for free!</h1>
                          {loading ? (
                            <button
                              className="btn-lg-primary-full mr-0"
                              disabled
                            >
                              <LoaderComponent show={true} />
                            </button>
                          ) : (
                            <button
                              className="btn-lg-primary-full mr-0"
                              onClick={handleRequestSongSubmit}
                            >
                              Request Song
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="mt-lg-5 d-flex-fe">
                      <button className="btn-lg-primary-full mr-0">
                        Request Song
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    } else {
      return <LoadingScreenComponent text="Loading..." />;
    }
  }
}

export default EventDetails;
