import React, { useEffect, useState } from "react";
import Label from "../../../common/Label";
import Input from "../../../common/Input";
import { post, postApi } from "../../../../near/utils/localUtils";
import ModalComponent from "../../../common/ModalComponent";
import LoaderComponent from "../../../common/LoaderComponent";

function CreateEvent({ setstorageUpdateFlag, storageUpdateFlag }) {
  const [email, setemail] = useState("");
  const [fullname, setfullname] = useState("");

  const [title, settitle] = useState("");
  const [description, setdetails] = useState("");
  const [date, setdate] = useState("");
  const [time, settime] = useState("");
  const [venue, setvenue] = useState("");
  const [ticketPrice, setticketPrice] = useState("");
  const [featuredImg, setfeatimg] = useState(null);
  const [eventPhotos, seteventPhotos] = useState([]);
  const [duration, setduration] = useState("");
  const [categoryType, setcategoryType] = useState("");
  const [private_, setprivate] = useState(false);
  const [request_song, setrequest_song] = useState(false);

  // for auth
  const [memberId, setmemberId] = useState("");
  const [authToken, setauthToken] = useState("");

  useEffect(() => {
    const userinfolocal =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));
    const userotherinfo =
      JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"));

    // for auth
    setauthToken(userotherinfo.token);
    setmemberId(userinfolocal.id);

    setemail(userinfolocal.email);
    setfullname(userinfolocal.fullname);
  }, [storageUpdateFlag]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "title":
        settitle(value);
        break;
      case "featImg":
        setfeatimg(e.target.files[0]);
        break;
      case "eventPhotos":
        seteventPhotos(Array.from(e.target.files));
        break;
      case "details":
        setdetails(value);
        break;
      case "time":
        settime(value);
        break;
      case "duration":
        setduration(value);
        break;
      case "date":
        setdate(value);
        break;
      case "categoryType":
        setcategoryType(value);
        break;
      case "venue":
        setvenue(value);
        break;
      case "ticket-price":
        setticketPrice(value);
        break;
      case "request_song":
        setrequest_song(e.target.checked);
        break;
      case "private":
        setprivate(e.target.checked);
        break;
      default:
        console.log("NA");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("featured_img", featuredImg);

    eventPhotos.forEach((pic) => {
      formData.append("pics[]", pic);
    });
    formData.append("e_name", title);
    formData.append("datetime", date);
    formData.append("time", time);
    formData.append("duration", duration);
    formData.append("v_name", venue);
    formData.append("Category_type", categoryType);
    formData.append("Description", description);
    formData.append("private", private_ ? "1" : "0");
    formData.append("request_song", request_song ? "1" : "0");
    formData.append("submit_event", "Publish");
    formData.append("live_show", "1");
    formData.append("live_show_amount", "0");
    // live_show

    // auth
    formData.append("user_id", memberId);
    formData.append("token", authToken);
    formData.append("user_type", "Member");
    // formData.append("submit", "Update");

    setLoading(true);

    postApi("frontapi/event_add", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (data[0].status == "succcess") {
          setModalInfo({
            show: true,
            type: "primary",
            heading: "Successful",
            body: data[0].message,
          });
          setTimeout(() => {
            setModalInfo({
              show: false,
            });
          }, 700);

          // clear inputs
          setticketPrice("");
          settitle("");
          setdate("");
          settime("");
          setvenue("");
          setdetails("");
          setprivate(false);
          setrequest_song(false);
          setcategoryType("");
        } else {
          setModalInfo({
            show: true,
            type: "danger",
            heading: "Error",
            body: data[0].message,
          });
          setTimeout(() => {
            setModalInfo({
              show: false,
            });
          }, 700);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalInfo(false);
  };

  return (
    <div>
      {" "}
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />
      <div className="container">
        <div className="edit-profile-wrapper">
          <h1 className="mb-lg-5"> Create Event </h1>

          <div className="content-wrapper">
            <form onSubmit={handleSubmit}>
              <div className="form-row mt-lg-5">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Event Poster"} htmlFor={"featImg"} />
                    <input
                      type={"file"}
                      className={"form-control"}
                      name={"featImg"}
                      id={"featImg"}
                      accept="image/*"
                      // value={title}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Event Title"} htmlFor={"title"} />
                    <Input
                      type={"text"}
                      className={"form-control"}
                      name={"title"}
                      id={"title"}
                      placeholder={"Event Name"}
                      // defaultValue={"John Doe"}
                      value={title}
                      onChange={handleChange}
                      isRequired={true}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row mt-lg">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Event Date"} htmlFor={"date"} />
                    <Input
                      type={"date"}
                      className={"form-control"}
                      name={"date"}
                      id={"date"}
                      placeholder={"Event Date"}
                      // defaultValue={"09/22"}
                      value={date}
                      isRequired={true}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Event Photos"} htmlFor={"date"} />
                    <input
                      type={"file"}
                      multiple
                      className={"form-control"}
                      name={"eventPhotos"}
                      id={"eventPhotos"}
                      accept="image/*"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="form-row mt-lg">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Event Time"} htmlFor={"time"} />
                    <Input
                      type={"time"}
                      className={"form-control"}
                      name={"time"}
                      id={"time"}
                      placeholder={"Time"}
                      // defaultValue={"2512"}
                      value={time}
                      isRequired={true}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="form-row mt-lg">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label
                      mainLabel={"Event Duration (in hours)"}
                      htmlFor={"time"}
                    />
                    <Input
                      type={"text"}
                      className={"form-control"}
                      name={"duration"}
                      id={"duration"}
                      // defaultValue={"2512"}
                      value={duration}
                      isRequired={true}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Event Venue"} htmlFor={"venue"} />
                    <Input
                      type={"text"}
                      className={"form-control"}
                      name={"venue"}
                      id={"venue"}
                      placeholder={"City, State"}
                      // defaultValue={"09/22"}
                      value={venue}
                      isRequired={true}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="form-row mt-lg">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <select
                      className="form-control categoryType"
                      id="categoryType"
                      name="Category_type"
                      required
                      onChange={handleChange}
                    >
                      <option value="">Event Type</option>
                      <option value="3">Athletic Field </option>
                      <option value="13">Brewery </option>
                      <option value="4">Church </option>
                      <option value="8">Coffee Shop </option>
                      <option value="5">Event Center</option>
                      <option value="2">House Show</option>
                      <option value="7">Listening Room </option>
                      <option value="11">Live Stream</option>
                      <option value="6">Music Venue</option>
                      <option value="9">Restaurant </option>
                      <option value="10">Stadium </option>
                      <option value="14">TipJarLive.com Event</option>
                      <option value="12">Winery </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-row mt-lg">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label
                      mainLabel={"Ticket Price"}
                      htmlFor={"ticket-price"}
                    />
                    <Input
                      type={"number"}
                      min={0}
                      className={"form-control"}
                      name={"ticket-price"}
                      id={"ticket-price"}
                      placeholder={"Price in USD"}
                      value={ticketPrice}
                      isRequired={true}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Details"} htmlFor={"details"} />
                    <textarea
                      // type={"text"}
                      className={"form-control"}
                      name={"details"}
                      id={"details"}
                      placeholder={"Info for event poster"}
                      value={description}
                      required
                      onChange={handleChange}
                      // defaultValue={"4562 1122 4594 7852"}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      name="private"
                      id="private"
                      checked={private_}
                      onChange={handleChange}
                    />
                    <label htmlFor="vehicle3">Make Event Private </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <input
                      type="checkbox"
                      name="request_song"
                      id="request_song"
                      checked={request_song}
                      onChange={handleChange}
                    />
                    <label htmlFor="vehicle3">Enable Request Songs </label>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="d-flex-fe">
                  <button className="btn-primary-full updateBtn mr-0">
                    <LoaderComponent show={true} />{" "}
                  </button>
                </div>
              ) : (
                <div className="d-flex-fe">
                  <button
                    className="btn-primary-full updateBtn mr-0"
                    type="submit"
                  >
                    Create
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateEvent;
