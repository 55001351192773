import React, { useEffect, useState } from "react";
import { jargonDecimals } from "../../../../near/utils/exchangRates";
import { API_BASE_URL, getApi } from "../../../../near/utils/localUtils";

function DashboardContent({ userinfo, balanceInfo, userotherinfo }) {
  function naiveRound(num, decimalPlaces = 0) {
    var p = Math.pow(10, decimalPlaces);
    if (typeof num != "number") {
      return Math.round(Number(num) * p) / p;
    }
    return Math.round(num * p) / p;
  }

  const [subscribers, setsubscribers] = useState([]);
  const [tips_registered_fan, settips_registered_fan] = useState([]);

  useEffect(() => {
    if (userotherinfo.subscribers) {
      settips_registered_fan(userotherinfo.tips_registered_fan);
      setsubscribers(userotherinfo.subscribers);
    }
  }, [userotherinfo]);

  return (
    <div>
      <div className="container">
        <div className="dashboard-wrapper">
          <section className="profile-info">
            <div className="row">
              <div className="col-md-8">
                <div className="header">
                  <h1>
                    {userinfo.fname} {userinfo.lname}{" "}
                  </h1>
                </div>
                <div className="row">
                  <div className="col-md-3 col-3 title">
                    <p> Referral code : </p>
                    <p> SLUG : </p>
                    <p> Email : </p>
                    <p> Phone : </p>
                    <p> NEAR ID : </p>
                  </div>
                  <div className="col-md-9 col-9 value">
                    <p> {userinfo.reffral_code} </p>
                    <p>
                      <a href="#">{userinfo.slug} </a>
                    </p>
                    <p>
                      <a href="#"> {userinfo.email} </a>
                    </p>
                    <p> {userinfo.mobile} </p>
                    <p> {userinfo.near_id} </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                {userinfo.avatar ? (
                  <img
                    className="profile-avatar pull-right"
                    src={
                      userinfo.avatar.includes("http")
                        ? `${userinfo.avatar}`
                        : `${API_BASE_URL}/${userinfo.avatar}`
                    }
                    alt=""
                  />
                ) : null}
              </div>
            </div>
          </section>

          <section className="member-detail">
            <div className="row d-flex-sb ml-0 mr-0">
              <div className="credit-card">
                <img className="card-bg" src="/images/card_bg.png" alt="" />
                <div className="card-content">
                  <i className="fas fa-wifi"></i>
                  <div className="card-number-details">
                    <p> {userinfo.card_no} </p>
                    {/* <p> {accountInfo.cardDetails.cardNumber.slice(0, 5)} </p>
                    <p> {accountInfo.cardDetails.cardNumber.slice(5, 8)} </p>
                    <p> {accountInfo.cardDetails.cardNumber.slice(8, 12)} </p>
                    <p> {accountInfo.cardDetails.cardNumber.slice(12, 16)} </p> */}
                  </div>
                  <div className="extra-info">
                    <div className="content">
                      <p> Card Holder </p>
                      <h1> {userinfo.card_name} </h1>
                      {/* <h1> {accountInfo.cardDetails.name} </h1> */}
                    </div>
                    <div className="content">
                      <p> Expires </p>
                      <h1> {userinfo.exp_date}</h1>
                    </div>
                    <div className="card-logo">
                      <img
                        src="/images/logo-mastercard.png"
                        alt="Mastercard Logo"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="available-balance">
                <div className="header">
                  <div className="balance">
                    <h3> Available Balance (in USD) </h3>
                    <h1>
                      {" "}
                      ${" "}
                      {balanceInfo.usdBalance
                        ? naiveRound(balanceInfo.usdBalance, 4)
                        : ""}
                    </h1>
                  </div>
                  <div className="currency">
                    <p> $ </p>
                  </div>
                </div>
                <div className="footer">
                  <i className="fas fa-caret-up"></i>
                  {/* <p>
                    {" "}
                    + 2.0 % <span> than last month</span>
                  </p> */}
                </div>
              </div>

              {/* <div className="qr-scanner">
                <img
                  className="pull-right"
                  src="/images/member-qr-reader.png"
                  alt="Member QR Scanner"
                />
              </div> */}
            </div>
          </section>
          <section className="balance-info">
            <div className="balance-wrapper">
              <div className="content">
                <div className="heading">Available Balance (USD)</div>
                <p>
                  $
                  {balanceInfo.usdBalance
                    ? naiveRound(balanceInfo.usdBalance, 4)
                    : ""}
                  {/* <span className="positive"> +55% </span> */}
                </p>
              </div>

              <div className="icon">
                <i className="fas fa-wallet"></i>
              </div>
            </div>

            <div className="balance-wrapper">
              <div className="content">
                <div className="heading">Available NEAR</div>
                <p>
                  {balanceInfo.nearBalance
                    ? naiveRound(balanceInfo.nearBalance, 4)
                    : ""}
                  {/* <span className="negative"> -14% </span> */}
                </p>
              </div>

              <div className="icon">
                <i className="fas fa-wallet"></i>
              </div>
            </div>

            <div className="balance-wrapper">
              <div className="content">
                <div className="heading">Available Jargons</div>
                <p>
                  {balanceInfo.jargonBalance
                    ? balanceInfo.jargonBalance / jargonDecimals
                    : ""}
                  {/* <span className="positive"> +55% </span> */}
                </p>
              </div>

              <div className="icon">
                <i className="fas fa-wallet"></i>
              </div>
            </div>
          </section>

          <section className="balance-info">
            <div className="balance-wrapper">
              <div className="content">
                <div className="heading">Account Locked Balance</div>
                <p>
                  {" "}
                  {balanceInfo.lockedBalance
                    ? naiveRound(balanceInfo.lockedBalance / jargonDecimals, 4)
                    : ""}{" "}
                  NEAR {/* <span className="positive"> +55% </span> */}
                </p>
              </div>

              <div className="icon">
                <i className="fas fa-wallet"></i>
              </div>
            </div>

            <div className="balance-wrapper">
              <div className="content">
                <div className="heading">Account Unlocked Balance</div>
                <p>
                  {" "}
                  {balanceInfo.unlockedBalance
                    ? naiveRound(
                        balanceInfo.unlockedBalance / jargonDecimals,
                        4
                      )
                    : ""}
                  {/* <span className="positive"> +55% </span> */}
                </p>
              </div>

              <div className="icon">
                <i className="fas fa-wallet"></i>
              </div>
            </div>

            <div className="balance-wrapper">
              <div className="content">
                <div className="heading">Live NEAR Rate</div>
                <p>
                  $
                  {balanceInfo.exchangeRate
                    ? naiveRound(balanceInfo.exchangeRate, 4)
                    : ""}
                  {/* <span className="positive"> +55% </span> */}
                </p>
              </div>

              <div className="icon">
                <i className="fas fa-wallet"></i>
              </div>
            </div>
          </section>

          {/* ================ FOLLOWERS DUMMY DATA ===========  */}

          <section className="followers">
            <div className="row">
              <div className="col-md-6">
                <div className="supporters-wrapper">
                  <h1>Supporters</h1>

                  {subscribers.map((subs) => (
                    <div key={subs.id} className="members">
                      <div className="member-detail">
                        {/* <div className="member-image">
                          <img
                            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80"
                            alt=""
                          />
                        </div> */}
                        <div className="member-name">
                          <p> {subs.title}</p>
                          {/* <p> kummar123@tipjarlive.com </p> */}
                        </div>
                      </div>
                      <div className="member-info">
                        <p> {subs.fid} </p>
                        {/* <p className="package"> Monthly </p> */}
                      </div>
                    </div>
                  ))}

                  {/* <div className="members">
                    <div className="member-detail">
                      <div className="member-image">
                        <img
                          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80"
                          alt=""
                        />
                      </div>
                      <div className="member-name">
                        <p> Kumar Thapa </p>
                        <p> kummar123@tipjarlive.com </p>
                      </div>
                    </div>
                    <div className="member-info">
                      <p> Friend </p>
                      <p className="package"> Monthly </p>
                    </div>
                  </div>
                  <div className="members">
                    <div className="member-detail">
                      <div className="member-image">
                        <img
                          src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80"
                          alt=""
                        />
                      </div>
                      <div className="member-name">
                        <p> Esthera Jackson </p>
                        <p> estherajackson@tipjarlive.com </p>
                      </div>
                    </div>
                    <div className="member-info">
                      <p> Friend </p>
                      <p className="package"> Monthly </p>
                    </div>
                  </div>
                  <div className="members">
                    <div className="member-detail">
                      <div className="member-image">
                        <img
                          src="https://images.unsplash.com/photo-1590086782957-93c06ef21604?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                          alt=""
                        />
                      </div>
                      <div className="member-name">
                        <p> Kumar Thapa </p>
                        <p> kummar123@tipjarlive.com </p>
                      </div>
                    </div>
                    <div className="member-info">
                      <p> Friend </p>
                      <p className="package"> Monthly </p>
                    </div>
                  </div>
                  <div className="members">
                    <div className="member-detail">
                      <div className="member-image">
                        <img
                          src="https://images.unsplash.com/photo-1556474835-b0f3ac40d4d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                          alt=""
                        />
                      </div>
                      <div className="member-name">
                        <p> Esthera Jackson </p>
                        <p> estherajackson@tipjarlive.com </p>
                      </div>
                    </div>
                    <div className="member-info">
                      <p> Friend </p>
                      <p className="package"> Monthly </p>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="payment-received-wrapper">
                  <h1> Payment Received </h1>
                  {tips_registered_fan.map((tip) => (
                    <div key={tip.id} className="members">
                      <div className="member-detail">
                        <div className="member-image">
                          <img
                            src={`${API_BASE_URL}/${tip.pic}`}
                            // "https://images.unsplash.com/photo-1548372290-8d01b6c8e78c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80"
                            alt=""
                          />
                        </div>
                        <div className="member-name">
                          <p> {tip.fan}</p>
                          <p> {tip.email} </p>
                        </div>
                      </div>
                      <div className="price">
                        <p>
                          {tip.paid_amount} {tip.paid_amount_currency}
                        </p>
                      </div>
                    </div>
                  ))}

                  {/* <div className="members">
                    <div className="member-detail">
                      <div className="member-image">
                        <img
                          src="https://images.unsplash.com/photo-1590086782957-93c06ef21604?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                          alt=""
                        />
                      </div>
                      <div className="member-name">
                        <p> Esthera Jackson </p>
                        <p> estherajackson@tipjarlive.com </p>
                      </div>
                    </div>
                    <div className="price">
                      <p> $12.344 </p>
                    </div>
                  </div>
                  <div className="members">
                    <div className="member-detail">
                      <div className="member-image">
                        <img
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                          alt=""
                        />
                      </div>
                      <div className="member-name">
                        <p> Kumar Thapa </p>
                        <p> kummar123@tipjarlive.com </p>
                      </div>
                    </div>
                    <div className="price">
                      <p> $12.344 </p>
                    </div>
                  </div>
                  <div className="members">
                    <div className="member-detail">
                      <div className="member-image">
                        <img
                          src="https://images.unsplash.com/photo-1556474835-b0f3ac40d4d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                          alt=""
                        />
                      </div>
                      <div className="member-name">
                        <p> Kumar Thapa </p>
                        <p> kummar123@tipjarlive.com </p>
                      </div>
                    </div>
                    <div className="price">
                      <p> $12.344 </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default DashboardContent;
