import React from 'react';
import ReactTooltip from "react-tooltip";

function ToolTip(props) {
    return (
        <span>
            <a className="with-info" data-tip={props.toolTipText}
               title="">
                <i className="fa fa-info"></i>
            </a>
            <ReactTooltip />
        </span>

    );
}

export default ToolTip;