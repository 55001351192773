export const jargonToUsd = 0.00125; // first ICO Round price
export const jargonDecimals = 10000000;

export async function fetchMoonPay(
  url = "https://helper.testnet.near.org/fiat" ||
    "https://api.moonpay.com/v3/currencies/near/bid_price?apiKey=pk_test_SkPS7WIze94gNd05J9AmUj2Ws681V6B"
) {
  let nearRate = await fetch(url);
  nearRate = await nearRate.json();
  // return nearRate.USD; //for moonpay api
  return nearRate.near.usd; // for near api
}

export const calculateNearToJargon = async (nearRate) => {
  if (!nearRate) {
    const livenearRate = fetchMoonPay();
    console.log("jargon rate: ", livenearRate / jargonToUsd);
    return livenearRate / jargonToUsd;
  }
  return nearRate / jargonToUsd;
};

// ================================================== //
// ================================================== //
// ================================================== //

// export const calculateNearToJargon = (jargon) => {
//   return jargon * (1 / nearToJargon);
// };

// export const calculateMaxJargon = (nearBalance, nearToUSD) => {
//   return parseFloat(nearBalance) * nearToJargon;
// };

// export const calculateMaxJargon = async (near, usdToNear) => {
//   return near * (await calculateNearToJargon(usdToNear));
// };
