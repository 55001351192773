import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Label from "../common/Label";
import Input from "../common/Input";

import {
  API_BASE_URL,
  MASTERACCOUNT_ID,
  post,
  postApi,
} from "../../near/utils/localUtils";
import {
  calculateNearToJargon,
  fetchMoonPay,
  jargonDecimals,
  jargonToUsd,
} from "../../near/utils/exchangRates";
import {
  getNearAccountInfo,
  loginNearFunctional,
  sendNear,
} from "../../near/utils/browserUtils";
import ModalComponent from "../common/ModalComponent";
import LoaderComponent from "../common/LoaderComponent";
import { NotificationWrapper } from "../pages/Notifications";
import { handleLogout } from "../utils/Utils";

// const jargonToUsd = 0.01;

function Navbar(props) {
  const isLoggedIn = props.isLoggedIn;
  const isMember = props.isMember;
  const isFan = props.isFan;

  // general
  const [userInfo, setuserInfo] = useState(
    JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"))
  );
  const [userOtherInfo, setuserOtherInfo] = useState(
    JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"))
  );

  // ----------------- for edit profile -------------------- //
  const [firstname, setfirstname] = useState("First Name");
  const [lastname, setlastname] = useState("Last Name");
  const [email, setemail] = useState("");
  const [enableOtp, setenableOtp] = useState(false);
  const [mobile, setmobile] = useState("");
  // const [accountInfo, setaccountInfo] = useState({});
  // const [username, setusername] = useState("");

  //   password change
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");

  // for auth
  const [memberId, setmemberId] = useState("");
  const [authToken, setauthToken] = useState("");

  // logged in fan information
  const [userInfomation, setUserInfo] = useState({});

  //   buy jargon
  const [jargon, setjargons] = useState(0);
  const [maxJargons, setmaxJargons] = useState(0);
  const [nearSpent, setnearSpent] = useState(0);
  const [usdSpent, setusdSpent] = useState(0);

  // buy jargon confirmation modal
  const [confirmModalInfo, setconfirmModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Confirmation",
    body: "Please Confirm to Complete the Transaction.",
  });

  const [loading, setLoading] = useState(false); //for button only

  // near account info (after account connection)
  const [nearAccountInfo, setnearAccountInfo] = useState({});
  const [nearToJargon, setnearToJargonRate] = useState("");
  const [nearExchangeRate, setnearExchangeRate] = useState("");

  //   connect near accounts
  const [nearAccountId, setnearAccountId] = useState("");
  const [nearLoginErr, setnearLoginErr] = useState(false);

  //   for referral code
  const [refEmail, setrefEmail] = useState("");

  // notifications
  const [namedNofications, setnotifications] = useState([]);
  const [anonymousNotifications, setAnonymousNotifications] = useState([]);
  const [unreadNoficationCount, setunreadNoficationCount] = useState(0);
  const [notificationReadStatusChange, setNotificationReadStatusChange] =
    useState(false);

  useEffect(async () => {
    if (userInfo) {
      setmemberId(userInfo.id);
      const userotherinfo =
        JSON.parse(localStorage.getItem("otherinfo")) ||
        JSON.parse(sessionStorage.getItem("otherinfo")) ||
        {};
      setauthToken(userotherinfo.token);
      setUserInfo(userInfo);
      // setUserId(userInfo.id);
      const {
        fname,
        lname,
        email,
        mobile, // accountInfo
        enable_otp,
      } = userInfo;
      setfirstname(fname);
      setlastname(lname);
      setemail(email);
      setenableOtp(enable_otp == 1 ? true : false);
      setmobile(mobile);
    }

    if (window.location.href.includes("transactionHashes")) {
      const txHash = window.location.href.split("transactionHashes=")[1];
      // send reward if trx was a tip
      console.log(
        "from navbar: ",
        localStorage.getItem("trxtype"),
        localStorage.getItem("trxtype") == "tip"
      );

      if (localStorage.getItem("trxtype") == "jargonpurchase") {
        // if transaction was a a jargon purchase do this
        const formData = {
          accountId: window.walletConnection.account().accountId,
          txHash,
          userId: userInfo.id || userInfo.user_id,
          token: userOtherInfo.token,
        };

        post(formData, "send-jargon", "json")
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (!data.err) {
              setModalInfo({
                ...modalInfo,
                show: true,
                type: "primary",
                heading: "Successfull",
                body: "Congratulations! Jargons transferred!",
              });
              setjargons(1);
              window.location.href = window.location.href.split("?")[0];
            }
          })
          .catch((err) => {
            setLoading(false);
            setModalInfo({
              ...modalInfo,
              show: true,
              type: "danger",
              heading: "Failed",
              body: "Can not buy Jargons at this time",
            });
          });
      }
    } else if (window.location.href.includes("errorCode=")) {
      window.location.href = window.location.href.split("?")[0];
      localStorage.removeItem("tip");
      localStorage.removeItem("jargontip");
      localStorage.removeItem("jargonticket");
      localStorage.removeItem("ticket");
      localStorage.removeItem("jargonamount");
      localStorage.removeItem("jargonpurchase");
    }
  }, []);

  useEffect(async () => {
    if (!userInfo || !userOtherInfo) {
      return;
    }
    // notifications
    const nformdata = new FormData();
    nformdata.append("user_id", userInfo.id);
    nformdata.append("token", userOtherInfo.token);

    let api = "frontapi/notification_list_mus";

    if (isFan) {
      api = "frontapi/notification_list";
    }

    postApi(api, nformdata)
      .then((res) => res.json())
      .then((data) => {
        if (data[0].status != "error") {
          const records = data[0].records.filter((ntfcn) => ntfcn.view == 0);
          let newNtfcnCount = records.length;
          setnotifications(records);
          if (data[0].records_anonymous) {
            // available for members only
            const anonymousRecords = data[0].records_anonymous.filter(
              (ntfcn) => ntfcn.view == 0
            );
            setAnonymousNotifications(anonymousRecords);
            newNtfcnCount = newNtfcnCount + anonymousRecords.length;
          }

          setunreadNoficationCount(newNtfcnCount);
        }
      });
  }, [notificationReadStatusChange]);

  // logout the near id if user is not logged in
  useEffect(async () => {
    if (window.walletConnection.isSignedIn() && !isLoggedIn) {
      const { redirecting } = userInfomation;
    }
  }, []);

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    // console.log(id, value);
    switch (id) {
      case "firstName":
        setfirstname(value);
        break;
      case "lastName":
        setlastname(value);
        break;
      case "email":
        setemail(value);
        break;
      case "otp":
        setenableOtp(e.target.checked);
        break;
      case "phone":
        setmobile(value);
        break;
      case "password":
        setpassword(value);
        break;
      case "cpassword":
        setcpassword(value);
        break;
      case "nearAccount":
        setnearAccountId(value);
        break;
      case "jargons":
        setjargons(parseFloat(value));
        setnearSpent(value * (1 / nearToJargon));
        setusdSpent(value * jargonToUsd);
        break;
      case "refemail":
        setrefEmail(value);
        break;
      default:
        break;
    }
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    if (password != cpassword) {
      setModalInfo({
        ...modalInfo,
        show: true,
        type: "danger",
        heading: "Failed",
        body: "Passwords do not match!",
      });

      return;
    }

    const formData = new FormData();

    formData.append("password", password);
    formData.append("cpassword", cpassword);

    formData.append("submit", "Update");

    // auth
    formData.append("user_id", memberId);
    formData.append("token", authToken);
    formData.append("user_type", "Fan");

    setLoading(true);
    postApi("frontapi/fan_reset_pass_new", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "success") {
          document.getElementById("fan-pwd-change-close-btn").click();
          setpassword("");
          setcpassword("");

          setModalInfo({
            ...modalInfo,
            show: true,
            type: "success",
            heading: "Updated",
            body: data[0].message,
          });
          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
          }, 1000);
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Failed",
            body: data[0].message,
          });
          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditProfileSubmit = (e) => {
    e.preventDefault();

    // other fields update
    const formData = new FormData();

    formData.append("fname", firstname);
    formData.append("lname", lastname);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("enable_otp", enableOtp ? 1 : 0);
    formData.append(
      "stripe_customer_id",
      userInfomation.stripe_customer_id || ""
    );
    formData.append("submit", "Update");

    // auth
    formData.append("user_id", memberId);
    formData.append("token", authToken);
    formData.append("user_type", "Fan");

    setLoading(true);
    postApi("frontapi/profile_update", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "succcess") {
          sessionStorage.setItem("userinfo", JSON.stringify(data[0].fan[0]));

          document.getElementById("edit-fan-profile-closebtn").click();

          setModalInfo({
            ...modalInfo,
            show: true,
            type: "primary",
            heading: "Done",
            body: "Profile Updated!",
          });
          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
          }, 1000);
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Failed",
            body: data[0].message,
          });
          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleModalClose = () => {
    setModalInfo({ ...modalInfo, show: false });
    setconfirmModalInfo({ ...confirmModalInfo, show: false });
  };

  // ================  buy jargons! ============= //

  const handleBuyJargonsSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    localStorage.setItem("trxtype", "jargonpurchase");
    sendNear(MASTERACCOUNT_ID, nearSpent.toString());
  };

  const handleConnectNEARaccount = (e) => {
    e.preventDefault();
    // loginNearAccount(nearAccountId).catch((err) => {
    //   setnearLoginErr(true);
    //   console.log(err);
    // });
    loginNearFunctional().catch((err) => {
      // setnearLoginErr(true);
      console.log(err);
    });
  };

  const handlGetNearAccountInfo = async () => {
    const nearInfo = await getNearAccountInfo();
    const usdToNear = await fetchMoonPay();
    const nearToJargonRate = await calculateNearToJargon(usdToNear);
    setnearExchangeRate(usdToNear);
    setnearToJargonRate(nearToJargonRate);
    setnearAccountInfo({
      ...nearInfo,
      jargonBalance: nearInfo.jargonBalance / jargonDecimals,
      unlockedBalance: nearInfo.unlockedBalance / jargonDecimals,
      lockedBalance: nearInfo.lockedBalance / jargonDecimals,
    });

    setmaxJargons(parseFloat(nearInfo.nearBalance) * nearToJargonRate);
  };

  //   for referral code
  const handleAddRefCode = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("refEmail", refEmail);
    formData.append("refEmailSubmit", true);

    // auth
    formData.append("user_id", memberId);
    formData.append("token", authToken);
    formData.append("user_type", "Fan");

    setLoading(true);
    postApi("frontapi/refferal_list", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "Referral code Successfully sent") {
          setrefEmail("");
          document.getElementById("refemail-modal-close-btn").click();
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "primary",
            heading: "Successfull",
            body: data[0].status,
          });
          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
          }, 1000);
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Failed",
            body: data[0].status,
          });
          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMarkAsRead = async () => {
    const formdata = new FormData();
    formdata.append("token", authToken);
    formdata.append("user_id", memberId);

    postApi("frontapi/read_all_notification", formdata).then((res) =>
      res
        .json()
        .then((data) => {
          console.log(data);
          if (data[0].status == "status") {
            setNotificationReadStatusChange(true);
          }
        })
        .catch((err) => {
          console.log("Err: ", err);
        })
    );
  };

  // for fans only
  if (isLoggedIn && isFan && !isMember) {
    return (
      <div>
        <ModalComponent
          modalInfo={modalInfo}
          handleClose={handleModalClose}
          center={true}
        />
        <nav className="navbar navbar-expand-lg navbar-red fixed-top user-nav">
          <div className="container-fluid">
            <Link to="/fan" className="navbar-brand p-3">
              <img src="/images/tipjarlive_logo.svg" alt="TipJarLogo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto d-flex-ai">
                <li className="nav-item active">
                  <Link
                    to="/fan/members"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    Members
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/fan/upcoming-events"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    Upcoming Events
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/fan/supporting-following" className="nav-link">
                    Supporting/Following
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/fan/history-management" className="nav-link">
                    $Spent
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/fan/FAQs"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    FAQ
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/notifications" className="nav-link">
                    <i className="far fa-bell" />
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {userInfomation.avatar ? (
                      <img
                        src={
                          userInfomation.avatar.includes("http")
                            ? `${userInfomation.avatar}`
                            : `${API_BASE_URL}/${userInfomation.avatar}`
                        }
                        alt=""
                      />
                    ) : (
                      <img src={"/images/near-logo.svg"} alt="" />
                    )}
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right notification-dropdown"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    {window.walletConnection.isSignedIn() ? (
                      <a
                        href="#"
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#buy_jargons"
                        data-backdrop="static"
                        onClick={handlGetNearAccountInfo}
                      >
                        Buy Jargons
                      </a>
                    ) : null}

                    {window.walletConnection.isSignedIn() ? (
                      <a
                        href="#"
                        className="dropdown-item"
                        data-toggle="modal"
                        data-target="#near_accountinfo"
                        data-backdrop="static"
                        onClick={handlGetNearAccountInfo}
                      >
                        NEAR Account Info
                      </a>
                    ) : (
                      <a
                        href="#"
                        className="dropdown-item"
                        onClick={handleConnectNEARaccount}
                      >
                        Connect NEAR account
                      </a>
                    )}

                    {userInfomation.fname &&
                    userInfomation.oAuth != "google" ? (
                      <>
                        {" "}
                        <a
                          href="#"
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#edit_profile"
                          data-backdrop="static"
                        >
                          Edit Profile
                        </a>
                        <a
                          href="#"
                          className="dropdown-item"
                          data-toggle="modal"
                          data-target="#change_password"
                          data-backdrop="static"
                        >
                          Change Password
                        </a>
                      </>
                    ) : null}

                    <Link
                      to="/"
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      Logout
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* edit profile modal */}
        <div
          className="modal fade"
          id="edit_profile"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="close p-lg-3 text-right"
                data-dismiss="modal"
                aria-label="Close"
                id="edit-fan-profile-closebtn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-wrapper pt-0">
                <h1 className="section-title"> Edit Profile </h1>

                {/* ============== Edit profile modal ==================  */}

                <form onSubmit={handleEditProfileSubmit}>
                  <div className="form-row mt-lg-5">
                    <div className="col-6">
                      <div className="form-group">
                        <Label mainLabel={"First Name"} for={"firstName"} />
                        <Input
                          type={"text"}
                          name={"firstName"}
                          className={"form-control"}
                          id={"firstName"}
                          placeholder={"First Name"}
                          // defaultValue={"Austin"}
                          value={firstname}
                          onChange={handleChange}
                          isRequired={true}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <Label mainLabel={"Last Name"} for={"lastName"} />
                        <Input
                          type={"text"}
                          name={"lastName"}
                          className={"form-control"}
                          id={"lastName"}
                          placeholder={"Last Name"}
                          // defaultValue={"Axtell"}
                          value={lastname}
                          onChange={handleChange}
                          isRequired={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row mt-lg-5">
                    <div className="col-6">
                      <div className="form-group">
                        <Label mainLabel={"Email"} for={"email"} />
                        <Input
                          type={"text"}
                          name={"email"}
                          className={"form-control"}
                          id={"email"}
                          placeholder={"Email"}
                          // isDisabled={true}
                          value={email}
                          onChange={handleChange}
                          isRequired={true}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <Label mainLabel={"Enable OTP"} for={"otp"} />
                        <Input
                          type={"checkbox"}
                          name={"otp"}
                          className={"form-control"}
                          id={"otp"}
                          // defaultValue={"Axtell"}
                          isChecked={enableOtp}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <Label mainLabel={"Phone Number"} for={"phoneNumber"} />
                    <Input
                      type={"text"}
                      name={"phone"}
                      className={"form-control"}
                      id={"phone"}
                      value={mobile}
                      onChange={handleChange}
                      placeholder={"Phone Number"}
                      // defaultValue={"+9779843586610"}
                      isRequired={true}
                    />
                  </div>

                  <div className="form-group">
                    <Label
                      mainLabel={"Account Details"}
                      for={"accountDetails"}
                    />
                    <Input
                      type={"text"}
                      name={"accountDetails"}
                      className={"form-control"}
                      id={"accountDetails"}
                      placeholder={"Account Details"}
                      value={userInfomation.stripe_customer_id || ""}
                      // defaultValue={"cus_KY8hBlXmqR9T3C"}
                      isDisabled={true}
                    />
                  </div>
                  <div className="mt-lg-5 d-flex-fe">
                    {loading ? (
                      <button className="btn-lg-primary-full mr-0" disabled>
                        <LoaderComponent show={true} />
                      </button>
                    ) : (
                      <button
                        className="btn-lg-primary-full mr-0"
                        type="submit"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </form>
                {/* ========== Edit profile form ends ============  */}
              </div>
            </div>
          </div>
        </div>

        {/* Change Password modal */}
        <div
          className="modal fade"
          id="change_password"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="close p-lg-3 text-right"
                data-dismiss="modal"
                aria-label="Close"
                id="fan-pwd-change-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-wrapper pt-0">
                <h1 className="section-title"> Change Password</h1>

                <form onSubmit={handleChangePassword}>
                  <div className="form-row mt-lg-5">
                    <div className="col-6">
                      <div className="form-group">
                        <Label mainLabel={"New Password"} for={"password"} />
                        <Input
                          type={"password"}
                          name={"password"}
                          className={"form-control"}
                          id={"password"}
                          value={password}
                          onChange={handleChange}
                          isRequired={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row mt-lg-5">
                    <div className="col-6">
                      <div className="form-group">
                        <Label
                          mainLabel={"Confirm Password"}
                          for={"cpassword"}
                        />
                        <Input
                          type={"password"}
                          name={"cpassword"}
                          className={"form-control"}
                          id={"cpassword"}
                          value={cpassword}
                          onChange={handleChange}
                          isRequired={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-lg-5 d-flex-fe">
                    {loading ? (
                      <button className="btn-lg-primary-full mr-0" disabled>
                        <LoaderComponent show={true} />
                      </button>
                    ) : (
                      <button
                        className="btn-lg-primary-full mr-0"
                        type="submit"
                      >
                        Update
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* buy jargons ! */}

        <div
          className="modal fade"
          id="buy_jargons"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="close p-lg-3 text-right"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-wrapper pt-0">
                <h1 className="section-title"> Buy Jargons! </h1>
                <form onSubmit={handleBuyJargonsSubmit}>
                  <div className="form-group">
                    <Label
                      className={"text-black-50"}
                      mainLabel={"Jargons to buy"}
                      for={"jargons"}
                    />
                    <Input
                      type={"number"}
                      // step={0.001}
                      min={1}
                      max={maxJargons}
                      name={"jargons"}
                      className={"form-control"}
                      id={"jargons"}
                      placeholder={"Jargons"}
                      // defaultValue={""}
                      value={jargon}
                      onChange={handleChange}
                      isRequired={true}
                    />
                  </div>
                  <h3>
                    {" "}
                    Buy {jargon} Jargons for just {nearSpent.toFixed(5)} NEAR!
                    (or ${usdSpent}){" "}
                  </h3>

                  <div className="form-group mt-lg-5 ">
                    <span> Confirm </span>
                    {"      "}
                    <Input type={"checkbox"} isRequired={true} />
                  </div>

                  <div className="mt-lg-5 d-flex-fe">
                    {loading ? (
                      <button
                        className="btn-lg-primary-full mr-0"
                        // type="submit"
                        disabled
                      >
                        <LoaderComponent show={true} />
                      </button>
                    ) : (
                      <button
                        className="btn-lg-primary-full mr-0"
                        type="submit"
                        // disabled={false}
                        disabled={jargon == 0 ? true : false}
                      >
                        Buy Now!
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Connect NEAR account! */}
        <div
          className="modal fade"
          id="connect_near_account"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="close p-lg-3 text-right"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-wrapper pt-0">
                <h1 className="section-title"> Connect NEAR account </h1>
                <form onSubmit={handleConnectNEARaccount}>
                  <div className="form-group">
                    <Label
                      className={"text-black-50"}
                      mainLabel={"Your account Id: "}
                      for={"nearAccount"}
                    />
                    <Input
                      type={"text"}
                      name={"nearAccount"}
                      className={"form-control"}
                      id={"nearAccount"}
                      placeholder={"yourid.testnet"}
                      value={nearAccountId}
                      onChange={handleChange}
                      isRequired={true}
                    />
                    {nearLoginErr ? <h4>Account Does Not Exist!</h4> : null}
                  </div>

                  <div className="mt-lg-5 d-flex-fe">
                    <button className="btn-lg-primary-full mr-0" type="submit">
                      Connect!
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* near account info */}
        <div
          className="modal fade"
          id="near_accountinfo"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="close p-lg-3 text-right"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-wrapper pt-0">
                <h1 className="section-title">
                  {nearAccountInfo.nearAccountId}
                </h1>
                <div className="form-group">
                  <h2>Available NEAR: {nearAccountInfo.nearBalance}</h2>
                  <h2>Available Jargons: {nearAccountInfo.jargonBalance}</h2>
                  <h2>Locked Jargons: {nearAccountInfo.lockedBalance}</h2>
                  <h2>Unlocked Jargons: {nearAccountInfo.unlockedBalance}</h2>
                  <h2>Live NEAR Exchange Rate: ${nearExchangeRate}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (isLoggedIn && isMember && !isFan) {
    // for members only
    return (
      <div>
        <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />
        <nav className="navbar navbar-expand-lg navbar-red fixed-top user-nav">
          <div className="container-fluid">
            <Link to="/member" className="navbar-brand p-3">
              <img src="/images/tipjarlive_logo.svg" alt="" />
            </Link>

            <button
              className="navbar-toggler p-3"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto d-flex-ai">
                <li className="nav-item active">
                  <Link
                    to="/member/my-events"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    My Events
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarEcosystem"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Ecosystem
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarEcosystem"
                  >
                    <Link
                      to="/member/supporters-dashboard"
                      state={{
                        isLoggedIn: isLoggedIn,
                        isFan: isFan,
                        isMember: isMember,
                      }}
                      className="dropdown-item"
                    >
                      Supporters Dashboard
                    </Link>
                    <Link
                      to="/member/follower-campaign"
                      state={{
                        isLoggedIn: isLoggedIn,
                        isFan: isFan,
                        isMember: isMember,
                      }}
                      className="dropdown-item"
                    >
                      Marketing Campaigns
                    </Link>
                  </div>
                </li>

                <li className="nav-item">
                  <Link
                    to="/member/playlist"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    My Playlists
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/member/payment-received"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    My $$$
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/member/faqs"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    FAQ
                  </Link>
                </li>

                {/* nofitication list */}
                <li className="nav-item dropdown">
                  <Link
                    to=""
                    className="nav-link dropdown-toggle icon"
                    href="#"
                    id="notifications"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="far fa-bell" />
                    {unreadNoficationCount > 0 ? (
                      <span className="notification-alert">
                        {unreadNoficationCount}
                      </span>
                    ) : null}
                  </Link>

                  <div
                    className="dropdown-menu dropdown-menu-right notifications notification-dropdown"
                    aria-labelledby="notifications"
                  >
                    <h2>
                      <p> Notifications </p>
                      <Link to="" onClick={handleMarkAsRead}>
                        Mark all as read
                      </Link>
                    </h2>

                    {NotificationWrapper({
                      namedNofications,
                      anonymousNotifications,
                    })}
                    <Link to="/notifications" className="view-all">
                      View All
                    </Link>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {userInfomation.avatar ? (
                      <img
                        // className="profile-avatar pull-right"
                        src={
                          userInfomation.avatar.includes("http")
                            ? `${userInfomation.avatar}`
                            : `${API_BASE_URL}/${userInfomation.avatar}`
                        }
                        alt=""
                      />
                    ) : null}
                    {/* <img
                      // src="https://images.unsplash.com/photo-1628890923662-2cb23c2e0cfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                      alt=""
                    /> */}
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    style={{ width: "35" }}
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link
                      to="/member/dashboard"
                      state={{
                        isLoggedIn: isLoggedIn,
                        isFan: isFan,
                        isMember: isMember,
                      }}
                      className="dropdown-item"
                    >
                      Dashboard
                    </Link>

                    <a
                      href="#"
                      className="dropdown-item"
                      data-toggle="modal"
                      data-target="#referral_code"
                      data-backdrop="static"
                    >
                      Referral Code
                    </a>
                    <Link
                      to="/"
                      className="dropdown-item"
                      onClick={handleLogout}
                    >
                      Logout
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* referral code modal */}

        <div
          className="modal fade"
          id="referral_code"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <button
                type="button"
                className="close p-lg-3 text-right"
                data-dismiss="modal"
                aria-label="Close"
                id="refemail-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <form onSubmit={handleAddRefCode}>
                <div className="modal-wrapper pt-0">
                  <h1 className="section-title mb-lg-0 pb-lg-2">
                    {" "}
                    Referral or Invite Friend{" "}
                  </h1>
                  <p className="text-left mt-5 mb-lg-5">
                    {" "}
                    You can refer your friend and earn.{" "}
                  </p>
                  <div className="form-group">
                    <Label
                      className={"text-black-50"}
                      mainLabel={"Email"}
                      for={"email"}
                    />
                    <Input
                      type={"email"}
                      name={"refemail"}
                      value={refEmail}
                      onChange={handleChange}
                      className={"form-control"}
                      id={"refemail"}
                      placeholder={"Email"}
                    />
                  </div>

                  <div className="mt-lg-5 d-flex-fe">
                    {loading ? (
                      <button className="btn-lg-primary-full mr-0" disabled>
                        <LoaderComponent show={true} />
                      </button>
                    ) : (
                      <button
                        className="btn-lg-primary-full mr-0"
                        type="submit"
                      >
                        {" "}
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />

        <nav className="navbar navbar-expand-lg navbar-red fixed-top">
          <div className="container-fluid">
            <Link to="/" className="navbar-brand p-3">
              <img src="/images/tipjarlive_logo.svg" alt="" />
            </Link>
            <button
              className="navbar-toggler p-3"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fas fa-bars" />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link
                    to="/members"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    Members
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/events"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    Events
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/faqs"
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="nav-link"
                  >
                    {" "}
                    FAQ{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/fan/sign-in"
                    className="nav-link btn-white-outline mr-4 ml-4"
                  >
                    {" "}
                    Fan{" "}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/member/sign-in"
                    className="nav-link btn-white-full mr-4 ml-4"
                  >
                    {" "}
                    Member{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Navbar;
