import React from "react";
import VerfiyStripe from "../VerfiyStripe";

function Sidebar({ userinfo }) {
  return (
    <div>
      <div className="app-sidebar sidebar-shadow">
        <div className="app-header__logo">
          <div className=""></div>
          <div className="header__pane ml-auto">
            <div>
              <button
                type="button"
                className="hamburger close-sidebar-btn hamburger--elastic"
                data-class="closed-sidebar"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              type="button"
              className="hamburger hamburger--elastic mobile-toggle-nav"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button
              type="button"
              className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
            >
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6"></i>
              </span>
            </button>
          </span>
        </div>
        <div className="scrollbar-sidebar">
          <div className="app-sidebar__inner">
            <ul
              className="nav nav-tabs vertical-nav-menu"
              id="dashboard-menu-tabs"
              role="tablist"
            >
              <li className="nav-item">
                {/* <Link to="/member/dashboard/info">
                  <i className="metismenu-icon fa fa-home"></i>
                  Dashboard
                </Link> */}

                <a
                  className="nav-link"
                  href="#dashboard"
                  role="tab"
                  aria-controls="description"
                  aria-selected="true"
                >
                  <i className="metismenu-icon fa fa-home"></i>
                  Dashboard
                </a>
              </li>
              <li className="nav-item">
                {/* <Link to="/member/dashboard/edit">
                  <i className="metismenu-icon fa fa-edit"></i>
                  Edit Profile
                </Link>{" "} */}
                <a
                  className="nav-link"
                  href="#edit-profile"
                  role="tab"
                  aria-controls="history"
                  aria-selected="false"
                >
                  <i className="metismenu-icon fa fa-edit"></i>
                  Edit Profile
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#create-event"
                  role="tab"
                  aria-controls="history"
                  aria-selected="false"
                >
                  <i className="metismenu-icon fa fa-plus"></i>
                  Create Events
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#transfer-crypto"
                  role="tab"
                  aria-controls="history"
                  aria-selected="false"
                >
                  <i className="metismenu-icon fa fa-paper-plane"></i>
                  Transfer NEAR/Jargon
                </a>
              </li>
              {userinfo.Stripe_account_status != "1" ? (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#verify-stripe"
                    role="tab"
                    aria-controls="history"
                    aria-selected="false"
                  >
                    <i className="metismenu-icon fa fa-user-check"></i>
                    Verify Stripe
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
