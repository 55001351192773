import React from "react";
import AppRoute from "./components/AppRoute";
import Home from "./components/pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Members from "./components/pages/Members";
import Events from "./components/pages/Events";

function App() {
  return <AppRoute />;
}

export default App;
