import {
  connect,
  Contract,
  keyStores,
  WalletConnection,
  utils,
  Account,
} from "near-api-js";

import getConfig from "../config";

// Initialize contract & set global variables
export async function initContract(nearConfig = getConfig("development")) {
  // Initialize connection to the NEAR testnet
  const near = await connect(
    Object.assign(
      // { deps:
      { keyStore: new keyStores.BrowserLocalStorageKeyStore() },
      // }
      nearConfig
    )
  );
  window.near = near;

  // Initializing Wallet based Account. It can work with NEAR testnet wallet that
  // is hosted at https://wallet.testnet.near.org
  window.walletConnection = new WalletConnection(near);
  window.account = await window.walletConnection.account();

  return true;
}

async function getContract(nearConfig, account) {
  const contract = await new Contract(account, nearConfig.contractName, {
    // View methods are read only. They don't modify the state, but usually return some value.
    viewMethods: [
      "is_owner",
      "ft_balance_of",
      "get_owner_id",
      "get_total_supply",
      "get_balance",
      "get_total_balance",
      "get_locked_balance",
      "get_unlocked_balance",
      "get_allowance",
      "get_transfer_log",
      "get_burn_log",
      "get_metadata",
      "get_foundation_account_id",
      "get_lockup_master_account_id",
      "get_min_attached_balance",
      "get_unvested_amount",
    ],
    // Change methods can modify the state. But you don't receive the returned value when called.
    changeMethods: [
      "new",
      "new_default_meta",
      "migrate",
      "inc_allowance",
      "dec_allowance",
      "transfer_from",
      "transfer",
      "burn",
      "register_tax_account",
      "register_account",
    ],
  });
  return contract;
}

export async function getNearAccountInfo(
  nearId,
  nearConfig = window.nearConfig
) {
  if (!window.near) {
    const initContractSuccessFull = await initContract();
    if (initContractSuccessFull) {
      const nearAccountId = nearId || (await window.account.accountId);
      const account = await window.near.account(nearAccountId);

      // load contract
      const contract = await getContract(nearConfig, window.account);
      const unlockedBalance = await contract.get_unlocked_balance({
        account_id: nearAccountId,
      });
      const lockedBalance = await contract.get_locked_balance({
        account_id: nearAccountId,
      });
      const jargonBalance = await contract.get_balance({
        account_id: nearAccountId,
      });
      const balanceYoctoNear = await account.getAccountBalance({
        account_id: nearAccountId,
      });
      // yocto near to near
      let nearBalance = utils.format.formatNearAmount(
        balanceYoctoNear.available
      );
      nearBalance = parseFloat(nearBalance).toFixed(2);

      return {
        nearBalance,
        nearAccountId,
        unlockedBalance,
        lockedBalance,
        jargonBalance,
      };
    } else {
      return {};
    }
  } else {
    const nearAccountId = nearId || (await window.account.accountId);
    const account = await window.near.account(nearAccountId);

    // load contract
    const contract = await getContract(nearConfig, window.account);
    const unlockedBalance = await contract.get_unlocked_balance({
      account_id: nearAccountId,
    });
    const lockedBalance = await contract.get_locked_balance({
      account_id: nearAccountId,
    });
    const jargonBalance = await contract.get_balance({
      account_id: nearAccountId,
    });
    const balanceYoctoNear = await account.getAccountBalance({
      account_id: nearAccountId,
    });
    // yocto near to near
    let nearBalance = utils.format.formatNearAmount(balanceYoctoNear.available);
    nearBalance = parseFloat(nearBalance).toFixed(2);

    return {
      nearBalance,
      nearAccountId,
      unlockedBalance,
      lockedBalance,
      jargonBalance,
    };
  }
}

// ================================================== //
export function logoutNearAccount() {
  window.walletConnection.signOut();
  // reload page
  // window.location.replace(window.location.origin + window.location.pathname)
  window.location.replace(window.location.origin);
}

export async function loginNearFunctional() {
  return await window.walletConnection.requestSignIn({
    contractId: "contract.alyvethedefiant.testnet", // contract requesting access
    methodNames: ["is_owner", "ft_transfer"],
  });
}

export async function loginNearAccount(accountId) {
  // Allow the current app to make calls to the specified contract on the
  // user's behalf.
  // This works by creating a new access key for the user's account and storing
  // the private key in localStorage.

  return await window.walletConnection.requestSignIn(accountId);
}

// ------------------------------ account setup ===================

// export async function getAccount() {}

export async function createAccount(
  masterAccount,
  accountID,
  accessKey,
  BN = "1820000000000000000000" // min 1.82e+21 yoctoNEAR
) {
  // create new sub-account
  const newAcc = await masterAccount
    .createAccount(`${accountID}.${masterAccount.accountId}`, accessKey, BN)
    .catch((err) => {
      console.log(err);
    });
  if (newAcc) {
    alert(`Account Created: ${accountID}.${masterAccount.accountId}`);
  } else {
    alert("Failed");
  }
}

export async function sendNear(receiverAccountId, amount) {
  return window.account.sendMoney(
    receiverAccountId,
    utils.format.parseNearAmount(amount)
  );
}

//   send jargons (tokens)
export async function sendJargon(sendTo, amount) {
  const contract = await getContract(
    window.nearConfig,
    window.walletConnection.account()
  );
  try {
    let amountStr = amount;
    if (typeof amountStr != "string") {
      amountStr = amountStr.toString();
    }
    const transfer = await contract.transfer({
      receiver_id: sendTo,
      amount: amountStr,
    });
    return transfer;
  } catch (err) {
    return { err: "Transaction Failed!", details: err };
  }
}
