import React, { useEffect, useState } from "react";
import Navbar from "../include/Navbar";
import { useNavigate } from "react-router-dom";
import Footer from "../include/Footer";
import { getApi } from "../../near/utils/localUtils";

function FaQs() {
  const navigate = useNavigate();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );
  const [faqlist, setfaqlist] = useState([]);

  useEffect(async () => {
    const faq = await getApi("frontapi/cms/info");
    setfaqlist(faq);
  }, []);

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <section className="faqs-category-description container">
          <div className="category-wrapper">
            <h2 className="category-title"> Fans </h2>
            <ul>
              <li>
                {" "}
                Sign up to engage with your favorite bands, musicians, and live
                performers alike!
              </li>
              <li>
                {" "}
                Instantly send tips, request songs, subscribe, watch exclusive
                ppv livestreams, and much much more to come.
              </li>
              <li>
                {" "}
                Support your favorite musicians, bands, artists, athletes, and
                live performers through TipJarLive.com.
              </li>
            </ul>
          </div>

          <div className="category-wrapper">
            <h2 className="category-title"> Musicians/Members </h2>
            <ul>
              <li>
                {" "}
                Sign up to engage your fans before an event, during an event,
                and beyond!
              </li>
              <li>
                {" "}
                Instantly receive tips and song requests/take polls, set up
                support subscriptions, create and stream PPV events, engage fans
                longterm and much much more to come.
              </li>
            </ul>
          </div>
        </section>
        <section className="faqs-main container">
          <div className="faqs-main-title">FAQs</div>
          <p className="faqs-main-subtitle">
            Frequently asked questions about{" "}
            <img src="assets/images/tip-jar-live_black.png" alt="" />
          </p>
          <div id="accordion">
            {faqlist.map((faq) => (
              <div className="faq-card" key={faq.id}>
                <h5 className="question-title">
                  <a
                    href="#"
                    className="btn btn-link"
                    data-toggle="collapse"
                    data-target={`#collapse${faq.id}`}
                    aria-expanded="true"
                    aria-controls={`#collapse${faq.id}`}
                  >
                    {faq.title}
                  </a>
                </h5>

                <div
                  id={`collapse${faq.id}`}
                  className="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="faq-content">{faq.description}</div>
                </div>
              </div>
            ))}

            {/* <div className="faq-card">
              <h5 className="question-title">
                <a
                  href="#"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Who uses TipJarLive.com?
                </a>
              </h5>

              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div className="faq-content">
                  TipJarLive.com(TJL) is designed specifically for Musicians and
                  Live Performers but can benefit any Creator, Venue, Bar, or
                  Business.
                </div>
              </div>
            </div>
            <div className="faq-card">
              <h5 className="question-title">
                <a
                  href="#"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  Why use TipJarLive.com?
                </a>
              </h5>

              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div className="faq-content">
                  TipJarLive.com(TJL) was created to give musicians, live
                  performers and creators alike a place to do businesses outside
                  of the major competitors in this niche. These larger
                  corporations have proven time and time again that they are
                  self serving and the members come second to their profits. TJL
                  is created for the exact opposite purpose. We want to give the
                  power and money back to our members.
                </div>
              </div>
            </div>
            <div className="faq-card">
              <h5 className="question-title">
                <a
                  href="#"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  I don’t pay any fees with venmo, why should I switch?
                </a>
              </h5>

              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-parent="#accordion"
              >
                <div className="faq-content">
                  Venmo is a great platform for peer 2 peer money transfers with
                  friends, family and small face to face business, but that is
                  where Venmo's benefits end. With Tipjarlive.com every person
                  who leaves a tip becomes part of your ecosystem and fanbase.
                  With Tipjarlive.com your fans can tip you, subscribe to you,
                  request songs, and view PPV Live streamed events(More to
                  come). You are also able to send direct email communication
                  with the fans in your ecosystem with action filter parameters.
                  <br />
                  <br />
                  The TipJarLive Platform also gives the fan the ability to and
                  encourages them to pay for any and all fees associated with
                  the transactions through an option at checkout.
                  <br />
                  <br />
                  Other platforms require fans to sign up and link a card or
                  bank account in order to give you a tip. TipJarLive allows
                  fans to tip and make song requests without signing up, making
                  it accessible to any and everyone.
                  <br />
                  <br />
                  Tipjarlive.com is the full service business solution for live
                  performers. We are constantly working to improve our platform
                  and to increase the connection between supporting fans and
                  musicians.
                </div>
              </div>
            </div>
            <div className="faq-card">
              <h5 className="question-title">
                <a
                  href="#"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                >
                  What does TipJarLive.com cost to use?
                </a>
              </h5>

              <div
                id="collapseFive"
                className="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordion"
              >
                <div className="faq-content">
                  TJL is free to sign up with only a small transaction fee of
                  3%(not including industry standard processing fees, and if you
                  receive money in any given month then a fee is debited from
                  your TJL account for that month). We also add a 2% fee that
                  goes directly back into members pockets through our rewards
                  programs. The fan also has the option to cover all fees and we
                  encourage them to do so. There is also a small fee to initiate
                  a PPV Live stream which is debited from your account
                  internally, and the fan pays a small platform fee to view the
                  PPV Live stream.
                  <br />
                  <br />
                  <ul>
                    <li> TJL Platform Fee: 3%</li>
                    <li> Member Rewards: 2%</li>
                    <li>
                      {" "}
                      PPV Initiating by Musician: $6 - Debited from TJL Account.
                    </li>
                    <li> Fan PPV Fee: $1.15.</li>
                    <li>
                      {" "}
                      *Active Account Monthly Fee: $3 - Debited from TJL
                      Account.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="faq-card">
              <h5 className="question-title">
                <a
                  href="#"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="true"
                  aria-controls="collapseSix"
                >
                  Why does TJL have a transactional platform fee and what is an
                  internal account debit?
                </a>
              </h5>

              <div
                id="collapseSix"
                className="collapse"
                aria-labelledby="headingSix"
                data-parent="#accordion"
              >
                <div className="faq-content">
                  TJL has a small transactional platform fee so that we can
                  continue to improve our platform, give members more ways to
                  connect with fans and increase fan retention and long term
                  engagement.
                  <br />
                  <br />
                  These fees are designed to offset the large price tag and time
                  involvement of developing the high quality service we are
                  providing. This platform is designed to be low to no cost to
                  the members by passing any fees onto the fans who are there to
                  support the members.
                  <br />
                  <br />
                  Also, since our fees and the member rewards are percentage
                  based, everyone is encouraged and invested in helping members
                  generate more revenue for themselves.
                  <br />
                  <br />
                  An internal account debit means that it is taken directly from
                  your TJL account, so as long as you are using the platform for
                  its intended purpose then you as a member will never see these
                  fees.
                </div>
              </div>
            </div>
            <div className="faq-card">
              <h5 className="question-title">
                <a
                  href="#"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseSeven"
                  aria-expanded="true"
                  aria-controls="collapseSeven"
                >
                  Is there any form of community involvement?
                </a>
              </h5>

              <div
                id="collapseSeven"
                className="collapse"
                aria-labelledby="headingSeven"
                data-parent="#accordion"
              >
                <div className="faq-content">
                  If you would like to participate in the TJL Community you can
                  submit Images and videos for TJL to use in its marketing as
                  well as tutorial videos that TJL may distribute as educational
                  material amongst other members. Anyone who is active on TJL
                  has the opportunity to be the face of TJL to other TJL
                  members.
                </div>
              </div>
            </div>
            <div className="faq-card">
              <h5 className="question-title">
                <a
                  href="#"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseEight"
                  aria-expanded="true"
                  aria-controls="collapseEight"
                >
                  I didn’t have a referral code on sign up how can I get
                  approved?
                </a>
              </h5>

              <div
                id="collapseEight"
                className="collapse"
                aria-labelledby="headingEight"
                data-parent="#accordion"
              >
                <div className="faq-content">
                  If you did not have a referral code on sign up or you entered
                  it incorrectly, please send an email to:{" "}
                  <a href="mailto:dev@tipjarlive.com"> dev@tipjarlive.com. </a>
                  <br />
                  Subject: Membership Approval Request for *Your Name*.
                  <br />
                  Tell us a little bit about yourself, goals with the platform
                  and links to social media sites.
                  <br />
                  That is how you will get approval fast tracked.
                </div>
              </div>
            </div>
            <div className="faq-card">
              <h5 className="question-title">
                <a
                  href="#"
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseNine"
                  aria-expanded="true"
                  aria-controls="collapseNine"
                >
                  Affiliate Program
                </a>
              </h5>

              <div
                id="collapseNine"
                className="collapse"
                aria-labelledby="headingNine"
                data-parent="#accordion"
              >
                <div className="faq-content">
                  TipJarLive Affiliates receive a 1% reward on all their direct
                  and indirect sign ups total amount of their transactions.
                  <br />
                  <br />
                  Since you are required to have a referral code to avoid the
                  approval process, everyone is automatically enrolled into the
                  affiliate program as a “Member” through using a referral code
                  at time of sign up. If a person does not have a referral code
                  on sign up, they will need admin approval before they will be
                  displayed and able to use the platform. If approved they will
                  be assigned randomly under an Affiliate or a Co-Affiliate.
                  This is a benefit of being an Affiliate or signing up directly
                  under an Affiliate.
                  <br />
                  <br />
                  If you would like to take advantage of the TipJarLive
                  Affiliate Program please send an email to:{" "}
                  <a href="mailto:dev@tipjarlive.com"> dev@tipjarlive.com. </a>,
                  <br />
                  subject: Affiliate Request for *Your Name*.
                  <br />
                  Tell us a little bit about yourself, goals with the platform,
                  links to social media sites,
                  <br />
                  and why you would like to be part of TipJarLive Affiliate
                  Program.
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default FaQs;
