import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./components/AppRoute";
import getConfig from "./near/config";
import { initContract } from "./near/utils/browserUtils";

// ReactDOM.render(
//     <React.StrictMode>
//             <App/>
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// reportWebVitals();

// setup env
// require("dotenv").config();

global.Buffer = global.Buffer || require("buffer").Buffer;

window.nearConfig = getConfig("development");

window.nearInitPromise = initContract(window.nearConfig)
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );
  })
  .catch(console.error);
