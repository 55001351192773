import React, { Component } from "react";
import Banner from "./home/Banner";
import About from "./home/About";
import Members from "./home/Members";
import Events from "./home/Events";
import Navbar from "../include/Navbar";
import Footer from "../include/Footer";
import VerfiyStripe from "./member/VerfiyStripe";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn:
        JSON.parse(localStorage.getItem("isLoggedIn")) ||
        JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
        false,
      isMember:
        JSON.parse(localStorage.getItem("isMember")) ||
        JSON.parse(sessionStorage.getItem("isMember")) ||
        false,
      isFan:
        JSON.parse(localStorage.getItem("isFan")) ||
        JSON.parse(sessionStorage.getItem("isFan")) ||
        false,
      bannerInfo: [],
      membersList: [],
      eventsInfo: [],
      aboutUs: "",
    };
    this.updateState = this.updateState;
  }

  componentDidMount() {
    fetch("https://dev.tipjarlove.com/frontapi/index")
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          ...this.state,
          bannerInfo: [data[0][0]],
          membersList: data[1],
          eventsInfo: data[2],
          aboutUs: data[3],
        });
      })
      .catch((err) => {
        console.log("err : ", err);
      });
  }

  updateState(isLoggedIn) {
    this.state({ ...this.state, isLoggedIn });
  }

  render() {
    return (
      <div>
        <Navbar
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
          updateState={this.updateState}
        />

        {this.state.bannerInfo.length ? (
          <Banner bannerInfo={this.state.bannerInfo} />
        ) : null}
        {/* <VerfiyStripe /> */}
        <Members
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
          membersList={this.state.membersList}
        />
        {/* ) : null} */}
        <Events
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
          eventsInfo={this.state.eventsInfo}
        />
        <About
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
          aboutUs={this.state.aboutUs}
        />
        <Footer
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
        />
      </div>
    );
  }
}

export default Home;
