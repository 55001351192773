import React, { Component } from "react";
import { Link } from "react-router-dom";

class About extends Component {
  constructor(props) {
    super(props);
  }
  htmlDecode(input) {
    var e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  render() {
    let isLoggedIn = this.props.isLoggedIn;
    let isMember = this.props.isMember;
    let isFan = this.props.isFan;
    let link;

    if (isFan && isLoggedIn && !isMember) {
      link = "/fan/faqs";
    } else if (isMember && isLoggedIn && !isFan) {
      link = "/member/faqs";
    } else {
      link = "/faqs";
    }
    return (
      <div>
        <section className="about-us">
          <div className="container">
            <h1 className="section-title"> About Us</h1>
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: this.htmlDecode(this.props.aboutUs.description),
              }}
            >
              {/* {this.props.aboutUs.description} */}
            </p>
            {/* <div></div> */}
            <Link
              to={link}
              state={{
                isLoggedIn: isLoggedIn,
                isFan: isFan,
                isMember: isMember,
              }}
              className="arrow-btn"
            >
              MORE INFO <i className="fa fa-arrow-right"> </i>
            </Link>
          </div>
        </section>
      </div>
    );
  }
}

export default About;
