import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { API_BASE_URL, post, postApi } from "../../../near/utils/localUtils";

function PaymentReceived(props) {
  const navigate = useNavigate();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  // trx type to be displayed (used for search bar)
  const [showCurrency, setShowCurrency] = useState("usd");

  // usd records
  const [usdPayments, setUsdPayments] = useState([]);
  const [usdTrxAllData, setusdTrxAllData] = useState([]);

  // NEAR records
  const [nearPayments, setNearPayments] = useState([]);

  // for search/filter
  const [searchPaymentType, setsearchPaymentType] = useState("");
  const [searchPaymentDate, setsearchPaymentDate] = useState("");
  const [searchPaymentAmount, setsearchPaymentAmount] = useState("");

  // auth
  const [authToken, setauthToken] = useState("");
  const [userId, setuserId] = useState("");

  useEffect(() => {
    if (!isMember || !isLoggedIn) {
      navigate("/member/sign-in");
      return;
    }
    const userinfo =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));
    const userotherinfo =
      JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"));

    setauthToken(userotherinfo.token);
    setuserId(userinfo.id);

    // fetch usd data
    const formData = new FormData();
    formData.append("token", userotherinfo.token);
    formData.append("user_id", userinfo.id);

    postApi("frontapi/payment_list", formData)
      .then((res) => res.json())
      .then((data) => {
        setUsdPayments(data[0].records);
        setusdTrxAllData(data[0].records);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });

    // -------------------------------------- //
    // trx history NEAR
    const formDataNear = new FormData();
    formDataNear.append("mid", userinfo.id);
    formDataNear.append("token", userotherinfo.token);
    formDataNear.append("user_id", userinfo.id);
    post(formDataNear, "trx-hitory-member")
      .then((res) => res.json())
      .then((data) => {
        if (!data.err) {
          setNearPayments(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case "eventType":
        setsearchPaymentType(value);
        break;
      case "paymentDate":
        setsearchPaymentDate(value);
        break;
      case "trxamount":
        setsearchPaymentAmount(value);
        break;
      default:
        break;
    }
  };

  // querry/search members
  const handleSearch = (e) => {
    e.preventDefault();
    // filter for distance
    let filteredData = usdTrxAllData;

    if (searchPaymentType.length) {
      filteredData = filteredData.filter(
        (trx) => trx.payment_type == searchPaymentType
      );
    }

    if (searchPaymentDate.length) {
      filteredData = filteredData.filter(
        (trx) => trx.created.split(" ")[0] == searchPaymentDate
      );
    }

    if (searchPaymentAmount.length) {
      filteredData = filteredData.filter((trx) => {
        return (
          parseFloat(trx.paid_amount) >
            parseFloat(searchPaymentAmount.split("-")[0]) &&
          parseFloat(trx.paid_amount) <=
            parseFloat(searchPaymentAmount.split("-")[1])
        );
      });
    }

    setUsdPayments(filteredData);
  };

  // to clear search
  const handleResetSearch = () => {
    setUsdPayments(usdTrxAllData);
    setsearchPaymentAmount("");
    setsearchPaymentDate("");
    setsearchPaymentType("");
  };

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="member-payment-received-wrapper container">
          <div className="section-header">
            <h1 className="section-title"> Payment Received </h1>
            <div className="d-flex-jc">
              <ul className="nav nav-tabs custom-nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#usdTrx"
                    role="tab"
                    onClick={() => {
                      setShowCurrency("usd");
                    }}
                  >
                    USD ($)
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#nearTrx"
                    role="tab"
                    onClick={() => {
                      setShowCurrency("near");
                    }}
                  >
                    NEAR
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="main-content">
            {showCurrency == "usd" ? (
              <div className="filter-selection mb-lg-5">
                <form onSubmit={handleSearch}>
                  <div className="form-group filter-item">
                    <Label mainLabel={"Types"} for={"eventType"} />
                    <select
                      id="eventType"
                      className="form-control"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onChange={handleChange}
                      value={searchPaymentType}
                    >
                      <option
                        defaultValue={"Select"}
                        readOnly={true}
                        hidden={true}
                      >
                        Select
                      </option>
                      <option value="tip"> Tips</option>
                      <option value="subscribe"> Subscribe</option>
                      <option value="liveStream"> PPV Live Stream</option>
                      <option value="songReq"> Request for Songs</option>
                      <option value="anonymous"> Anonymous Payment</option>
                    </select>
                  </div>

                  <div className="form-group filter-item">
                    <Label mainLabel={"Filter by Date"} for={"paymentDate"} />
                    <Input
                      type={"date"}
                      name={"subject"}
                      className={"form-control"}
                      id={"paymentDate"}
                      onChange={handleChange}
                      placeholder={"Filter by Date"}
                      autoComplete={"off"}
                      value={searchPaymentDate}
                    />
                  </div>

                  <div className="form-group filter-item">
                    <Label mainLabel={"Price Range "} for={"priceRange"} />
                    <select
                      id="trxamount"
                      className="form-control"
                      onChange={handleChange}
                      value={searchPaymentAmount}
                    >
                      <option
                        defaultValue={"Select"}
                        readOnly={true}
                        hidden={true}
                      >
                        {" "}
                        Select
                      </option>
                      <option value="0-2000"> 0 to 2000</option>
                      <option value="2000-5000"> 2000 to 5000</option>
                      <option value="5000-10000"> 5000 to 10000</option>
                    </select>
                  </div>
                  <div className="button-wrapper filter-item">
                    <button className="btn-primary-full mr-0" type="submit">
                      Search
                    </button>
                    <div
                      className="icon-wrapper filter-item"
                      onClick={handleResetSearch}
                    >
                      <i className="fa fa-redo"></i>
                    </div>
                  </div>
                </form>
              </div>
            ) : null}

            <div className="tab-content custom-tab-content">
              <div className="tab-pane active" id="usdTrx" role="tabpanel">
                <div className="row">
                  {usdPayments.map((transaction) => (
                    <div className="col-md-3" key={transaction.id}>
                      <div className="small-card">
                        <img
                          className="card-img"
                          // src="/images/cards/1.jpeg"
                          src={`${API_BASE_URL}/${transaction.pic}`}
                          alt=""
                        />

                        <div className="card-content">
                          <div className="card-description">
                            <p className="card-text text-center font-weight-bold">
                              {" "}
                              {transaction.fan}{" "}
                            </p>
                            <h1 className="number-text text-center">
                              {" "}
                              ${transaction.paid_amount}{" "}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="tab-pane" id="nearTrx" role="tabpanel">
                <div className="row">
                  {nearPayments.map((transaction) => (
                    <div className="col-md-3" key={transaction.trxid}>
                      <div className="small-card">
                        <img
                          className="card-img"
                          src={
                            transaction.description.includes("near")
                              ? "/images/near-logo.png"
                              : "/images/JargonTOKEN.svg"
                          }
                          alt=""
                        />

                        <div className="card-content">
                          <div className="card-description">
                            <p className="card-text text-center font-weight-bold">
                              {" "}
                              {transaction.fanName}{" "}
                            </p>
                            <h1 className="number-text text-center">
                              {" "}
                              {transaction.amount}
                              {transaction.description.includes("near")
                                ? " NEAR"
                                : " Jargon"}
                            </h1>
                            <a
                              href={`https://explorer.${window.nearConfig.networkId}.near.org/transactions/${transaction.trxid}`}
                            >
                              <p
                                className="btn-primary-full"
                                style={{ padding: 10 }}
                              >
                                {" "}
                                View on Explorer{" "}
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default PaymentReceived;
