import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";
import { API_BASE_URL } from "../../../near/utils/localUtils";

function Members({ membersList, isLoggedIn, isMember, isFan }) {
  SwiperCore.use([Navigation, EffectCoverflow, Pagination]);
  // const isLoggedIn =
  //   JSON.parse(sessionStorage.getItem("isLoggedIn")) || props.isLoggedIn;
  // const isMember =
  //   JSON.parse(sessionStorage.getItem("isMember")) || props.isMember;
  // const isFan = JSON.parse(sessionStorage.getItem("isFan")) || props.isFan;

  return (
    <div>
      <section className="members">
        <h1 className="section-title"> Members</h1>
        <h3 className="section-subtitle"> Newly Joined Members!</h3>
        <div className="member-swiper-container d-flex-jc">
          <div className="swiper-wrapper">
            <Swiper
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              initialSlide={2}
              slidesPerView={3}
              spaceBetween={180}
              navigation={true}
              loop={true}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 300,
              }}
              pagination={true}
              breakpoints={{
                // when window width is >= 640px
                320: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 3,
                },
              }}
            >
              {membersList.map((member) => (
                <SwiperSlide id={member.id} key={member.id}>
                  <Link
                    to={`/members/${member.slug}`}
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                  >
                    {/* <img src={member.avatar} alt="" /> */}

                    <img
                      src={
                        member.avatar
                          ? member.avatar.includes("http")
                            ? `${member.avatar}`
                            : `${API_BASE_URL}/${member.avatar}`
                          : ""
                      }
                      alt=""
                    />
                    <div className="member-content">
                      <div className="line"></div>
                      <div className="member-name">
                        {member.fname} {member.lname}
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Members;
