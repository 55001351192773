import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getApi } from "../../near/utils/localUtils";
import { Container } from "react-bootstrap";

export default function BlankComponent({ show, handleClose, endpoint }) {
  const [htmlContent, sethtmlContent] = useState("");
  useEffect(async () => {
    const resData = await getApi(endpoint);
    sethtmlContent(resData[0].description);
  }, [endpoint]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        scrollable="true"
        centered
      >
        <Modal.Header>
          <a className="modal-close" onClick={handleClose}>
            <i className="fa fa-times" />
          </a>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
