import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import Label from "../../common/Label";
import Input from "../../common/Input";
import ModalComponent from "../../common/ModalComponent";
import LoaderComponent from "../../common/LoaderComponent";
import { postApi } from "../../../near/utils/localUtils";

function Playlist(props) {
  const navigate = useNavigate();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  // for state
  const [userinfo, setuserinfo] = useState({});
  const [userotherinfo, setuserotherinfo] = useState({});

  // form values
  const [songtitle, setsontitle] = useState("");
  const [songprice, setsongprice] = useState("");
  const [songgroupcategory, setgroupcategory] = useState("1");

  const [playlistGroup, setplaylistGroup] = useState([]);
  const [mySongsList, setmySongsList] = useState([]);

  // edit song state
  const [editSongInfo, seteditSongInfo] = useState({});

  useEffect(() => {
    if (isMember && isLoggedIn) {
      const userotherinfostorage =
        JSON.parse(localStorage.getItem("otherinfo")) ||
        JSON.parse(sessionStorage.getItem("otherinfo"));
      const userinfoStorage =
        JSON.parse(localStorage.getItem("userinfo")) ||
        JSON.parse(sessionStorage.getItem("userinfo"));
      setuserotherinfo(userotherinfostorage);

      // list only active playlist groups
      setplaylistGroup(
        userotherinfostorage.mus_playlist_group.filter((grp) => grp.status == 1)
      );
      setmySongsList(userotherinfostorage.mus_playlist);
      setuserinfo(userinfoStorage);
    } else {
      navigate("/member/sign-in");
    }
  }, []);

  // for ui
  const [loading, setLoading] = useState(false);

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const handleModalClose = () => {
    setModalInfo(false);
  };

  const handleEditSongState = (id) => {
    const editSong = mySongsList.find((song) => song.id == id);
    seteditSongInfo(editSong);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id == "title") {
      setsontitle(value);
    } else if (id == "price") {
      setsongprice(value);
    } else if (id == "group-category") {
      setgroupcategory(value);
    } else if (id == "editprice") {
      seteditSongInfo({ ...editSongInfo, price: value });
    } else if (id == "edit-group-category") {
      seteditSongInfo({ ...editSongInfo, group_id: value });
    }
  };

  const handleEditSongSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // formData.append("audioName", songtitle);
    formData.append("songid", editSongInfo.id);
    formData.append("price", editSongInfo.price);
    formData.append("playlist_group", editSongInfo.group_id);
    formData.append("audioFileUpdaet", true);

    // auth
    formData.append("user_id", userinfo.id);
    formData.append("token", userotherinfo.token);
    formData.append("user_type", "Member");
    handleSubmit(formData, "frontapi/SongPriceData", "editsong");
  };
  // for adding new song
  const handleAddSongSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("audioName", songtitle);
    formData.append("amount", songprice);
    formData.append("playlist_group", songgroupcategory);
    formData.append("addAudioDataSave1", true);

    // auth
    formData.append("user_id", userinfo.id);
    formData.append("token", userotherinfo.token);
    formData.append("user_type", "Member");
    handleSubmit(formData, "frontapi/add_playlist");
  };

  // delete song
  const handleDeleteSongSubmit = async (id) => {
    const formData = new FormData();
    formData.append("song_id", id);

    // auth
    formData.append("user_id", userinfo.id);
    formData.append("token", userotherinfo.token);

    // window.formData = formData;
    setLoading(true);

    postApi("frontapi/musicianPlayListRemove", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "succcess") {
          const updatePlaylists = mySongsList.filter(
            (playlist) => playlist.id != id
          );
          setmySongsList(updatePlaylists);
          sessionStorage.setItem(
            "otherinfo",
            JSON.stringify({ ...userotherinfo, mus_playlist: updatePlaylists })
          );
          if (localStorage.getItem("otherinfo")) {
            localStorage.setItem(
              "otherinfo",
              JSON.stringify({
                ...userotherinfo,
                mus_playlist: updatePlaylists,
              })
            );
          }
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "primary",
            heading: data[0].status,
            body: data[0].message,
          });
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Failed",
            body: "Something Went Wrong!",
          });
        }
        setTimeout(() => {
          setModalInfo({
            ...modalInfo,
            show: false,
          });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setModalInfo({
          ...modalInfo,
          show: true,
          type: "danger",
          heading: "Failed",
          body: "Something Went Wrong!",
        });
        setTimeout(() => {
          setModalInfo({
            ...modalInfo,
            show: false,
          });
        }, 1000);
      });

    // handleSubmit(formData, "frontapi/musicianPlayListRemove");
  };

  const handleSubmit = async (formData, api, action = "addsong") => {
    // window.formData = formData;
    setLoading(true);

    postApi(api, formData)
      .then((res) => {
        setLoading(false);
        document.getElementById("add-song-modal-close").click();
        document.getElementById("edit-song-modal-close").click();
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (data[0].status == "success" || data[0].status == "succcess") {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "primary",
            heading: data[0].status,
            body: data[0].message,
          });

          // different state update for add and edit song
          if (action == "addsong") {
            setmySongsList(data[0].mus_playList);
            // update playlist
            const userOtherInfoUpdated = {
              ...userotherinfo,
              mus_playlist: data[0].mus_playList,
            };

            setsontitle("");
            setsongprice("");

            sessionStorage.setItem(
              "otherinfo",
              JSON.stringify(userOtherInfoUpdated)
            );
            if (localStorage.getItem("otherinfo")) {
              localStorage.setItem(
                "otherinfo",
                JSON.stringify(userOtherInfoUpdated)
              );
            }
          } else if (action == "editsong") {
            // all songs except this song
            const updatedSongList = mySongsList.filter(
              (song) => song.id != editSongInfo.id
            );
            const updatedPlaylist = [
              ...updatedSongList,
              {
                ...editSongInfo,
                price: data[0].amount,
                group_id: data[0].group_id,
              },
            ];

            const userOtherInfoUpdated = {
              ...userotherinfo,
              mus_playlist: updatedPlaylist,
            };

            // update this song details
            setmySongsList(updatedPlaylist);
            sessionStorage.setItem(
              "otherinfo",
              JSON.stringify(userOtherInfoUpdated)
            );
            if (localStorage.getItem("otherinfo")) {
              localStorage.setItem(
                "otherinfo",
                JSON.stringify(userOtherInfoUpdated)
              );
            }
          }
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: data[0].status,
            body: data[0].message,
          });
        }
        setTimeout(() => {
          setModalInfo({
            ...modalInfo,
            show: false,
          });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setModalInfo({
          ...modalInfo,
          show: true,
          type: "danger",
          heading: "Failed",
          body: "Something Went Wrong!",
        });
        setTimeout(() => {
          setModalInfo({
            ...modalInfo,
            show: false,
          });
        }, 1000);
      });
  };

  return (
    <div>
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="supporters-dashboard-wrapper container">
          <div className="section-header">
            <h1 className="section-title"> Active Playlists </h1>
            <a
              href="#"
              className="add-btn"
              data-toggle="modal"
              data-target="#add_song"
              data-backdrop="static"
            >
              <i className="fa fa-plus"></i> Add a Song
            </a>
          </div>

          <div className="playlist-group">
            <div className="table-outer-link">
              <h2> Active/Editing "Playlist Name” </h2>
              <Link
                to="/member/playlist-group"
                state={{
                  isMember: isMember,
                  isFan: isFan,
                  isLoggedIn: isLoggedIn,
                }}
                className="link-primary"
              >
                Playlist Group
              </Link>
            </div>

            <div className="table-responsive">
              <table className="table table-red">
                <thead>
                  <tr>
                    <th> Song Name</th>
                    <th> Group</th>
                    <th className="text-center"> Price</th>
                    <th className="text-right"> Action</th>
                  </tr>
                </thead>
                <tbody>
                  {mySongsList.map((song) => (
                    <tr key={song.id}>
                      <td>{song.title}</td>
                      <td>
                        {" "}
                        {playlistGroup.find(
                          (grp) => grp.group_id == song.group_id
                        )
                          ? playlistGroup.find(
                              (grp) => grp.group_id == song.group_id
                            ).playlist_group_name
                          : "Group Not in List"}
                      </td>
                      <td className="text-center"> {song.amount}</td>
                      <td className="text-right">
                        <a
                          href="#"
                          className="add-btn"
                          data-toggle="modal"
                          data-target="#edit_song"
                          data-backdrop="static"
                          className="link text-decoration-none m-0 mr-3"
                          onClick={() => {
                            handleEditSongState(song.id);
                          }}
                        >
                          <i className="fa fa-edit mr-2"></i> Edit
                        </a>
                        <a
                          href="#"
                          className="link-error text-decoration-none m-0"
                          onClick={() => {
                            handleDeleteSongSubmit(song.id);
                          }}
                        >
                          <i className="fa fa-trash-alt mr-2"></i> Delete
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>

      {/* add a song modal */}
      <div
        className="modal fade"
        id="add_song"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close p-lg-3 text-right"
              data-dismiss="modal"
              aria-label="Close"
              id={"add-song-modal-close"}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <form onSubmit={handleAddSongSubmit}>
              <div className="modal-wrapper pt-0">
                <h1 className="mb-lg-5 font-weight-bold"> Add a Song </h1>

                <div className="form-row">
                  <div className="col-6">
                    <div className="form-group">
                      <Label
                        mainLabel={"Title"}
                        className={"text-black-50"}
                        for={"title"}
                      />
                      <Input
                        type={"text"}
                        name={"title"}
                        className={"form-control"}
                        id={"title"}
                        value={songtitle}
                        onChange={handleChange}
                        placeholder={"Title"}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Label
                        mainLabel={"Price ($)"}
                        className={"text-black-50"}
                        for={"price"}
                      />
                      <Input
                        type={"number"}
                        name={"price"}
                        min={0}
                        className={"form-control"}
                        id={"price"}
                        placeholder={"Price"}
                        value={songprice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <Label
                    mainLabel={"Group / Category"}
                    className={"text-black-50"}
                    for={"group-category"}
                  />
                  <select
                    id="group-category"
                    className="form-control"
                    value={songgroupcategory}
                    onChange={handleChange}
                  >
                    <option
                      defaultValue={"Select"}
                      readOnly={true}
                      hidden={true}
                    >
                      Select
                    </option>
                    {playlistGroup.map((grp) => (
                      <option value={grp.group_id} key={grp.group_id}>
                        {grp.playlist_group_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mt-lg-5 d-flex-fe">
                  {loading ? (
                    <button className="btn-lg-primary-full mr-0" disabled>
                      <LoaderComponent show={true} />{" "}
                    </button>
                  ) : (
                    <button className="btn-lg-primary-full mr-0" type="submit">
                      {" "}
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Edit song modal */}
      <div
        className="modal fade"
        id="edit_song"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close p-lg-3 text-right"
              data-dismiss="modal"
              aria-label="Close"
              id={"edit-song-modal-close"}
            >
              <span aria-hidden="true">&times;</span>
            </button>

            <form onSubmit={handleEditSongSubmit}>
              <div className="modal-wrapper pt-0">
                <h1 className="mb-lg-5 font-weight-bold">
                  Edit playlist price{" "}
                </h1>

                <div className="form-row">
                  <div className="col-6">
                    <div className="form-group">
                      <Label
                        mainLabel={"Title"}
                        className={"text-black-50"}
                        for={"edittitle"}
                      />
                      <span className={"form-control"} id={"edittitle"}>
                        {editSongInfo.title}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <Label
                        mainLabel={"Price ($)"}
                        className={"text-black-50"}
                        for={"editprice"}
                      />
                      <Input
                        type={"number"}
                        // name={"price"}
                        min={0}
                        className={"form-control"}
                        id={"editprice"}
                        placeholder={"Price"}
                        value={editSongInfo.price}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <Label
                    mainLabel={"Group / Category"}
                    className={"text-black-50"}
                    for={"edit-group-category"}
                  />
                  <select
                    id="edit-group-category"
                    className="form-control"
                    value={editSongInfo.group_id}
                    onChange={handleChange}
                  >
                    <option
                      defaultValue={"Select"}
                      readOnly={true}
                      hidden={true}
                    >
                      Select
                    </option>
                    {playlistGroup.map((grp) => (
                      <option value={grp.group_id} key={grp.group_id}>
                        {grp.playlist_group_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mt-lg-5 d-flex-fe">
                  {loading ? (
                    <button className="btn-lg-primary-full mr-0" disabled>
                      <LoaderComponent show={true} />{" "}
                    </button>
                  ) : (
                    <button className="btn-lg-primary-full mr-0" type="submit">
                      {" "}
                      Update
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Playlist;
