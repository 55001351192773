import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";
import { API_BASE_URL, postApi } from "../../../near/utils/localUtils";

class SupportingFollowing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn:
        JSON.parse(localStorage.getItem("isLoggedIn")) ||
        JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
        false,
      isMember:
        JSON.parse(localStorage.getItem("isMember")) ||
        JSON.parse(sessionStorage.getItem("isMember")) ||
        false,
      isFan:
        JSON.parse(localStorage.getItem("isFan")) ||
        JSON.parse(sessionStorage.getItem("isFan")) ||
        false,
      userInfo:
        JSON.parse(localStorage.getItem("userinfo")) ||
        JSON.parse(sessionStorage.getItem("userinfo")),
      userotherinfo:
        JSON.parse(localStorage.getItem("otherinfo")) ||
        JSON.parse(sessionStorage.getItem("otherinfo")),

      supportingsList: [],
      followingsList: [],
    };
    this.handleUnfollow = this.handleUnfollow.bind(this);
  }
  componentDidMount() {
    // fetch data for the fan
    if (this.state.isFan && this.state.isLoggedIn) {
      const formData = new FormData();
      formData.append(
        "user_id",
        this.state.userInfo.id || this.state.userInfo.user_id
      );
      formData.append("token", this.state.userotherinfo.token);

      postApi("frontapi/fan_following_list", formData)
        .then((res) => res.json())
        .then((data) => {
          if (data[0].status == "success") {
            this.setState({
              ...this.state,
              supportingsList: data[0].records,
              followingsList: data[0].records2,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  handleUnfollow(memberId, follow) {
    const formData = new FormData();
    formData.append("musician_id", memberId);
    formData.append(
      "user_id",
      this.state.userInfo.id || this.state.userInfo.user_id
    );
    formData.append("token", this.state.userotherinfo.token);

    postApi(follow ? "frontapi/follow" : "frontapi/unfollow", formData)
      .then((res) => res.json())
      .then((data) => {
        // follow
        if (data[0].status == "success") {
          if (follow) {
            const newFollowed = this.state.supportingsList.find(
              (member) => member.id == memberId || member.user_id == memberId
            );
            this.setState({
              ...this.state,
              followingsList: [...this.state.followingsList, newFollowed],
            });
            return;
          }

          // unfollow
          const updatedFollowingsList = this.state.followingsList.filter(
            (member) => member.id != memberId
          );
          this.setState({
            ...this.state,
            followingsList: updatedFollowingsList,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    SwiperCore.use([Navigation, EffectCoverflow, Pagination]);

    if (!this.state.isLoggedIn || !this.state.isFan) {
      return <Navigate to="/fan/sign-in" />;
    }

    return (
      <div>
        <Navbar
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
        />

        <main className="main-container">
          <div className="supporting-wrapper container">
            <div className="section-header">
              <h1 className="section-title"> Supporting </h1>
              <div className="swiper supporting-swiper">
                <div className="swiper-wrapper">
                  <Swiper
                    watchSlidesProgress={true}
                    slidesPerView={4}
                    spaceBetween={25}
                    navigation={true}
                    loop={true}
                    autoplay={{
                      delay: 7000,
                    }}
                    breakpoints={{
                      // when window width is >= 640px
                      320: {
                        slidesPerView: 1,
                      },
                      640: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 960
                      960: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {this.state.supportingsList.map((musician) => (
                      <SwiperSlide key={musician.id || musician.user_id}>
                        <div className="small-card">
                          <Link
                            to={`/members/${musician.slug}`}
                            state={{
                              isLoggedIn: true,
                              isFan: true,
                              isMember: false,
                            }}
                          >
                            <img
                              className="card-img"
                              src={
                                musician.pic.includes("http")
                                  ? `${musician.pic}`
                                  : `${API_BASE_URL}/${musician.pic}`
                              }
                              // src="/images/members/Amandalynn_Hickox.png"
                              alt=""
                            />
                          </Link>
                          <div className="card-content">
                            <div className="card-description">
                              <h1 className="card-title">
                                {" "}
                                {musician.musician}
                              </h1>
                              {/* <p className="card-text"> Musician / Band </p> */}
                            </div>
                            <div className="card-action">
                              {this.state.followingsList.find(
                                (mmbr) =>
                                  mmbr.id ||
                                  mmbr.user_id == musician.id ||
                                  musician.user_id
                              ) ? (
                                <a
                                  onClick={() => {
                                    this.handleUnfollow(
                                      musician.id || musician.user_id
                                    );
                                  }}
                                  className="btn-primary-full"
                                >
                                  Unfollow{" "}
                                </a>
                              ) : (
                                <a
                                  onClick={() => {
                                    this.handleUnfollow(
                                      musician.id || musician.user_id,
                                      true
                                    );
                                  }}
                                  className="btn-primary-full"
                                >
                                  Follow{" "}
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          <div className="following-wrapper container">
            <div className="section-header">
              <h1 className="section-title"> Following </h1>
              <div className="swiper following-swiper">
                <div className="swiper-wrapper">
                  <Swiper
                    watchSlidesProgress={true}
                    slidesPerView={4}
                    spaceBetween={25}
                    navigation={true}
                    loop={true}
                    autoplay={{
                      delay: 5000,
                    }}
                    breakpoints={{
                      // when window width is >= 640px
                      320: {
                        slidesPerView: 1,
                      },
                      640: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 2,
                      },
                      // when window width is >= 960
                      960: {
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {this.state.followingsList.map((musician) => (
                      <SwiperSlide key={musician.id || musician.user_id}>
                        <div className="small-card">
                          <Link
                            to={`/members/${musician.slug}`}
                            state={{
                              isLoggedIn: true,
                              isFan: true,
                              isMember: false,
                            }}
                          >
                            <img
                              className="card-img"
                              src={
                                musician.pic.includes("http")
                                  ? `${musician.pic}`
                                  : `${API_BASE_URL}/${musician.pic}`
                              }
                              // src="/images/members/Amandalynn_Hickox.png"
                              alt=""
                            />
                          </Link>
                          <div className="card-content">
                            <div className="card-description">
                              <h1 className="card-title">
                                {" "}
                                {musician.musician}
                              </h1>
                              {/* <p className="card-text"> Musician / Band </p> */}
                            </div>
                            <div className="card-action">
                              <a
                                onClick={() => {
                                  this.handleUnfollow(
                                    musician.id || musician.user_id
                                  );
                                }}
                                className="btn-primary-full"
                              >
                                {" "}
                                Unfollow{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    );
  }
}

export default SupportingFollowing;
