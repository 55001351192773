import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "../../../near/utils/localUtils";

class Banner extends Component {
  render() {
    const isLoggedIn = this.props.isLoggedIn;
    const isMember = this.props.isMember;
    const isFan = this.props.isFan;

    // for members
    if (isLoggedIn && isMember && !isFan) {
      return (
        <div>
          <div
            id="carouselExampleIndicators"
            className="carousel slide home-carousel"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="/images/background_1.png"
                  alt="Fan Background Image"
                />
                <div className="carousel-caption carousel-data-caption">
                  <h1>
                    <span> $176,313.25 </span> Earned on Tipjarlive.com{" "}
                  </h1>
                  <h1>
                    <span> 17,631,325 </span> $TIP Earned in Rewards{" "}
                  </h1>
                </div>
              </div>
              <div className="carousel-item">
                <img
                  src="/images/background_2.png"
                  alt="Member Background Image"
                />
                <div className="carousel-caption carousel-data-caption">
                  <h1>
                    <span> $176,313.25 </span> Earned on Tipjarlive.com{" "}
                  </h1>
                  <h1>
                    <span> 17,631,325 </span> $TIP Earned in Rewards{" "}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id="carouselExampleIndicators"
          className="carousel slide home-carousel"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            {/* slide 1 */}
            <div className="carousel-item active">
              <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-5">
                  <div className="nav custom-tab" id="nav-tab" role="tablist">
                    <a
                      className="nav-item nav-link active"
                      id="nav-home-tab"
                      data-toggle="tab"
                      href="#nav-home"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Fans
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-profile-tab"
                      data-toggle="tab"
                      href="#nav-profile"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      {" "}
                      Members{" "}
                    </a>
                  </div>
                  <div
                    className="custom-tab-content tab-content"
                    id="nav-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <h2> Sign Up Today And Show Some Love </h2>
                      <ul className="fa-ul list">
                        {this.props.bannerInfo.map((banner) =>
                          banner.title.split("\n").map((title, idx) => {
                            //   filter leading * and last space
                            if (title.includes("*") || !title.length) {
                              return null;
                            }
                            return (
                              <li id={idx} key={idx}>
                                <span className="fa-li">
                                  <i className="fas fa-check" />
                                </span>
                                {title}
                              </li>
                            );
                          })
                        )}
                      </ul>

                      <div className="bottom-info">
                        <div className="d-flex-jc">
                          <Link
                            to="/fan/sign-in"
                            className="btn-primary-outline"
                          >
                            Login
                          </Link>

                          <Link
                            to="/fan/sign-up"
                            className="btn-lg-primary-full"
                          >
                            Sign Up
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                    >
                      <h2> Sign Up To Receive Tips Instantly </h2>
                      <ul className="fa-ul list">
                        {this.props.bannerInfo.map((banner) =>
                          banner.title2.split("\n").map((title, idx) => {
                            //   filter leading * and last space
                            if (title.includes("*") || !title.length) {
                              return null;
                            }
                            return (
                              <li id={idx} key={idx}>
                                <span className="fa-li">
                                  <i className="fas fa-check" />
                                </span>
                                {title}
                              </li>
                            );
                          })
                        )}
                      </ul>

                      <div className="bottom-info">
                        <div className="d-flex-jc">
                          <Link
                            to="/member/sign-in"
                            className="btn-primary-outline"
                          >
                            Login
                          </Link>
                          <Link
                            to="/member/sign-up"
                            className="btn-lg-primary-full"
                          >
                            Sign Up
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {/*<img*/}
                  {/*    src="https://images.unsplash.com/photo-1516589178581-6cd7833ae3b2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"*/}
                  {/*    alt=""/>*/}

                  {this.props.bannerInfo.map((banner, idx) => (
                    <img
                      key={idx}
                      src={`${API_BASE_URL}${banner.featured_img}`}
                      alt="Fan Background Image"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Banner;
