import React from "react";
// import Notifications from "../pages/Notifications";

const NotificationItem = (props) => {
  return (
    // props.newNotification ?:()
    <div
      className="notifications-item"
      style={{ backgroundColor: props.view == "0" ? "#a9c4b9" : "inherit" }}
    >
      <div> {props.transactionType} </div>
      <div> {props.name}</div>
      <div> {props.date} </div>
      <div> {props.info}</div>
      <div>
        <img src={props.imageURL} alt={props.name} />
      </div>
      <div className="close-wrapper">
        <i className="fa fa-times close" />
      </div>
    </div>
  );
};

export default NotificationItem;
