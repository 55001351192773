import React, { useEffect, useState } from "react";
import { getApi, postApi } from "../../../near/utils/localUtils";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import LoaderComponent from "../../common/LoaderComponent";
import ModalComponent from "../../common/ModalComponent";
import Label from "../../common/Label";
import Input from "../../common/Input";

export default function VerfiyStripe() {
  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalInfo(false);
  };
  // ==========

  const [userinfo, setuserinfo] = useState(
    JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"))
  );
  const [userOtherInfo, setuserOtherInfo] = useState(
    JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"))
  );

  const handleVerify = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("user_id", userinfo.id || userinfo.user_id);
    formData.append("token", userOtherInfo.token);

    setLoading(true);

    postApi("frontapi/stripe_verification", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "success") {
          window.location.href = data[0].url;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div>
        {" "}
        <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />
        <div className="container">
          <div className="edit-profile-wrapper">
            <h1 className="mb-lg-5"> Stripe ID Verificaion </h1>
            <h2 className="mb-lg-3"> Your Stripe Account Is Not Verified! </h2>
            <br />
            {/* {stripePromise ? (
              <button onClick={handleSubmit}>Click Me</button>
            ) : null} */}
            <div className="content-wrapper">
              {loading ? (
                <div className="d-flex-fe">
                  <button className="btn-primary-full updateBtn mr-0">
                    <LoaderComponent show={true} />{" "}
                  </button>
                </div>
              ) : (
                <div className="d-flex-fe">
                  <button
                    onClick={handleVerify}
                    className="btn-primary-full updateBtn mr-0"
                  >
                    Verify Now
                  </button>
                </div>
              )}
            </div>
            {/* <div className="content-wrapper">
              <form onSubmit={handleSubmit}>
                <div className="form-row mb-5 display-picture">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="picture">Front Image</label>
                      <div className="image-upload">
                        <p>Choose Front Side of Your ID</p>

                        <div className="input-group rounded-pill bg-white shadow-sm">
                          <input
                            id="frontImg"
                            type="file"
                            accept="image/*"
                            name="pic"
                            onChange={handleChange}
                            className="form-control mb-0"
                            style={{ borderRadius: 5, border: 5 }}
                            reqruired
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="uploaded-image">
                      <p className="font-italic text-center mb-3">
                        The image uploaded will be rendered inside the box
                        below.
                      </p>
                      <div className="image-area">
                        <img
                          id="imageResult"
                          src={frontImgLocation}
                          alt=""
                          className="img-fluid rounded shadow-sm mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row mb-5 display-picture">
                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="picture">Back Image</label>
                      <div className="image-upload">
                        <p>Choose Back Side of Your ID</p>

                        <div className="input-group rounded-pill bg-white shadow-sm">
                          <input
                            id="backImg"
                            type="file"
                            accept="image/*"
                            name="pic"
                            onChange={handleChange}
                            className="form-control mb-0"
                            style={{ borderRadius: 5, border: 5 }}
                            reqruired
                          />
                          {/* <label
                            id="upload-label"
                            htmlFor="frontImg"
                            className="font-weight-light text-muted"
                          >
                            Choose file
                          </label> 
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="uploaded-image">
                      <p className="font-italic text-center mb-3">
                        The image uploaded will be rendered inside the box
                        below.
                      </p>
                      <div className="image-area">
                        <img
                          id="imageResult"
                          src={backImgLocation}
                          alt=""
                          className="img-fluid rounded shadow-sm mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div className="d-flex-fe">
                    <button className="btn-primary-full updateBtn mr-0">
                      <LoaderComponent show={true} />{" "}
                    </button>
                  </div>
                ) : (
                  <div className="d-flex-fe">
                    <button
                      className="btn-primary-full updateBtn mr-0"
                      type="submit"
                    >
                      Verify
                    </button>
                  </div>
                )}
              </form>
            </div> */}
          </div>
        </div>
      </div>
      {/* <button onClick={handleCheckResults}>Check result</button> */}
    </div>
  );
}

// export default function VerfiyStripe() {
//   const [stripePubKey, setstripePubKey] = useState("");

//   useEffect(async () => {
//     // fetch stripe pub key from server
//     const stripeKey = await getApi("frontapi/get_stripe_key");
//     setstripePubKey(stripeKey[0].stripe_public_key);
//   }, []);

//   if (stripePubKey.length) {
//     const stripePromise = loadStripe(stripePubKey);
//     return (
//       <Elements stripe={stripePromise}>
//         <VerifyStripeCompo />
//       </Elements>
//     );
//   } else {
//     return <></>;
//   }
// }
