import React from "react";
import { Link } from "react-router-dom";
import { logoutNearAccount } from "../../../../near/utils/browserUtils";
import { API_BASE_URL, postApi } from "../../../../near/utils/localUtils";
import { handleLogout } from "../../../utils/Utils";

function Navbar({ userinfo }) {
  // const handleLogout = (e) => {
  //   const formData = new FormData();

  //   const userotherinfo =
  //     JSON.parse(localStorage.getItem("otherinfo")) ||
  //     JSON.parse(sessionStorage.getItem("otherinfo")) ||
  //     {};

  //   // auth
  //   formData.append("user_id", userinfo.id);
  //   formData.append("token", userotherinfo.token);

  //   postApi("frontapi/logout", formData)
  //     .then((res) => res.json())
  //     .then((data) => {
  //       logoutLocal();
  //     })
  //     .catch((err) => {
  //       console.log("Err: ", err);
  //     });

  //   function logoutLocal() {
  //     sessionStorage.removeItem("isLoggedIn");
  //     sessionStorage.removeItem("isMember");
  //     sessionStorage.removeItem("isFan");
  //     sessionStorage.removeItem("userinfo");
  //     sessionStorage.removeItem("otherinfo");

  //     localStorage.removeItem("isLoggedIn");
  //     localStorage.removeItem("isMember");
  //     localStorage.removeItem("isFan");
  //     localStorage.removeItem("userinfo");
  //     localStorage.removeItem("otherinfo");
  //     logoutNearAccount();
  //   }
  // };

  return (
    <div>
      <div className="app-header header-shadow">
        <div className="app-header__logo">
          <div className="logo-src">
            <Link to="/member">
              <img src="/images/tipjarlive_logo.svg" alt="" />
            </Link>
          </div>
          <div className="header__pane ml-auto">
            <div>
              <button
                type="button"
                className="hamburger close-sidebar-btn hamburger--elastic"
                data-class="closed-sidebar"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="app-header__mobile-menu">
          <div>
            <button
              type="button"
              className="hamburger hamburger--elastic mobile-toggle-nav"
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <div className="app-header__menu">
          <span>
            <button
              type="button"
              className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
            >
              <span className="btn-icon-wrapper">
                <i className="fa fa-ellipsis-v fa-w-6"></i>
              </span>
            </button>
          </span>
        </div>
        <div className="app-header__content">
          <div className="app-header-right">
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                    <Link className="username" to="/">
                      Home
                    </Link>
                    <span className="border-line"> | </span>
                    <a className="username" onClick={handleLogout}>
                      Logout
                    </a>
                    <span className="border-line"> | </span>

                    {/* <a href="#">
                      <i className="far fa-eye"></i>
                    </a>
                    <span className="border-line"> | </span>
                    <a href="#">
                      <i className="far fa-bell"></i>
                    </a> */}
                  </div>
                  <div className="widget-content-left  ml-3 header-user-info">
                    <div className="username">
                      {userinfo.fname} {userinfo.lname}
                    </div>
                  </div>
                  <div className="widget-content-right header-user-info ml-3">
                    <div className="btn-group">
                      {userinfo.avatar ? (
                        <img
                          className="rounded-circle profile-photo"
                          src={
                            userinfo.avatar.includes("http")
                              ? `${userinfo.avatar}`
                              : `${API_BASE_URL}/${userinfo.avatar}`
                          }
                          alt=""
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
