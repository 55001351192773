import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import Input from "../components/common/Input";
import Label from "../components/common/Label";
import ToolTip from "../components/common/ToolTip";
import { postApi } from "../near/utils/localUtils";
import LoaderComponent from "../components/common/LoaderComponent";

const CheckoutForm = ({
  memberDetails,
  handlStripeModalClose,
  amount,
  btntext,
  trxData,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      JSON.parse(localStorage.getItem("isLoggedIn")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(sessionStorage.getItem("isFan")) ||
      JSON.parse(localStorage.getItem("isFan")) ||
      false
  );
  const [userinfo, setuserinfo] = useState(
    JSON.parse(sessionStorage.getItem("userinfo")) ||
      JSON.parse(localStorage.getItem("userinfo"))
  );

  useEffect(async () => {
    // setusdTipAmount(amount);
    // set usd tip modal state
    updatePaymentBreakdown(true, false, false);
  }, [amount]);

  // useEffect(async () => {
  //   // set usd tip modal state
  //   updatePaymentBreakdown(true, false, false);
  // }, [usdTipAmount]);

  // loading state
  const [loading, setLoading] = useState(false);

  // usd tip
  const [email, setemail] = useState("");
  const [cardName, setcardName] = useState("");

  // for usd tip
  const [usdSplitFees, setusdSplitFees] = useState(false);
  const [usdFullSupport, setusdFullSupport] = useState(true);
  const [usdNoFees, setusdNoFees] = useState(false);

  const [tjlFees, settjlFees] = useState(0.1);
  // const [amount, setusdTipAmount] = useState(amount);

  const [totalUsdAmount, settotalUsdAmount] = useState("");
  const [fanAmount, setfanAmount] = useState("");
  const [processingCharges, setprocessingCharges] = useState("");
  const [musAmount, setmusAmount] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      // for usd tip
      case "email":
        setemail(value);
        break;
      case "card-name":
        setcardName(value);
        break;

      case "split-fees":
        updatePaymentBreakdown(false, true, false);
        if (e.target.checked) {
          setusdSplitFees(e.target.checked);
          setusdFullSupport(!e.target.checked);
          setusdNoFees(!e.target.checked);
        } else {
          setusdSplitFees(e.target.checked);
        }
        break;
      case "full-support":
        updatePaymentBreakdown(true, false, false);
        if (e.target.checked) {
          setusdSplitFees(!e.target.checked);
          setusdFullSupport(e.target.checked);
          setusdNoFees(!e.target.checked);
        } else {
          setusdFullSupport(e.target.checked);
        }
        break;
      case "no-fees":
        updatePaymentBreakdown(false, false, true);
        if (e.target.checked) {
          setusdSplitFees(!e.target.checked);
          setusdFullSupport(!e.target.checked);
          setusdNoFees(e.target.checked);
        } else {
          setusdNoFees(e.target.checked);
        }
        break;
      default:
        break;
    }
  };

  // update usd charges stata
  const updatePaymentBreakdown = (full, split, no) => {
    if (full) {
      const tjl = Number((amount * 2.9) / 100 + 0.3);
      const processCharges = Number((tjlFees + tjl).toFixed(2));

      const inclusiveFees = Number((amount - processCharges).toFixed(2));
      const exclusiveFees = Number((amount + processCharges).toFixed(2));

      setprocessingCharges(processCharges);
      setmusAmount(inclusiveFees + processCharges);
      setfanAmount(exclusiveFees);
      settotalUsdAmount(exclusiveFees);
    } else if (split) {
      const tjl = Number((amount * 2.9) / 100 + 0.3);
      const processCharges = Number((tjlFees + tjl).toFixed(2) / 2);
      const processChargeDisplay = Number((tjlFees + tjl).toFixed(2));

      const inclusiveFees = Number((amount - processCharges).toFixed(2));
      const exclusiveFees = Number((amount + processCharges).toFixed(2));

      setprocessingCharges(processChargeDisplay);
      setmusAmount(inclusiveFees);
      setfanAmount(exclusiveFees);
      settotalUsdAmount(exclusiveFees);
    } else if (no) {
      const tjl = Number((amount * 2.9) / 100 + 0.3);
      const processCharges = Number((tjlFees + tjl).toFixed(2));
      const processChargeDisplay = Number((tjlFees + tjl).toFixed(2));

      const inclusiveFees = Number((amount - processCharges).toFixed(2));
      const exclusiveFees = Number((amount + processCharges).toFixed(2));

      setprocessingCharges(processChargeDisplay);
      setmusAmount(inclusiveFees);
      setfanAmount(inclusiveFees + processCharges);
      settotalUsdAmount(inclusiveFees + processCharges);
    }
  };

  const sendUSDTip = async (e) => {
    e.preventDefault();

    if (elements == null) {
      return;
    }

    const { token, error } = await stripe.createToken(
      elements.getElement(CardNumberElement)
    );

    if (error) {
      alert(error.message);
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("mid", memberDetails.id);
    formData.append("stripeToken", token.id);
    if (isFan && isLoggedIn) {
      formData.append("email", userinfo.email);
    } else {
      formData.append("email", email);
    }

    formData.append("page", trxData.page || "tip");
    formData.append("request_id", trxData.id || "");

    formData.append("mus_amount", musAmount);
    formData.append("tip_amount", amount);
    formData.append("amount", fanAmount);
    formData.append("total_vote", 0);

    let api = "frontapi/payment_nofan";
    if (userinfo && isFan) {
      api = "frontapi/payment_all";
      formData.append("fid", userinfo.id || userinfo.user_id);
      formData.append("user_id", userinfo.id || userinfo.user_id);
      const userotherinfo =
        JSON.parse(localStorage.getItem("otherinfo")) ||
        JSON.parse(sessionStorage.getItem("otherinfo"));
      formData.append("token", userotherinfo.token);
    }

    postApi(api, formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        handlStripeModalClose();
        console.log(data);
        if (data.status == "success" || data.status == "succeeded") {
          alert("Thank you! Transaction Successful!");
          // } else if (data.status == "success") {
          //   alert("Error! Transaction Successful!");
          setemail("");
          setcardName("");
          elements.getElement(CardNumberElement).clear();
          elements.getElement(CardExpiryElement).clear();
          elements.getElement(CardCvcElement).clear();
        } else if (data.status == "error") {
          alert(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {memberDetails ? (
        <h2 className="heading">
          Payment Information for a tip for <span> {memberDetails.name} </span>
        </h2>
      ) : (
        <h2></h2>
      )}
      <p>
        {" "}
        You are paying <span> {fanAmount} </span> for Tip.{" "}
      </p>
      <p>
        {" "}
        Processing Fees <span> {processingCharges} </span>
      </p>
      <p>
        {" "}
        Member Receives <span> {musAmount}</span>
      </p>

      <form onSubmit={sendUSDTip}>
        <div className="form-group mt-lg-5">
          <div className="form-check">
            <Input
              type={"checkbox"}
              className={"form-check-input"}
              id={"split-fees"}
              onChange={handleChange}
              isChecked={usdSplitFees}
            />
            <Label
              mainLabel={
                " Split Fees! - Split the cost of processing with the musician"
              }
              for={"split-fees"}
              className={"form-check-label text-dark"}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-check">
            <Input
              type={"checkbox"}
              className={"form-check-input"}
              id={"full-support"}
              onChange={handleChange}
              isChecked={usdFullSupport}
            />
            <Label
              mainLabel={
                "Full Support! - Pay the full cost of processing for this transaction"
              }
              for={"full-support"}
              className={"form-check-label text-dark"}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-check">
            <Input
              type={"checkbox"}
              className={"form-check-input"}
              id={"no-fees"}
              isChecked={usdNoFees}
              onChange={handleChange}
            />
            <label className="form-check-label text-dark" htmlFor="no-fees">
              No Fees - Not much to say here about that
              <ToolTip
                toolTipText={
                  "As a Fan you get to choose how the fees from your tips are paid. You can\n" +
                  "add the fee to your tip, split it with the musician, or subtract it from your tip."
                }
              />
            </label>
          </div>
        </div>
        {isLoggedIn && isFan ? (
          <div className="form-row">
            <div className="col-6">
              <div className="form-group">
                <Label mainLabel={"Card Name"} for={"email"} />
                <Input
                  type={"text"}
                  name={"card-name"}
                  className={"form-control"}
                  id={"card-name"}
                  placeholder={"Card Name"}
                  isRequired={true}
                  value={cardName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <Label mainLabel={"Card Number"} for={"card-number"} />
                <CardNumberElement className={"form-control"} />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="form-row">
              <div className="col-6">
                <div className="form-group">
                  <Label mainLabel={"Card Name"} for={"email"} />
                  <Input
                    type={"text"}
                    name={"card-name"}
                    className={"form-control"}
                    id={"card-name"}
                    placeholder={"Card Name"}
                    isRequired={true}
                    value={cardName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <Label mainLabel={"Email"} for={"email"} />
                  <Input
                    type={"text"}
                    name={"email"}
                    className={"form-control"}
                    id={"email"}
                    isRequired={true}
                    value={email}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="col">
                <div className="form-group">
                  <Label mainLabel={"Card Number"} for={"card-number"} />
                  <CardNumberElement className={"form-control"} />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="form-row">
          <div className="col-6">
            <div className="col-6">
              <div className="form-group">
                <Label mainLabel={"Expiry Date"} for={"card-number"} />
                <CardExpiryElement className={"form-control"} />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <Label mainLabel={"CVV/CVC"} />
              <CardCvcElement className={"form-control"} />
            </div>
          </div>
        </div>
        <br />
        <div className="mt-lg-5 d-flex-fe">
          {loading ? (
            <button className="btn-success-full mr-0" disabled>
              <LoaderComponent show={true} />{" "}
            </button>
          ) : (
            <button className="btn-success-full mr-0" type="submit">
              {btntext ? btntext : "Submit"}
            </button>
          )}
        </div>
      </form>
    </>
  );
};

const StripeComponent = ({
  memberDetails,
  handlStripeModalClose,
  stripePubKey,
  amount,
  btntext,
  trxData,
}) => {
  const stripePromise = loadStripe(stripePubKey);
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        memberDetails={memberDetails}
        handlStripeModalClose={handlStripeModalClose}
        amount={amount}
        btntext={btntext || null}
        trxData={trxData || {}}
      />
    </Elements>
  );
};
export default StripeComponent;
