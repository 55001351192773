import React from "react";
import Home from "./pages/Home";
import Members from "./pages/Members";
import Events from "./pages/Events";
import ScrollToTop from "./include/ScrollToTop";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import FaQs from "./pages/FAQs";
import FanSignIn from "./pages/fan/SignIn";
import MemberSignIn from "./pages/member/SignIn";
import FanSignUp from "./pages/fan/SignUp";
import MemberSignUp from "./pages/member/SignUp";
import MemberHome from "./pages/member/Index";
import FanHome from "./pages/fan/Index";
import FanSupportingFollowing from "./pages/fan/SupportingFollowing";
import FanHistoryManagement from "./pages/fan/HistoryManagement";
import SupporterDashboard from "./pages/member/SupporterDashboard";
import FollowerCampaign from "./pages/member/FollowerCampaign";
import Playlist from "./pages/member/Playlist";
import PaymentReceived from "./pages/member/PaymentReceived";
import FollowerCampaignHistory from "./pages/member/FollowerCampaignHistory";
import CampaignTemplate from "./pages/member/CampaignTemplate";
import FollowerSettings from "./pages/member/FollowerSettings";
import PlaylistGroup from "./pages/member/PlaylistGroup";
import EventDetails from "./pages/event/EventDetails";
import MemberDashboard from "./pages/member/dashboard/Dashboard";
import MemberDetails from "./pages/member/MemberDetails";
import { logoutNearAccount } from "../near/utils/browserUtils";
import Notifications from "./pages/Notifications";
import OTP from "./pages/OTP";
import ForgotPassword from "./common/ForgotPassword";

function AppRoute() {
  const loggedIn =
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
    JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
    false;
  const userinfo =
    JSON.parse(localStorage.getItem("userinfo")) ||
    JSON.parse(sessionStorage.getItem("userinfo")) ||
    {};
  // const isFan =
  //   JSON.parse(localStorage.getItem("isFan")) ||
  //   JSON.parse(sessionStorage.getItem("isFan")) ||
  //   false;
  // const isMember =
  //   JSON.parse(localStorage.getItem("isMember")) ||
  //   JSON.parse(sessionStorage.getItem("isMember")) ||
  //   false;
  const isNearLoggedin = window.walletConnection.isSignedIn();
  // logout near account if fan is not logged-in (remember me not used)
  if (!loggedIn && isNearLoggedin && userinfo.fannearid) {
    // logout only if not signed-in just now
    // if (!document.referrer.includes("near.org")) {
    // logoutNearAccount();
    // }
  }

  return (
    <BrowserRouter>
      <div>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/members" element={<Members />} />
          <Route path="/events" element={<Events />} />
          <Route path="/faqs" element={<FaQs />} />
          <Route path="/fan/sign-in" element={<FanSignIn />} />
          <Route path="/fan/sign-up" element={<FanSignUp />} />
          <Route path="/member/sign-in" element={<MemberSignIn />} />
          <Route path="/member/sign-up" element={<MemberSignUp />} />
          <Route path="/fan" element={<FanHome />} />
          <Route path="/fan/members" element={<Members />} />
          <Route path="/fan/upcoming-events" element={<Events />} />
          <Route
            path="/fan/supporting-following"
            element={<FanSupportingFollowing />}
          />
          <Route
            path="/fan/history-management"
            element={<FanHistoryManagement />}
          />
          <Route path="/fan/faqs" element={<FaQs />} />

          <Route path="/member" element={<MemberHome />} />
          {/* -------------- allow on sign in only -------------- */}
          <Route path="/member/my-events" element={<Events />} />
          <Route
            path="/member/supporters-dashboard"
            element={<SupporterDashboard />}
          />
          <Route
            path="/member/follower-campaign"
            element={<FollowerCampaign />}
          />
          <Route path="/member/playlist" element={<Playlist />} />
          <Route
            path="/member/payment-received"
            element={<PaymentReceived />}
          />
          <Route path="/member/faqs" element={<FaQs />} />
          <Route
            path="/member/follower-campaign-history"
            element={<FollowerCampaignHistory />}
          />
          <Route
            path="/member/campaign-template"
            element={<CampaignTemplate />}
          />
          <Route
            path="/member/follower-settings"
            element={<FollowerSettings />}
          />
          <Route path="/member/playlist-group" element={<PlaylistGroup />} />
          <Route path="/event/:id" element={<EventDetails />} />
          <Route path="/member/dashboard" element={<MemberDashboard />} />
          {/* <Route path="/member/dashboard" element={<MemberDashboard />} /> */}
          <Route path="/members/:slug" element={<MemberDetails />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/otp-verification" element={<OTP />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default AppRoute;
