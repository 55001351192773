import React, { useEffect, useState } from "react";
import Navbar from "../include/Navbar";
import { Link, useLocation } from "react-router-dom";
import Footer from "../include/Footer";
import Label from "../common/Label";
import Input from "../common/Input";
import { API_BASE_URL, getApi, postApi } from "../../near/utils/localUtils";
import { handleLogout } from "../utils/Utils";

import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

function Events({ listMapButton = true, memberName = "" }) {
  // let location = useLocation();

  const [loading, setloading] = useState(true);
  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  // all events data
  const [eventsList, seteventsList] = useState([]);
  const [eventMap, seteventMap] = useState([]);
  const [eventsAllDataBackup, seteventsAllDataBackup] = useState([]);

  // for search bar
  const [eventType, setEventType] = useState([]);

  // search/querry inputs
  const [eventNameSearch, seteventNameSearch] = useState("");
  const [eventDateSearch, seteventDateSearch] = useState("");
  const [eventTypeSearch, seteventTypeSearch] = useState("Select");

  useEffect(async () => {
    // get event map view

    if (isMember && isLoggedIn) {
      const userinfolocal =
        JSON.parse(localStorage.getItem("userinfo")) ||
        JSON.parse(sessionStorage.getItem("userinfo"));
      const userotherinfo =
        JSON.parse(localStorage.getItem("otherinfo")) ||
        JSON.parse(sessionStorage.getItem("otherinfo"));
      const authData = new FormData();
      // auth
      authData.append("user_id", userinfolocal.id);
      authData.append("token", userotherinfo.token);

      // list of all events
      let memberEventsList = await postApi("frontapi/event_list", authData);

      memberEventsList = await memberEventsList.json();
      memberEventsList = memberEventsList.filter((evnt) => evnt.private != 1);
      if (memberEventsList[0].status != "error") {
        effectfunc(memberEventsList);
      } else {
        handleLogout();
      }
    } else {
      let allEventData = await getApi("frontapi/event_list_fan");
      allEventData = allEventData.filter((evnt) => evnt.private != 1);
      effectfunc(allEventData);
    }

    async function effectfunc(eventListData) {
      if (memberName.length) {
        // fitler events for specific member
        const memberEvents = eventListData[0].records.filter(
          (event) => event.musician.toLowerCase() == memberName.toLowerCase()
        );
        seteventsList(memberEvents);
        // for searching and resetting
        seteventsAllDataBackup(memberEvents);
      } else {
        seteventsList(eventListData[0].records);

        // for searching and resetting
        seteventsAllDataBackup(eventListData[0].records);
      }

      const eventMapData = await getApi("frontapi/eventMapView");
      seteventMap(eventMapData[0].result);
      setEventType(eventMapData[0].event_type);
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "eventName":
        seteventNameSearch(value);
        break;
      case "eventType":
        seteventTypeSearch(value);
        break;
      case "eventDate":
        seteventDateSearch(value);
        break;

      default:
        break;
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let filteredData = eventsAllDataBackup;
    if (eventNameSearch.length) {
      filteredData = filteredData.filter((event) =>
        event.title.toLowerCase().includes(eventNameSearch.toLowerCase())
      );
    }
    // type filter
    if (
      eventTypeSearch.length &&
      filteredData.length &&
      eventTypeSearch != "Select"
    ) {
      filteredData = filteredData.filter((event) => {
        return event.typ == eventTypeSearch;
      });
    }

    // filter date
    if (eventDateSearch.length && filteredData.length) {
      filteredData = filteredData.filter((event) => {
        let eventDateInt = new Date(event.datetime.split(" ")[0]).getTime();
        let searchDateInt = new Date(eventDateSearch).getTime();
        return eventDateInt == searchDateInt;
      });
    }

    seteventsList(filteredData);
  };

  // to clear search
  const handleResetSearch = () => {
    seteventsList(eventsAllDataBackup);
    seteventTypeSearch("Select");
    seteventNameSearch("");
    seteventDateSearch("");
  };

  const [currentPosition, setCurrentPosition] = useState({
    lng: 38.9072,
    lat: 77.0369,
  });

  const success = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setCurrentPosition(currentPosition);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  });

  const [selected, setSelected] = useState({});
  const onSelect = (item) => {
    setSelected(item);
  };

  return (
    <>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="events-wrapper container">
          <div className="section-header">
            <h1 className="section-title">Events</h1>
            {listMapButton ? (
              <div className="d-flex-jc">
                <ul className="nav nav-tabs custom-nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#list"
                      role="tab"
                    >
                      List
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#maps"
                      role="tab"
                    >
                      Map
                    </a>
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
          <div className="main-content">
            <div className="filter-selection">
              <form onSubmit={handleSearch}>
                <div className="form-group filter-item">
                  <Label mainLabel={"Event Name"} for={"eventName"} />
                  <Input
                    type={"text"}
                    name={"eventName"}
                    className={"form-control"}
                    id={"eventName"}
                    placeholder={"Event Name"}
                    onChange={handleChange}
                    value={eventNameSearch}
                  />
                </div>
                <div className="form-group filter-item">
                  <label htmlFor="eventType">Event Type</label>
                  <select
                    id="eventType"
                    className="form-control"
                    onChange={handleChange}
                    value={eventTypeSearch}
                  >
                    <option defaultValue="Select" readOnly={true}>
                      Select
                    </option>
                    {eventType.map((opt) => (
                      <option key={opt.id}>{opt.title}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group filter-item">
                  <Label mainLabel={"Event Date"} for={"eventDate"} />
                  <Input
                    type={"date"}
                    name={"eventDate"}
                    className={"form-control"}
                    id={"eventDate"}
                    autoComplete={"off"}
                    placeholder={"Event Date"}
                    onChange={handleChange}
                    value={eventDateSearch}
                  />
                </div>
                <div className="form-group filter-item">
                  <label htmlFor="filterByDistance">
                    Filter (by distance){" "}
                  </label>
                  <select id="filterByDistance" className="form-control">
                    <option defaultValue="Select" readOnly={true}>
                      Select
                    </option>
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>25</option>
                    <option>30</option>
                  </select>
                </div>
                <div className="button-wrapper filter-item">
                  <button className="btn-primary-full mr-0" type="submit">
                    Search
                  </button>
                  <div
                    className="icon-wrapper filter-item"
                    onClick={handleResetSearch}
                  >
                    <i className="fa fa-redo"></i>
                  </div>
                </div>
              </form>
            </div>
            <div className="tab-content custom-tab-content">
              <EventsListView
                eventsList={eventsList}
                isLoggedIn={isLoggedIn}
                isMember={isMember}
                isFan={isFan}
              />
              <div className="tab-pane" id="maps" role="tabpanel">
                <LoadScript googleMapsApiKey="AIzaSyA2VohtbyuoHxWCxvU8QAb1RfgEvtY2xL8">
                  <GoogleMap zoom={5} center={currentPosition}>
                    
                    {eventMap.map((item) => {
                      return (
                        <Marker
                          key={item.MarkerId}
                          position={{
                            lat: parseFloat(item.lat),
                            lng: parseFloat(item.lng),
                          }}
                          onClick={() => onSelect(item)}
                        />
                      );
                    })}
                    {selected.lat && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(selected.lat),
                          lng: parseFloat(selected.lng),
                        }}
                        clickable={true}
                        onCloseClick={() => setSelected({})}
                      >
                        <p> {selected.DisplayText}</p>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export const EventsListView = ({ eventsList, isLoggedIn, isFan, isMember }) => (
  <div className="tab-pane active" id="list" role="tabpanel">
    {eventsList.length ? (
      <div className="row">
        {eventsList.map((event, id) => (
          <div className="col-md-4" key={id}>
            <div className="big-card">
              <img
                className="card-img"
                src={
                  event.featured_img.includes("http")
                    ? `${event.featured_img}`
                    : `${API_BASE_URL}/${event.featured_img}`
                }
              />
              <div className="card-content">
                <div className="card-description">
                  <h1 className="card-title">{event.title}</h1>
                  <p className="card-text">{event.content}</p>
                </div>
                <div className="d-flex-sb d-flex-ai">
                  <p className="date">
                    <i className="fa fa-map-marker-alt"></i>
                    <span>{event.venue} </span>
                  </p>
                  <p className="location">
                    <i className="fa fa-calendar-alt"></i>
                    <span>
                      {" "}
                      {event.datetime.split(" ")[0]}
                      {/*<br />*/}
                      {/*{event.time}*/}
                    </span>
                  </p>
                </div>
                <div className="card-action">
                  <div className="artist">
                    <a href="#">
                      <div className="d-flex-sb">
                        <img src="/images/evn.png" alt="" />
                        <div className="ml-3">
                          <p className="artist-name mb-0"> {event.musician} </p>
                          {/* <p className="artist-followers">
                          <span> 12 </span> Followers{" "}
                        </p> */}
                        </div>
                      </div>
                    </a>
                  </div>
                  <Link
                    to={`/event/${event.id}`}
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="arrow-btn mr-3"
                  >
                    VIEW MORE
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <h2>No Event Found!</h2>
    )}
  </div>
);

export default Events;
