import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import { API_BASE_URL, post, postApi } from "../../../near/utils/localUtils";

function SupporterDashboard() {
  const navigate = useNavigate();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );
  // all data
  const [userinfo, setuserinfo] = useState({});
  const [userotherinfo, setuserotherinfo] = useState({});

  // data used
  const [subscribers, setsubscribers] = useState([]);
  const [followers, setfollowers] = useState([]);

  // filter
  const [filterby, setfilterby] = useState("all");

  useEffect(() => {
    const loggedIn =
      JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn"));
    const isMember =
      JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember"));

    if (!isMember || !loggedIn) {
      navigate("/member/sign-in");
    }

    // functions
    const userinfoLocal =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));
    const userotherinfoLocal =
      JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"));
    setuserinfo(userinfoLocal);
    setuserotherinfo(userotherinfoLocal);

    const formdata = new FormData();
    formdata.append("token", userotherinfoLocal.token);
    formdata.append("user_id", userinfoLocal.id);
    // formData.append("user_id", userinfo.id);
    // formData.append("token", userotherinfo.token);
    formdata.append("user_type", "Member");
    postApi("frontapi/follower_list", formdata)
      .then((res) => res.json())
      .then((data) => {
        if (data[0].status == "success") {
          setfollowers(data[0].followers);
          setsubscribers(data[0].subscribers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlFilter = (e) => {
    const { value } = e.target;
    setfilterby(value);
  };

  const exportToCsv = () => {
    const formdata = new FormData();
    formdata.append("token", userotherinfo.token);
    formdata.append("user_id", userinfo.id);
    formdata.append("usd_type", "member");
    postApi("musician/query_to_csv_musician", formdata)
      .then((res) => res.text())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="supporters-dashboard-wrapper container">
          <div className="section-header">
            <h1 className="section-title"> Supporters Dashboard </h1>
            <div className="d-flex-sb">
              <Link
                to="/member/follower-settings"
                state={{
                  isLoggedIn: isLoggedIn,
                  isFan: isFan,
                  isMember: isMember,
                }}
                className="add-btn"
              >
                <i className="fa fa-plus"></i> Add / Change Subscription Levels
              </Link>
            </div>
          </div>

          <div className="main-content mb-lg-5">
            <div className="filter-selection">
              <form action="">
                <div className="filter-wrapper">
                  <div className="filter-by">
                    <span className="mr-5"> Filter By </span>
                    <div className="form-group mb-0">
                      <select
                        id="eventType"
                        className="form-control mb-0"
                        onChange={handlFilter}
                      >
                        <option
                          defaultValue={"Select"}
                          readOnly={true}
                          hidden={true}
                        >
                          Select
                        </option>
                        <option value={"all"}>All Fans</option>
                        <option value={"subscribers"}>Subscribers</option>
                        <option value={"followers"}>Followers</option>
                        {/* <option value={"supporters"}>Friend</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="filter-links-wrapper">
                    <div className="filter-links">
                      <Link
                        to="/member/follower-campaign"
                        state={{
                          isLoggedIn: isLoggedIn,
                          isFan: isFan,
                          isMember: isMember,
                        }}
                        className="link-primary mr-3"
                      >
                        Dated:{" "}
                      </Link>

                      <Link
                        to="/member/campaign-template"
                        state={{
                          isLoggedIn: isLoggedIn,
                          isFan: isFan,
                          isMember: isMember,
                        }}
                        className="link-primary ml-3 mr-3"
                      >
                        Campaign Templates
                      </Link>
                    </div>
                    <div className="filter-button">
                      <a className="btn-default" onClick={exportToCsv}>
                        <i className="far fa-file-excel text-success"></i>{" "}
                        Export All Data
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="section-header mt-lg-5 mb-lg-2">
              <h1 className="section-title"> Supporters </h1>
            </div>

            <div className="row">
              {/* subscribers */}
              {subscribers &&
              (filterby == "subscribers" || filterby == "all") ? (
                <>
                  {subscribers.map((subscriber) => (
                    <div className="col-md-3" key={subscriber.id}>
                      <div className="card-content">
                        {" "}
                        <div className="small-card">
                          {subscriber.pic ? (
                            <img
                              className="card-img"
                              src={
                                subscriber.pic.includes("http")
                                  ? `${subscriber.pic}`
                                  : `${API_BASE_URL}/${subscriber.pic}`
                              }
                              alt=""
                            />
                          ) : (
                            <img
                              className="card-img"
                              src="/images/cards/2.jpeg"
                              alt=""
                            />
                          )}

                          <div className="card-description">
                            {/* <h1 className="card-title"></h1> */}
                            <p className="card-text">{subscriber.fan}</p>
                            <p className="card-text">
                              {subscriber.is_created.split(" ")[0]}
                            </p>
                            {/* <p className="card-text"> Friend - Monthly</p> */}
                          </div>
                          <div className="card-action d-flex-fe">
                            {/* <a href="#" className="btn-primary-full">
                              {" "}
                              <i className="fa fa-trash-alt"></i>{" "}
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
              {/* followers */}
              {followers && (filterby == "followers" || filterby == "all") ? (
                <>
                  {followers.map((follower) => (
                    <div className="col-md-3" key={follower.id}>
                      <div className="card-content">
                        {" "}
                        <div className="small-card">
                          {follower.pic ? (
                            <img
                              className="card-img"
                              src={
                                follower.pic.includes("http")
                                  ? `${follower.pic}`
                                  : `${API_BASE_URL}/${follower.pic}`
                              }
                              alt=""
                            />
                          ) : (
                            <img
                              className="card-img"
                              src="/images/cards/2.jpeg"
                              alt=""
                            />
                          )}

                          <div className="card-description">
                            {/* <h1 className="card-title">{follower.fan}</h1> */}
                            <p className="card-text">{follower.fan}</p>
                            <p className="card-text">
                              {follower.is_created.split(" ")[0]}
                            </p>
                            {/* <p className="card-text"> Friend - Monthly</p> */}
                          </div>
                          <div className="card-action d-flex-fe">
                            {/* <a href="#" className="btn-primary-full">
                              {" "}
                              <i className="fa fa-trash-alt"></i>{" "}
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default SupporterDashboard;
