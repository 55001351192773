import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { getApi, postApi } from "../../../near/utils/localUtils";
import ModalComponent from "../../common/ModalComponent";
import LoaderComponent from "../../common/LoaderComponent";
import ToolTip from "../../common/ToolTip";
import { loginNearFunctional } from "../../../near/utils/browserUtils";

function SignIn(props) {
  const navigate = useNavigate();

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const [loading, setLoading] = useState(false);

  const paddingForm = {
    padding: "0 4rem",
  };

  const paddingTitle = {
    padding: "3rem 0",
  };

  const buttonPadding = {
    padding: "1rem 0",
    borderRadius: "4px",
    margin: "2rem 0 1rem 0",
  };

  const handleModalClose = () => {
    setModalInfo(false);
  };

  // for near sign-in/sign-up
  const rerouteNEARlogin = async () => {
    if (window.walletConnection.isSignedIn()) {
      // check if near user
      if (sessionStorage.getItem("userinfo")) {
        const { membernearid, redirecting } = JSON.parse(
          sessionStorage.getItem("userinfo")
        );
        // if near account is logged in after redirecting to near site
        // log-in the fan near acc
        if (
          membernearid &&
          redirecting &&
          !JSON.parse(sessionStorage.getItem("isLoggedIn"))
        ) {
          const formData = new FormData();
          formData.append(
            "near_id",
            window.walletConnection.account().accountId
          );

          postApi("frontapi/mus_near_login", formData)
            .then((res) => res.json())
            .then(async (data) => {
              if (data[0].status == "invalid") {
                // get near reg form fields data
                const signupFormData = await getApi("frontapi/mus_signup");
                setcateroyList(signupFormData[0].category);
                setskillsList(signupFormData[0].skills);

                document.getElementById("memberNearModalOpen").click();
              } else if (data[0].status == "success") {
                const {
                  musicians,
                  subscribers,
                  mus_playlist,
                  mus_playlist_group,
                  tips_anonymous,
                  tips_registered_fan,
                  followers,
                  token,
                  subscribe_package,
                } = data[0];
                const userinfoData = musicians[0];
                const otherinfoData = {
                  subscribers,
                  mus_playlist,
                  mus_playlist_group,
                  tips_anonymous,
                  tips_registered_fan,
                  followers,
                  token,
                  subscribe_package,
                };

                // set session storage only
                sessionStorage.setItem(
                  "userinfo",
                  JSON.stringify(userinfoData)
                );
                sessionStorage.setItem(
                  "otherinfo",
                  JSON.stringify(otherinfoData)
                );
                sessionStorage.setItem("isMember", true);
                sessionStorage.setItem("isLoggedIn", true);
                sessionStorage.setItem("isFan", false);

                setModalInfo({
                  ...modalInfo,
                  show: true,
                  type: "primary",
                  heading: "Logged In",
                  body: "Welcome Back! We Missed You ",
                });
                setTimeout(() => {
                  setModalInfo({
                    ...modalInfo,
                    show: false,
                  });
                  navigate("/member");
                }, 1000);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
      // if near account is connected but not user not logged-in (or just connected the account)
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
      return;
    }

    rerouteNEARlogin();
    sessionStorage.removeItem("membernearsignup");
  }, []);

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  // near signup form fields info

  const [cateroyList, setcateroyList] = useState([]);
  const [skillsList, setskillsList] = useState([]);

  // for near signup
  const [nearfName, setnearfName] = useState("");
  const [nearlName, setnearlName] = useState("");
  const [nearemail, setnearemail] = useState("");
  const [nearphone, setnearphone] = useState("");
  const [nearuserRefCode, setnearruserRefCode] = useState("");
  const [nearcategoryType, setnearcategoryType] = useState();
  const [nearcategory, setnearcategory] = useState();

  const [nearusedreferralCode, setnearusedreferralCode] = useState("");
  const [nearavatarLocation, setnearavatarLocation] = useState("");
  const [nearavatar, setnearavatar] = useState(null);
  const [nearloading, setnearloading] = useState("");

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [rememberMe, setrememberMe] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id == "email") {
      setemail(value);
    } else if (id == "password") {
      setpassword(value);
    } else if (id == "rememberMe") {
      setrememberMe(e.target.checked);
    } else if (id == "fName") {
      setnearfName(value);
    } else if (id == "lName") {
      setnearlName(value);
    } else if (id == "mobile") {
      setnearphone(value);
    } else if (id == "user_ref_code") {
      setnearruserRefCode(value);
    } else if (id == "nearemail") {
      setnearemail(value);
    } else if (id == "referralCode") {
      setnearusedreferralCode(value);
    } else if (id == "upload") {
      // setnearemail(value);
      setnearavatarLocation(URL.createObjectURL(e.target.files[0]));
      setnearavatar(e.target.files[0]);
    } else if (id == "email") {
      setnearemail(value);
    } // member type as object
    else if (id == "categoryType") {
      setnearcategoryType(value);
    } else if (id == "category") {
      let value = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setnearcategory(value);
    }
  };

  const handleSigninWithNear = async () => {
    // e.preventDefault();
    loginNearFunctional();

    sessionStorage.setItem(
      "userinfo",
      JSON.stringify({
        membernearid: true,
        redirecting: true,
      })
    );
  };

  const handleNearReg = async (e) => {
    e.preventDefault();

    setnearloading(true);

    const formdata = new FormData();

    formdata.append("near_id", window.walletConnection.account().accountId);
    formdata.append("user_type", "near");
    formdata.append("fname", nearfName);
    formdata.append("lname", nearlName);
    formdata.append("email", nearemail);
    formdata.append("ref_code", nearuserRefCode);
    formdata.append("reffral_code", nearusedreferralCode);
    formdata.append("mobile", nearphone);
    formdata.append("cid", nearcategoryType);
    formdata.append("avatar", nearavatar);
    formdata.append("submit", "true");
    for (var i = 0; i < nearcategory.length; i++) {
      formdata.append("skills[]", nearcategory[i]);
    }
    const refCode = new FormData();
    refCode.append("referral", nearusedreferralCode);
    // check if referral code is valid
    postApi(`Frontapi/check_referral`, refCode)
      .then((res) => res.json())
      .then((data) => {
        if (data.status == "success") {
          postNearRegForm(formdata);
        } else {
          setnearloading(false);
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Error",
            body: data.message,
          });
        }
      })
      .catch((err) => {
        setModalInfo({
          ...modalInfo,
          show: true,
          type: "danger",
          heading: "Error",
          body: "Something went wrong!",
        });
        setLoading(false);
      });

    async function postNearRegForm(formData) {
      postApi("frontapi/mus_near_signup", formData)
        .then((res) => {
          setnearloading(false);
          return res.json();
        })
        .then((data) => {
          if (data[0].status == "success") {
            rerouteNEARlogin();
            document.getElementById("near-reg-close-btn").click();
          } else {
            setModalInfo({
              ...modalInfo,
              show: true,
              type: "danger",
              heading: "Error",
              body: data.message || "Something went wrong",
            });
          }
        })
        .catch((err) => {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Error",
            body: "Something Went Wrong!",
          });
          console.log("Err: ", err);
        });
    }
  };

  const handlSubmit = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("submit", "login");

    setLoading(true);

    //dev.tipjarlove.com/frontapi/mus_login
    postApi("frontapi/mus_login", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "success") {
          localStorage.clear();
          sessionStorage.clear();

          const {
            musicians,
            subscribers,
            mus_playlist,
            mus_playlist_group,
            tips_anonymous,
            tips_registered_fan,
            followers,
            token,
            subscribe_package,
          } = data[0];

          localStorage.clear();
          sessionStorage.clear();

          // navigate to otp verification if required
          if (!musicians) {
            sessionStorage.setItem("otpemail", email);
            sessionStorage.setItem("isMember", true);
            if (rememberMe) {
              localStorage.setItem("otpemail", email);
              localStorage.setItem("isMember", true);
            }
            navigate("/otp-verification");
            return;
          }

          const userinfoData = musicians[0];
          const otherinfoData = {
            subscribers,
            mus_playlist,
            mus_playlist_group,
            tips_anonymous,
            tips_registered_fan,
            followers,
            token,
            subscribe_package,
          };

          // set session storage only
          sessionStorage.setItem("userinfo", JSON.stringify(userinfoData));
          sessionStorage.setItem("otherinfo", JSON.stringify(otherinfoData));
          sessionStorage.setItem("isMember", true);
          sessionStorage.setItem("isLoggedIn", true);
          sessionStorage.setItem("isFan", false);

          if (rememberMe) {
            localStorage.setItem("userinfo", JSON.stringify(userinfoData));
            localStorage.setItem("otherinfo", JSON.stringify(otherinfoData));
            localStorage.setItem("isMember", true);
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("isFan", false);
          }

          setModalInfo({
            ...modalInfo,
            show: true,
            type: "primary",
            heading: "Logged In",
            body: "Welcome Back! We Missed You ",
          });
          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
            navigate("/member");
          }, 1000);
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Failed",
            body: "Invalid Credentials! ",
          });
          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
          }, 1000);
        }
      })
      .catch((err) => {
        setModalInfo({
          ...modalInfo,
          show: true,
          type: "danger",
          heading: "Failed",
          body: "Signin Failed!",
        });
        setTimeout(() => {
          setModalInfo({
            ...modalInfo,
            show: false,
          });
        }, 1000);

        console.log(err);
      });
  };

  return (
    <div>
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="fan-container container">
          <div className="form-wrapper">
            <div className="ribbon-heading">
              <h2> Member Login </h2>
            </div>
            <h1 className="section-title">Sign In</h1>
            <p> Welcome, we missed you! </p>
            <form
              onSubmit={handlSubmit}
              // action="https://dev.tipjarlove.com/front/mus_login"
              // action="https://dev.tipjarlove.com/frontapi/mus_login"
              // method="POST"
              // encType="multipart/form-data"
            >
              <div className="form-group">
                <Label mainLabel={"Email address"} for={"email"} />
                <Input
                  type={"email"}
                  className={"form-control"}
                  id={"email"}
                  placeholder={"Email"}
                  value={email}
                  name="email"
                  onChange={handleChange}
                  isRequired={true}
                />
              </div>

              <div className="form-group">
                <Label mainLabel={"Password"} for={"password"} />
                <Input
                  type={"password"}
                  className={"form-control"}
                  id={"password"}
                  name="password"
                  placeholder={"Password"}
                  value={password}
                  onChange={handleChange}
                  isRequired={true}
                />
              </div>

              <div className="d-flex-sb">
                <div className="form-group">
                  <div className="form-check">
                    <Input
                      type={"checkbox"}
                      onChange={handleChange}
                      // value={rememberMe}
                      checked={rememberMe}
                      className={"form-check-input"}
                      id={"rememberMe"}
                    />
                    <Label
                      mainLabel={"Remember Me"}
                      className={"form-check-label"}
                      for={"rememberMe"}
                    />
                  </div>
                </div>

                <Link
                  to="/forgot-password"
                  className="link"
                  onClick={() => {
                    sessionStorage.setItem("resetpwdUserType", "member");
                  }}
                >
                  Forgot Password?
                </Link>
              </div>
              <div className="d-flex">
                {loading ? (
                  <button
                    className="btn-lg-primary-full"
                    type="submit"
                    disabled={true}
                  >
                    <LoaderComponent show={true} />{" "}
                  </button>
                ) : (
                  <button
                    className="btn-lg-primary-full"
                    type="submit"
                    name="submit"
                    value={true}
                  >
                    Sign In
                  </button>
                )}
              </div>
            </form>

            <div className="line-with-text">
              <p>
                <span>or continue with</span>
              </p>
            </div>

            <div className="near-login">
              <a
                // href="#"
                data-toggle="modal"
                // data-target="#nearLoginModal"
                onClick={handleSigninWithNear}
              >
                <img src="/images/near-logo.svg" />
                Log in with NEAR
              </a>
              <a
                style={{ display: "none" }}
                // href="#"
                data-toggle="modal"
                data-target="#memberNearRegModal"
                id="memberNearModalOpen"
              >
                AAA
              </a>
            </div>
            <div className="sign-up-link">
              <p> Don’t have an account? </p> &nbsp;&nbsp;
              <Link to="/member/sign-up#step-1" className="link">
                Sign Up
              </Link>
            </div>
          </div>

          <div
            className="modal fade"
            id="memberNearRegModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="nearLoginModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close p-lg-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="near-reg-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-body">
                  <div className="form-wrapper" style={paddingForm}>
                    <h1 className="section-title pb-5 pl-5 pt-0">
                      Register With {"     "}
                      <img
                        src="/images/near-logo.svg"
                        className="login-image"
                      />
                    </h1>
                    <form className="step-form w-100" onSubmit={handleNearReg}>
                      <div className="col-md-12">
                        <div className="form-row">
                          <div className="col-6">
                            <div className="form-group">
                              <Label mainLabel={"First Name *"} for={"fName"} />
                              <Input
                                type={"text"}
                                name={"fname"}
                                className={"form-control"}
                                id={"fName"}
                                placeholder={"First Name"}
                                value={nearfName}
                                isRequired={true}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-6">
                            <div className="form-group">
                              <Label mainLabel={"Last Name *"} for={"lName"} />
                              <Input
                                type={"text"}
                                name={"lname"}
                                className={"form-control"}
                                id={"lName"}
                                placeholder={"Last Name"}
                                value={nearlName}
                                onChange={handleChange}
                                isRequired={true}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-6">
                            <div className="form-group">
                              <Label mainLabel={"Phone"} for={"mobile"} />
                              <input
                                type="tel"
                                name={"mobile"}
                                className={"form-control"}
                                id={"mobile"}
                                placeholder={"Phone Number"}
                                value={nearphone}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="ref_code">
                                Your Referral Code
                                <ToolTip
                                  toolTipText={
                                    "This is permanent referral code. This is referral code that you will give to other members"
                                  }
                                />
                              </label>

                              <input
                                type="text"
                                name={"ref_code"}
                                className={"form-control"}
                                id={"user_ref_code"}
                                value={nearuserRefCode}
                                onChange={handleChange}
                              />
                              <small className="help-text">This is permanent referral code. This is referral code that you will give to other members</small>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <Label mainLabel={"Email *"} for={"email"} />
                              <Input
                                type={"email"}
                                name={"email"}
                                className={"form-control"}
                                id={"nearemail"}
                                placeholder={"Email"}
                                value={nearemail}
                                onChange={handleChange}
                                isRequired={true}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="ref_code">
                                Verification Code
                                <ToolTip
                                    toolTipText={
                                      "This is the code of another member they need to sign up"
                                    }
                                />
                              </label>
                              <Input
                                type={"text"}
                                name={"reffral_code"}
                                minlength={2}
                                className={"form-control"}
                                id={"referralCode"}
                                placeholder={"Verification Code"}
                                value={nearusedreferralCode}
                                onChange={handleChange}
                              />
                              <small className="help-text">This is the code of another member they need to sign up.</small>
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-6">
                            <div className="form-group">
                              <Label
                                mainLabel={"Category Type"}
                                for={"categoryType"}
                              />
                              <select
                                id="categoryType"
                                name={"category_type"}
                                className="form-control"
                                onChange={handleChange}
                              >
                                <option
                                  defaultValue={"Select"}
                                  readOnly={true}
                                  hidden={true}
                                  value={nearcategoryType}
                                >
                                  Select
                                </option>
                                {cateroyList.map((ctgry) => (
                                  <option key={ctgry.id} value={ctgry.id}>
                                    {" "}
                                    {ctgry.title}{" "}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group">
                              <Label mainLabel={"Category"} for={"category"} />

                              <select
                                id={"category"}
                                className="form-control"
                                onChange={handleChange}
                                name={"skills[]"}
                                multiple
                                value={nearcategory}
                              >
                                <option
                                  defaultValue={"Select"}
                                  readOnly={true}
                                  hidden={true}
                                >
                                  Select
                                </option>
                                {skillsList.map((skill) => (
                                  <option key={skill.id} value={skill.id}>
                                    {skill.title}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="form-row mb-5 display-picture">
                          <div className="col-6">
                            <div className="form-group">
                              <label htmlFor="picture">Display Picture</label>
                              <div className="image-upload text-center">
                                {/* <img src="/images/image-placeholder.svg" alt="" /> */}
                                <p className="mb-5 mt-4">Add Display Photo</p>
                                <p className="mb-5">(up to 12 MB)</p>
                                {/*Upload image input */}

                                <div className="input-group rounded-pill bg-white shadow-sm">
                                  <input
                                    id="upload"
                                    type="file"
                                    name="pic"
                                    onChange={handleChange}
                                    className="form-control mb-0"
                                  />
                                  <label
                                    id="upload-label"
                                    htmlFor="upload"
                                    className="font-weight-light text-muted"
                                  >
                                    Choose file
                                  </label>
                                  <div className="input-group-append">
                                    <label
                                      htmlFor="upload"
                                      className="btn btn-light m-0 rounded-pill px-4"
                                    >
                                      <i className="fas fa-cloud-upload-alt mr-2 text-muted"></i>
                                      <small className="text-uppercase font-weight-bold text-muted">
                                        Choose file
                                      </small>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="uploaded-image">
                              {/*Uploaded image area*/}
                              <p className="font-italic text-center">
                                The image uploaded will be rendered below.
                              </p>
                              <div className="image-area">
                                <img
                                  id="imageResult"
                                  src={nearavatarLocation}
                                  alt=""
                                  className="img-fluid rounded shadow-sm mx-auto d-block"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="row setup-content" id="step-2">
                      </div> */}
                      {/* <div className="row setup-content" id="step-3">
                      </div> */}

                      <div className="col-12 submit-step ">
                        {/* <i className="far fa-check-circle"></i> */}

                        {nearloading ? (
                          <div className="d-flex-fe d-flex-ai w-100 mt-5 mb-5">
                            <a className="link prevBtn" href="#"></a>
                            <button
                              className="btn-success-full mr-0"
                              type="submit"
                              disabled
                            >
                              <LoaderComponent show={true} />{" "}
                            </button>{" "}
                          </div>
                        ) : (
                          <div className="d-flex-fe d-flex-ai w-100 mt-5 mb-5">
                            <button
                              className="btn-success-full mr-0"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="form-info-wrapper">
            <h1 className="section-title">
              {" "}
              Sign Up To Receive Tips Instantly{" "}
            </h1>
            <p> Musicians | Athletes | Live Entertainers | Events </p>
            <ul className="fa-ul list">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Subscriptions
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Tip
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Create PPV Live Streams
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Song Requests
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                In-Person Event Calendar
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Direct Fan Marketing & Email Campaigns
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Crypto-currency Rewards
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Keep your Fans in the Know and able to support you
              </li>
            </ul>

            <div className="d-flex">
              <Link
                to="/member/sign-up"
                className="btn-lg-white-outline sign-up-btn"
              >
                Register Now
              </Link>
            </div>
            {/* <img src="/images/sign_in_image.svg" alt="Sign Up Image" /> */}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default SignIn;
