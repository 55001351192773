import React, { useEffect, useState } from "react";
import Navbar from "../include/Navbar";
import { Link } from "react-router-dom";
import Footer from "../include/Footer";
import Label from "../common/Label";
import Input from "../common/Input";
import { API_BASE_URL, getApi } from "../../near/utils/localUtils";
import ModalComponent from "../common/ModalComponent";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

function Members() {
  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  const [membersList, setmembersArray] = useState([]);
  const [memberCategory, setmemberCategory] = useState([]);
  // used for filter/search
  const [membersAllData, setmembersAllData] = useState([]);

  // near only and all account
  const [enabledTab, setenabledTab] = useState("all");

  // for search
  const [filterDistance, setfilterDistance] = useState("Select");
  const [filterName, setfilterName] = useState("");
  // category is filtered based on the id
  const [filterCategoryId, setfilterCategoryId] = useState("");

  // for maps
  const [currentPosition, setCurrentPosition] = useState({
    lng: 38.9072,
    lat: 77.0369,
  });

  const success = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setCurrentPosition(currentPosition);
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  });

  const [selected, setSelected] = useState({});
  const onSelect = (item) => {
    setSelected(item);
  };

  useEffect(async () => {
    // https://dev.tipjarlove.com/frontapi/musicians_list_view_all
    // const membersData = await getApi("frontapi/musicians_list_view");
    const membersData = await getApi("frontapi/musicians_list_view_all");

    let { result, category } = membersData[0];
    // set category name (cid to category name)
    result = result.map((member) => {
      let memberCategory = category.find((ct) => ct.id == member.cid);
      if (memberCategory) {
        return {
          ...member,
          lat: member.LatitudeLongitude.split(",")[1],
          lng: member.LatitudeLongitude.split(",")[0],
          category: memberCategory.title,
        };
      }
      // temporary bug fixed
      return {
        ...member,
        category: "Independent Creator",
        cid: "61",
        lat: member.LatitudeLongitude.split(",")[1],
        lng: member.LatitudeLongitude.split(",")[0],
      };
    });
    const defaultMembers = result.filter(
      (member) => member.Stripe_account_status == 1
    );

    // displayed
    setmembersArray(defaultMembers);
    setmemberCategory(category);
    // used for filter/search
    setmembersAllData(result);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "name":
        setfilterName(value);
        break;
      case "filterByDistance":
        setfilterDistance(value);
        break;
      case "filterByCategory":
        // settig cid
        setfilterCategoryId(value);
        break;
      default:
        break;
    }
  };

  // filter near-only account
  const handleFilterNearOnlyAccount = (nearOnly = false) => {
    setenabledTab(nearOnly ? "near" : "all");
    let filteredData = membersAllData;
    if (nearOnly) {
      filteredData = filteredData.filter((member) => Boolean(member.near_id));
    } else {
      filteredData = membersAllData.filter(
        (member) => member.Stripe_account_status == 1
      );
    }
    setmembersArray(filteredData);
  };

  // querry/search members
  const handleSearch = (e) => {
    e.preventDefault();
    // filter for distance
    let filteredData = membersAllData;
    if (enabledTab == "near") {
      // show all near members
      filteredData = membersAllData.filter((member) => Boolean(member.near_id));
    } else {
      // show stripe verified members only
      filteredData = filteredData.filter(
        (member) => member.Stripe_account_status == 1
      );
    }

    if (filterName.length) {
      filteredData = filteredData.filter((member) => {
        return member.NameText.toLowerCase().includes(filterName.toLowerCase());
      });
    }

    // category filter
    if (
      filterCategoryId.length &&
      filteredData.length &&
      filterCategoryId != "Select"
    ) {
      filteredData = filteredData.filter((member) => {
        return member.cid == filterCategoryId;
      });
    }

    // distance filter
    if (
      filterDistance.length &&
      filteredData.length &&
      filterDistance != "Select"
    ) {
      filteredData = filteredData.filter((member) => {
        return parseFloat(member.distance) <= parseFloat(filterDistance);
      });
    }
    setmembersArray(filteredData);
  };

  // to clear search
  const handleResetSearch = () => {
    // show all near members
    if (enabledTab == "near") {
      setmembersArray(
        membersAllData.filter((member) => Boolean(member.near_id))
      );
    } else {
      // show stripe verified members only
      setmembersArray(
        membersAllData.filter((member) => member.Stripe_account_status == 1)
      );
    }
    setfilterDistance("Select");
    setfilterName("");
  };

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "danger",
    heading: "Failed",
    body: "Not Found!",
  });

  const handleModalClose = () => {
    setModalInfo(false);
  };

  return (
    <div>
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />

      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="members-wrapper container">
          <div className="section-header">
            <h1 className="section-title">Members</h1>
            <div className="d-flex-jc">
              <ul className="nav nav-tabs custom-nav-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#list"
                    role="tab"
                  >
                    List
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#maps"
                    role="tab"
                  >
                    Map
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex-jc">
            <ul className="nav nav-tabs custom-nav-tabs" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  onClick={() => {
                    // setshowNEARonly(false);
                    handleFilterNearOnlyAccount(false);
                  }}
                >
                  All Accounts
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  onClick={() => {
                    // setshowNEARonly(true);
                    handleFilterNearOnlyAccount(true);
                  }}
                >
                  NEAR Accounts
                </a>
              </li>
            </ul>
          </div>
          <br />

          <div className="main-content">
            <div className="filter-selection">
              <form onSubmit={handleSearch}>
                <div className="form-group filter-item">
                  <Label mainLabel={"Member Name"} for={"name"} />
                  <Input
                    type={"text"}
                    className={"form-control"}
                    id={"name"}
                    placeholder={"Member Name"}
                    onChange={handleChange}
                    value={filterName}
                  />
                </div>

                <div className="form-group filter-item">
                  <Label
                    mainLabel={"Filter (by category)"}
                    for={"filterByCategory"}
                  />
                  <select
                    id="filterByCategory"
                    className="form-control"
                    onChange={handleChange}
                  >
                    <option defaultValue="Select" readOnly={true}>
                      Select
                    </option>

                    {memberCategory.map((category, id) => (
                      <option key={id} value={category.id}>
                        {" "}
                        {category.title}
                      </option>
                    ))}

                    {/* <option> Independent Creator</option>
                    <option> Musician Band</option> */}
                  </select>
                </div>
                <div className="form-group filter-item">
                  <Label
                    mainLabel={"Filter (by distance)"}
                    for={"filterByDistance"}
                  />
                  <select
                    id="filterByDistance"
                    className="form-control"
                    onChange={handleChange}
                    value={filterDistance}
                  >
                    <option defaultValue="Select" readOnly={true}>
                      Select
                    </option>
                    <option>100</option>
                    <option>300</option>
                    <option>500</option>
                    <option>700</option>
                    <option>1000</option>
                    <option>10000</option>
                    <option>20000</option>
                  </select>
                </div>

                <div className="button-wrapper filter-item">
                  <button className="btn-primary-full mr-0" type="submit">
                    Search
                  </button>
                  <div
                    className="icon-wrapper filter-item"
                    onClick={handleResetSearch}
                  >
                    <i className="fa fa-redo"></i>
                  </div>
                </div>
              </form>
            </div>
            <div className="tab-content custom-tab-content">
              <div className="tab-pane active" id="list" role="tabpanel">
                <div className="row">
                  {membersList.map((member, i) => {
                    if (member.slug) {
                      return (
                        <div className="col-md-3" key={member.MarkerId}>
                          <div className="small-card">
                            <Link to={"/members/" + member.slug}>
                              <img
                                className="card-img"
                                src={
                                  member.avatar
                                    ? member.avatar.includes("http")
                                      ? `${member.avatar}`
                                      : `${API_BASE_URL}${member.avatar}`
                                    : ""
                                }
                                alt=""
                              />
                            </Link>
                            <div className="card-content">
                              <div className="card-description">
                                <h1 className="card-title">
                                  {member.NameText}
                                </h1>
                                <p className="card-text">{member.category}</p>
                              </div>

                              <div className="card-action">
                                {/* <a href="#" className="btn-primary-full">
                                  {" "}
                                  Follow{" "}
                                </a> */}
                              </div>
                            </div>

                            {/* <div className="card-action">
                              <a className="btn-secondary-full">
                                {member.fans} fans
                              </a>
                            </div> */}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
              <div className="tab-pane" id="maps" role="tabpanel">
                <LoadScript googleMapsApiKey="AIzaSyA2VohtbyuoHxWCxvU8QAb1RfgEvtY2xL8">
                  <GoogleMap zoom={5} center={currentPosition}>
                    <div style={{ minHeight: "90vh" }}></div>
                    {membersList.map((mmbr) => {
                      return (
                        <Marker
                          key={mmbr.MarkerId}
                          position={{
                            lat: parseFloat(mmbr.lat),
                            lng: parseFloat(mmbr.lng),
                          }}
                          onClick={() => onSelect(mmbr)}
                        />
                      );
                    })}
                    {selected.lat && (
                      <InfoWindow
                        position={{
                          lat: parseFloat(selected.lat),
                          lng: parseFloat(selected.lng),
                        }}
                        clickable={true}
                        onCloseClick={() => setSelected({})}
                      >
                        <p> {selected.NameText}</p>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Members;
