import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { postApi } from "../../../near/utils/localUtils";
import ModalComponent from "../../common/ModalComponent";
import LoaderComponent from "../../common/LoaderComponent";

function PlaylistGroup(props) {
  const navigate = useNavigate();
  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  useEffect(() => {
    if (isMember && isLoggedIn) {
      const userotherinfostorage =
        JSON.parse(localStorage.getItem("otherinfo")) ||
        JSON.parse(sessionStorage.getItem("otherinfo"));
      const userinfoStorage =
        JSON.parse(localStorage.getItem("userinfo")) ||
        JSON.parse(sessionStorage.getItem("userinfo"));

      setuserinfo(userinfoStorage);
      setuserotherinfo(userotherinfostorage);
    } else {
      navigate("/member/sign-in");
    }
  }, []);

  // for ui components
  const [loading, setLoading] = useState(false);

  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const handleModalClose = () => {
    setModalInfo(false);
  };

  // for state
  const [userinfo, setuserinfo] = useState({});
  const [userotherinfo, setuserotherinfo] = useState({});

  // for new group
  const [playlistGroupName, setplaylistGroupName] = useState("");
  const [description, setdescription] = useState("");

  // for edit group
  const [editGroupInfo, seteditGroupInfo] = useState({
    description: "",
    playlist_group_name: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "title":
        setplaylistGroupName(value);
        break;
      case "description":
        setdescription(value);
        break;
      case "edittitle":
        seteditGroupInfo({ ...editGroupInfo, title: value });
        break;
      case "editdescription":
        seteditGroupInfo({ ...editGroupInfo, description: value });
        break;
      default:
        break;
    }
  };

  // activate/deactivate group list
  const handleGroupListActivation = async (e, groupId) => {
    const { checked } = e.target;

    const formData = new FormData();
    formData.append("group_id", groupId);

    // auth
    formData.append("user_id", userinfo.id);
    formData.append("token", userotherinfo.token);
    // formData.append("user_type", "Member");

    const updateLocalData = () => {
      const updatedMusPlyalistGroup = userotherinfo.mus_playlist_group.map(
        (grp) => {
          if (grp.group_id == groupId) {
            return { ...grp, status: checked ? 1 : 0 };
          }
          return grp;
        }
      );
      const updatedOtherInfo = {
        ...userotherinfo,
        mus_playlist_group: updatedMusPlyalistGroup,
      };
      setuserotherinfo(updatedOtherInfo);
      sessionStorage.setItem("otherinfo", JSON.stringify(updatedOtherInfo));
      if (localStorage.getItem("otherinfo")) {
        localStorage.setItem("otherinfo", JSON.stringify(updatedOtherInfo));
      }
    };

    if (checked) {
      handleSubmit(formData, "frontapi/GroupActive", updateLocalData);
    } else {
      handleSubmit(formData, "frontapi/GroupInactive", updateLocalData);
    }
  };

  const handleEditModal = (id) => {
    seteditGroupInfo(
      userotherinfo.mus_playlist_group.find((grp) => grp.group_id == id)
    );
  };

  const handleAddPlaylistGroup = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("playlist_group_name", playlistGroupName);
    formData.append("description", description);
    formData.append("audioFileUpdaet", true);

    // auth
    formData.append("user_id", userinfo.id);
    formData.append("token", userotherinfo.token);
    formData.append("user_type", "Member");

    // update the local/session storage and update ui
    const handlUpdateGroupList = (data) => {
      if (localStorage.getItem("otherinfo")) {
        let otherinfo = JSON.parse(localStorage.getItem("otherinfo"));
        const updatedData = {
          ...otherinfo,
          mus_playlist_group: data[0].mus_playlist_group,
        };
        localStorage.setItem("otherinfo", JSON.stringify(updatedData));
        // update state
        setuserotherinfo(updatedData);
      } else if (sessionStorage.getItem("otherinfo")) {
        let otherinfo = JSON.parse(sessionStorage.getItem("otherinfo"));
        const updatedData = {
          ...otherinfo,
          mus_playlist_group: data[0].mus_playlist_group,
        };
        sessionStorage.setItem("otherinfo", JSON.stringify(updatedData));
        // update state
        setuserotherinfo(updatedData);
      }
    };

    handleSubmit(formData, "frontapi/PlaylistGroupAdd", handlUpdateGroupList);
  };

  const handleSubmit = async (formData, api, callback) => {
    // window.formData = formData;
    setLoading(true);

    postApi(api, formData)
      .then((res) => {
        setLoading(false);
        // document.getElementById("add-song-modal-close").click();
        document.getElementById("add-playlist-modal-close").click();
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (data[0].status == "success" || data[0].status == "succcess") {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "primary",
            heading: data[0].status,
            body: data[0].message,
          });
          if (callback) {
            callback(data);
          }

          // state to be updated
          // API update required
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: data[0].status,
            body: data[0].message,
          });
        }
        setTimeout(() => {
          setModalInfo({
            ...modalInfo,
            show: false,
          });
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setModalInfo({
          ...modalInfo,
          show: true,
          type: "danger",
          heading: "Failed",
          body: "Something Went Wrong!",
        });
        setTimeout(() => {
          setModalInfo({
            ...modalInfo,
            show: false,
          });
        }, 1000);
      });
  };

  return (
    <div>
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />

      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="supporters-dashboard-wrapper container">
          <div className="section-header">
            <h1 className="section-title"> Playlist Group </h1>
            <a
              href="#"
              className="add-btn"
              data-toggle="modal"
              data-target="#add_playlist"
              data-backdrop="static"
            >
              <i className="fa fa-plus"></i> Add New
            </a>
          </div>

          <div className="playlist-group table-responsive">
            <table className="table table-red">
              <thead>
                <tr>
                  <th> Group Name</th>
                  <th> Description</th>
                  <th className="text-right"> Action</th>
                </tr>
              </thead>

              {userotherinfo.mus_playlist_group ? (
                <tbody>
                  {userotherinfo.mus_playlist_group.map((grp) => (
                    <tr key={grp.group_id}>
                      <td> {grp.playlist_group_name}</td>
                      <td>{grp.description}</td>
                      <td className="text-right d-flex-sb d-flex-ai td-action">
                        <a
                          href="#"
                          data-toggle="modal"
                          data-target="#edit_playlist"
                          data-backdrop="static"
                          className="link text-decoration-none m-0 mr-3"
                          onClick={() => {
                            handleEditModal(grp.group_id);
                          }}
                        >
                          {" "}
                          <i className="fa fa-edit mr-2"></i> Edit
                        </a>
                        <a
                          href="#"
                          className="link-error text-decoration-none m-0"
                        >
                          {" "}
                          <i className="fa fa-trash-alt mr-2"></i> Delete{" "}
                        </a>
                        <div className="switch d-flex ml-lg-4">
                          <input
                            className="switch mb-0"
                            id={`switch-${grp.group_id}`}
                            name="switch"
                            type="checkbox"
                            defaultChecked={grp.status == 1}
                            onChange={(e) => {
                              handleGroupListActivation(e, grp.group_id);
                            }}
                          />
                          <label
                            data-off="Inactive"
                            data-on="Active"
                            htmlFor={`switch-${grp.group_id}`}
                          ></label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>
          </div>
        </div>
      </main>

      {/* add new group modal */}
      <div
        className="modal fade"
        id="add_playlist"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <form onSubmit={handleAddPlaylistGroup}>
            <div className="modal-content">
              <button
                type="button"
                className="close p-lg-3 text-right"
                data-dismiss="modal"
                aria-label="Close"
                id={"add-playlist-modal-close"}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-wrapper pt-0">
                <h1 className="mb-lg-5 font-weight-bold"> Add Group </h1>

                <div className="form-group">
                  <Label
                    mainLabel={"Title"}
                    className={"text-black-50"}
                    for={"title"}
                  />
                  <Input
                    type={"text"}
                    name={"title"}
                    className={"form-control"}
                    id={"title"}
                    onChange={handleChange}
                    value={playlistGroupName}
                    placeholder={"Title"}
                  />
                </div>

                <div className="form-group">
                  <Label mainLabel={"Description"} for={"description"} />
                  <textarea
                    className="form-control"
                    id="description"
                    placeholder="Description"
                    col="6"
                    rows="6"
                    onChange={handleChange}
                    value={description}
                  ></textarea>
                </div>

                <div className="mt-lg-5 d-flex-fe">
                  {loading ? (
                    <button className="btn-lg-primary-full mr-0" disabled>
                      <LoaderComponent show={true} />{" "}
                    </button>
                  ) : (
                    <button className="btn-lg-primary-full mr-0" type="submit">
                      {" "}
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Edit group modal */}
      <div
        className="modal fade"
        id="edit_playlist"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <form
          //  onSubmit={handleAddPlaylistGroup}
          >
            <div className="modal-content">
              <button
                type="button"
                className="close p-lg-3 text-right"
                data-dismiss="modal"
                aria-label="Close"
                id={"add-playlist-modal-close"}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-wrapper pt-0">
                <h1 className="mb-lg-5 font-weight-bold"> Edit Group </h1>

                <div className="form-group">
                  <Label
                    mainLabel={"Title"}
                    className={"text-black-50"}
                    for={"title"}
                  />
                  <Input
                    type={"text"}
                    name={"title"}
                    className={"form-control"}
                    id={"edittitle"}
                    onChange={handleChange}
                    value={editGroupInfo.playlist_group_name}
                    placeholder={"Title"}
                  />
                </div>

                <div className="form-group">
                  <Label mainLabel={"Description"} for={"description"} />
                  <textarea
                    className="form-control"
                    id="editdescription"
                    placeholder="Description"
                    col="6"
                    rows="6"
                    onChange={handleChange}
                    value={editGroupInfo.description}
                  ></textarea>
                </div>

                <div className="mt-lg-5 d-flex-fe">
                  {loading ? (
                    <button className="btn-lg-primary-full mr-0" disabled>
                      <LoaderComponent show={true} />{" "}
                    </button>
                  ) : (
                    <button className="btn-lg-primary-full mr-0" type="submit">
                      {" "}
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PlaylistGroup;
