import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import SwiperCore, { Navigation } from "swiper";
import { API_BASE_URL } from "../../../near/utils/localUtils";

function Events({ eventsInfo, isLoggedIn, isMember, isFan }) {
  SwiperCore.use([Navigation]);
  return (
    <section className="events">
      <h1 className="section-title"> Events </h1>

      {eventsInfo.length ? (
        <h3 className="section-subtitle">
          {" "}
          In-Person and Live Streamed Events{" "}
        </h3>
      ) : (
        <h3 className="section-subtitle"> No Events To Show! </h3>
      )}
      <div className="event-swiper-container">
        <div className="swiper-wrapper">
          <Swiper
            watchSlidesProgress={true}
            slidesPerView={3}
            spaceBetween={100}
            navigation={true}
            loop={true}
            breakpoints={{
              // when window width is >= 640px
              320: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 3,
              },
            }}
          >
            {eventsInfo.map((event) => (
              <SwiperSlide id={event.id} key={event.id}>
                {event.featured_img ? (
                  <img
                    src={
                      event.featured_img.includes("html")
                        ? event.featured_img
                        : `${API_BASE_URL}/${event.featured_img}`
                    }
                    alt=""
                  />
                ) : (
                  <img src={"/images/events/2.png"} alt="" />
                )}

                <div className="event-content">
                  <h2 className="event-title"> {event.title} </h2>
                  <p className="event-description">{event.content}</p>
                  <p className="date">
                    <i className="fa fa-calendar-alt"></i>
                    {event.datetime.split(" ")[0]}
                    <br />
                    {event.time}
                  </p>
                  <p className="location">
                    <i className="fa fa-map-marker-alt"></i> {event.venue}
                  </p>

                  <Link
                    to={`/event/${event.id}`}
                    state={{
                      isLoggedIn: isLoggedIn,
                      isFan: isFan,
                      isMember: isMember,
                    }}
                    className="arrow-btn"
                  >
                    VIEW MORE
                    <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Events;
