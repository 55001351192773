import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

export default function LoaderComponent({ show }) {
  return (
    <>
      {/* <Modal show={show} centered={true}> */}
      <Spinner animation="border" variant="success" />
      {/* </Modal> */}
    </>
  );
}

// render(<Example />);
