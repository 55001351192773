import { logoutNearAccount } from "../../near/utils/browserUtils";
import { postApi } from "../../near/utils/localUtils";

export const handleLogout = (e) => {
  const formData = new FormData();

  const isFan =
    JSON.parse(localStorage.getItem("isFan")) ||
    JSON.parse(sessionStorage.getItem("isFan")) ||
    {};
  const isMember =
    JSON.parse(localStorage.getItem("isMember")) ||
    JSON.parse(sessionStorage.getItem("isMember")) ||
    {};
  const isLoggedin =
    JSON.parse(localStorage.getItem("isLoggedin")) ||
    JSON.parse(sessionStorage.getItem("isLoggedin")) ||
    {};
  const userotherinfo =
    JSON.parse(localStorage.getItem("otherinfo")) ||
    JSON.parse(sessionStorage.getItem("otherinfo")) ||
    {};
  const userinfo =
    JSON.parse(localStorage.getItem("userinfo")) ||
    JSON.parse(sessionStorage.getItem("userinfo")) ||
    {};

  // auth
  formData.append("user_id", userinfo.id);
  formData.append("token", userotherinfo.token);

  if (isMember) {
    postApi("frontapi/logout", formData)
      .then((res) => res.json())
      .then((data) => {
        logoutLocal();
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  } else if (isFan) {
    postApi("frontapi/logout2", formData)
      .then((res) => res.json())
      .then((data) => {
        logoutLocal();
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  }

  function logoutLocal() {
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("isMember");
    sessionStorage.removeItem("isFan");
    sessionStorage.removeItem("userinfo");
    sessionStorage.removeItem("otherinfo");

    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("isMember");
    localStorage.removeItem("isFan");
    localStorage.removeItem("userinfo");
    localStorage.removeItem("otherinfo");
    logoutNearAccount();
  }
};
