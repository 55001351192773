import React, { Component, useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import DashboardContent from "./DashboardContent";
import EditProfile from "./EditProfile";
import Navbar from "./Navbar";
import { Helmet } from "react-helmet";
import CreateEvent from "./CreateEvent";
import TransferCrpto from "./TransferCrypto";
import { getNearAccountInfo } from "../../../../near/utils/browserUtils";
import {
  fetchMoonPay,
  jargonDecimals,
} from "../../../../near/utils/exchangRates";
import { getApi, postApi } from "../../../../near/utils/localUtils";
import AddPlaylist from "./AddPlaylist";
import VerfiyStripe from "../VerfiyStripe";
import ModalComponent from "../../../common/ModalComponent";

// import "./member_dashboard.css";
// import "./member_main.css";

function Dashboard() {
  const navigate = useNavigate();
  // trigger when local storage is updated
  const [storageUpdateFlag, setstorageUpdateFlag] = useState(false);

  const [userinfo, setuserinfo] = useState(
    JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"))
  );
  const [userotherinfo, setuserotherinfo] = useState(
    JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"))
  );
  const [balanceInfo, setbalanceInfo] = useState({});

  useEffect(async () => {
    const loggedIn =
      JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn"));
    const isMember =
      JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember"));

    if (!isMember || !loggedIn) {
      navigate("/member/sign-in");
    }

    // personal info
    if (userinfo) {
      setuserinfo(userinfo);
    }
    // followers, etc data
    setuserotherinfo(userotherinfo);

    const balanceData = await getNearAccountInfo(userinfo.near_id);
    const exchangeRate = await fetchMoonPay();
    setbalanceInfo({
      ...balanceData,
      exchangeRate,
      usdBalance: exchangeRate * balanceData.nearBalance,
    });
  }, [storageUpdateFlag]);

  useEffect(() => {
    if (window.localStorage) {
      if (!localStorage.getItem("reload")) {
        localStorage["reload"] = true;
        window.location.reload();
      } else {
        localStorage.removeItem("reload");
        // verify stripe notification
        console.log(userinfo);
        if (userinfo.Stripe_account_status == "0") {
          setModalInfo({
            show: true,
            type: "warning",
            heading: "Warning!",
            body: "Your stripe account is not verified! Please verify it now and setup your USD payments.",
            // "Welcome Body"
          });
          // alert(
          //   "Your stripe account is not verified! Please verify it now and setup your USD payments."
          // );
        }
      }
    }
  }, []);

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  // const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalInfo(false);
  };

  const handleVerifyStripe = () => {
    const formData = new FormData();
    formData.append("user_id", userinfo.id || userinfo.user_id);
    formData.append("token", userotherinfo.token);

    postApi("frontapi/stripe_verification", formData)
      .then((res) => res.json())
      .then((data) => {
        if (data[0].status == "success") {
          window.location.href = data[0].url;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
      <ModalComponent
        modalInfo={modalInfo}
        handleClose={handleModalClose}
        oktext="Verify Now"
        handleOk={handleVerifyStripe}
      />
      <Helmet>{<script src="/js/member_dashboard.js" />}</Helmet>
      <Navbar userinfo={userinfo} />
      <div className="app-main">
        <Sidebar userinfo={userinfo} />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div id="tabsContent" className="tab-content">
              <div id="dashboard" className="tab-pane active">
                <DashboardContent
                  userinfo={userinfo}
                  balanceInfo={balanceInfo}
                  userotherinfo={userotherinfo}
                />
              </div>

              <div id="edit-profile" className="tab-pane">
                <EditProfile
                  userinfo={userinfo}
                  setstorageUpdateFlag={setstorageUpdateFlag}
                  storageUpdateFlag={storageUpdateFlag}
                />
              </div>
              <div id="create-event" className="tab-pane">
                <CreateEvent
                  userinfo={userinfo}
                  setstorageUpdateFlag={setstorageUpdateFlag}
                  storageUpdateFlag={storageUpdateFlag}
                />
              </div>
              <div id="transfer-crypto" className="tab-pane">
                <TransferCrpto
                  userinfo={userinfo}
                  setstorageUpdateFlag={setstorageUpdateFlag}
                  storageUpdateFlag={storageUpdateFlag}
                />
              </div>
              <div id="verify-stripe" className="tab-pane">
                <VerfiyStripe
                // userinfo={userinfo}
                // setstorageUpdateFlag={setstorageUpdateFlag}
                // storageUpdateFlag={storageUpdateFlag}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
