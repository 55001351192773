import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { post, postApi } from "../../../near/utils/localUtils";
import ModalComponent from "../../common/ModalComponent";
import LoaderComponent from "../../common/LoaderComponent";

function FollowerCampaign() {
  const navigate = useNavigate();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  const [userinfo, setuserinfo] = useState(
    JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"))
  );

  const [userotherinfo, setuserotherinfo] = useState(
    JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"))
  );

  // form fields
  const [category, setcategory] = useState("");
  const [supporter, setsupporter] = useState("");
  const [chooseEvent, setchooseEvent] = useState("");
  const [template, settemplate] = useState("");
  const [subject, setsubject] = useState("");
  const [description, setdescription] = useState("");

  // for auth
  const [authtoken, setAuthToken] = useState("");
  const [userid, setUserid] = useState("");

  useEffect(() => {
    if (!isMember || !isLoggedIn) {
      navigate("/member/sign-in");
      return;
    }

    setUserid(userinfo.id);
    setAuthToken(userotherinfo.token);

    const formData = new FormData();
    formData.append("user_id", userinfo.id);
    formData.append("token", userotherinfo.token);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "category":
        setcategory(value);
        break;
      case "supporter":
        setsupporter(value);
        break;
      case "chooseEvent":
        setchooseEvent(value);
        break;
      case "template":
        settemplate(value);
        break;
      case "subject":
        setsubject(value);
        break;
      case "description":
        setdescription(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("category", category);
    formData.append("subject", subject);
    formData.append("campaign", description);
    formData.append("send", "submit");

    // auth
    formData.append("token", authtoken);
    formData.append("user_id", userid);

    setLoading(true);
    // https://dev.tipjarlove.com/frontapi/follower_campaign
    // postApi("frontapi/campaign_template", formData)
    postApi("frontapi/follower_campaign", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (data[0].status == "success") {
          setModalInfo({
            show: true,
            type: "primary",
            heading: "Successful",
            body: "Template added!",
          });
          setTimeout(() => {
            setModalInfo({
              show: false,
            });
          }, 1000);

          // clear inputs
          setcategory("");
          setsupporter("");
          setchooseEvent("");
          settemplate("");
          setsubject("");
          setdescription("");
          return;
        } else {
          setModalInfo({
            show: true,
            type: "danger",
            heading: "Failed",
            body: "Something went wrong!",
          });
          setTimeout(() => {
            setModalInfo({
              show: false,
            });
          }, 1000);
        }
        // failure notification here
      })
      .catch((err) => {
        console.log("Err: ", err);
        setModalInfo({
          show: true,
          type: "danger",
          heading: "Error",
          body: "Please check console for error report.",
        });
        setTimeout(() => {
          setModalInfo({
            show: false,
          });
        }, 1000);
      });
  };

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalInfo(false);
  };

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />
      <main className="main-container">
        <div className="supporters-dashboard-wrapper container">
          <div className="section-header">
            <h1 className="section-title"> Campaign </h1>
          </div>
          <div className="notes-wrapper">
            <p className="font-italic">
              Note: Use{" "}
              <span dangerouslySetInnerHTML={{ __html: "{fname} " }} />
              for first name of fan,
              <span dangerouslySetInnerHTML={{ __html: " {lname} " }} /> for
              last name,
              <span dangerouslySetInnerHTML={{ __html: " {email} " }} /> for
              email in text
            </p>
            <Link
              to="/member/follower-campaign-history"
              state={{
                isLoggedIn: isLoggedIn,
                isFan: isFan,
                isMember: isMember,
              }}
              className="link-primary ml-3 mr-3"
            >
              View Previous Campaigns
            </Link>
          </div>

          <div className="dialog-box campaign-template">
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <Label mainLabel={"Send Emails to"} for={"sendEmails"} />
                    <select
                      id="category"
                      className="form-control"
                      onChange={handleChange}
                      required
                    >
                      <option
                        defaultValue={"Select"}
                        readOnly={true}
                        hidden={true}
                      >
                        Select
                      </option>
                      <option> All Supporter</option>
                      <option> All Follower</option>
                      <option> Friend</option>
                      <option> Supporter</option>
                    </select>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <Label
                      mainLabel={"Select Fan (Only Supporter)"}
                      for={"supporter"}
                    />
                    <select
                      id="supporter"
                      className="form-control"
                      onChange={handleChange}
                      required
                    >
                      <option
                        defaultValue={"Select"}
                        readOnly={true}
                        hidden={true}
                      >
                        Select
                      </option>
                      <option value="0"> Who Tipped</option>
                      <option value="1"> Who Requested Song</option>
                      <option value="3"> Who Interacted Events</option>
                      <option value="4"> ZIP code Based</option>
                    </select>
                  </div>
                </div>

                <div className="col-12 col-md-4 choose-event">
                  <div className="form-group">
                    <Label mainLabel={"Choose Event"} for={"chooseEvent"} />
                    <select
                      id="chooseEvent"
                      className="form-control"
                      onChange={handleChange}
                      required
                    >
                      <option
                        defaultValue={"Select"}
                        readOnly={true}
                        hidden={true}
                      >
                        Select
                      </option>
                      <option> All Supporter</option>
                      <option> All Follower</option>
                      <option> Friend</option>
                      <option> Supporter</option>
                    </select>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <Label mainLabel={"Load Template"} for={"loadTemplate"} />
                    <select
                      id="template"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option
                        defaultValue={"Select"}
                        readOnly={true}
                        hidden={true}
                      >
                        Select
                      </option>
                      <option> Template 1</option>
                      <option> Template 2</option>
                      <option> Template 3</option>
                      <option> Template 4</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <Label mainLabel={"Subject"} for={"subject"} />
                <Input
                  type={"text"}
                  name={"subject"}
                  className={"form-control"}
                  id={"subject"}
                  placeholder={"Subject"}
                  value={subject}
                  isRequired={true}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <Label mainLabel={"Description"} for={"description"} />
                <textarea
                  className="form-control"
                  id="description"
                  placeholder="Description"
                  col="6"
                  rows="6"
                  isRequired={true}
                  value={description}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="d-flex-fe">
                {loading ? (
                  <button className="btn-primary-full mr-0" disabled>
                    <LoaderComponent show={true} />
                  </button>
                ) : (
                  <button className="btn-primary-full mr-0" type="submit">
                    Send
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default FollowerCampaign;
