import React, { useEffect, useState } from "react";
import Label from "../../../common/Label";
import Input from "../../../common/Input";
import { postApi } from "../../../../near/utils/localUtils";
import ModalComponent from "../../../common/ModalComponent";
import LoaderComponent from "../../../common/LoaderComponent";
import {
  getNearAccountInfo,
  loginNearAccount,
  sendJargon,
  sendNear,
} from "../../../../near/utils/browserUtils";
import { jargonDecimals } from "../../../../near/utils/exchangRates";

function TransferCrpto({ setstorageUpdateFlag, storageUpdateFlag, userinfo }) {
  const [receiver, setreceiver] = useState("");
  const [currency, setcurrency] = useState("near");
  const [amount, setamount] = useState("5");

  // for auth
  const [memberId, setmemberId] = useState("");
  const [authToken, setauthToken] = useState("");

  useEffect(async function () {
    const userinfolocal =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));
    const userotherinfo =
      JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"));
    setauthToken(userotherinfo.token);
    setmemberId(userinfolocal.id);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "accountid":
        setreceiver(value);
        break;
      case "currency":
        setcurrency(value);
        break;
      case "amount":
        setamount(value);
        break;
      default:
        console.log("NA");
    }
  };

  const updateNEARBalanceInfo = async () => {
    setreceiver("");
    setamount("5");

    setstorageUpdateFlag(!storageUpdateFlag);
  };

  const handleSendFromBrowser = async (e) => {
    e.preventDefault();
    switch (currency) {
      case "jargon":
        // await sendJargonTip(tipAmount);
        setLoading(true);
        const jargonTransaction = await sendJargon(
          receiver,
          amount * jargonDecimals
        );
        // console.log(jargonTransaction);
        if (!jargonTransaction.err) {
          setLoading(false);
          showNotification("primary", "Transferred", "Trasaction Successfull!");
          // fetch new balance (after transaction)
          await updateNEARBalanceInfo();
        } else {
          setLoading(false);
          // console.log(jargonTransaction);
          showNotification(
            "danger",
            "Failed",
            `${jargonTransaction.details.kind.ExecutionError}`
          );
        }
        break;
      case "near":
        setLoading(true);
        try {
          const nearTransaction = await sendNear(receiver, amount);
          if (!nearTransaction.err) {
            setLoading(false);
            showNotification(
              "primary",
              "Transferred",
              "Trasaction Successfull!"
            );
            // fetch new balance (after transaction)
            await updateNEARBalanceInfo();
          } else {
            setLoading(false);
            showNotification("danger", "Failed", `${nearTransaction.err}`);
          }
        } catch (err) {
          setLoading(false);
          showNotification("danger", "Failed", `${err}`);
        }
        break;
      default:
        return;
    }
  };

  const handleSendFromSubaccount = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("crypto", currency);
    if (currency == "jargon") {
      formData.append("amount", amount * jargonDecimals);
    } else {
      formData.append("amount", amount);
    }
    formData.append("sender", userinfo.near_id);
    formData.append("receiver", receiver);

    // auth
    formData.append("user_id", memberId);
    formData.append("token", authToken);
    formData.append("user_type", "Member");

    setLoading(true);
    postApi("frontapi/send_near", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (!data[0].err) {
          showNotification("primary", "Transferred", "Trasaction Successfull!");
        } else {
          showNotification(
            "danger",
            "Failed",
            `Transaction failed! ${data[0].err}`
          );
        }
      })
      .catch((err) => {
        console.log("Err: ", err);
        showNotification("danger", "Failed", err);
      });

    // let formData = {
    //   receiver,
    //   amount,
    //   account: JSON.parse(sessionStorage.getItem("userinfo")).nearAccountId,
    // };
    // setLoading(true);

    // // for subaccount usage only
    // let endPoint = "transfer-near";
    // if (currency == "jargon") {
    //   endPoint = "transfer-jargon";
    //   formData = {
    //     ...formData,
    //     amount: (parseFloat(formData.amount) * jargonDecimals).toString(),
    //   };
    // }
    // post(formData, endPoint, "JSON")
    //   .then((res) => {
    //     setLoading(false);
    //     return res.json();
    //   })
    //   .then((data) => {
    //     console.log(data);
    //     if (!data.err) {
    //       //   store new account balance
    //       console.log(data);
    //       const {
    //         usdBalance,
    //         lockedBalance,
    //         unlockedBalance,
    //         nearBalance,
    //         jargonBalance,
    //         exchangeRate,
    //       } = data.newdAccountBalance;

    //       const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    //       sessionStorage.setItem(
    //         "userinfo",
    //         JSON.stringify({
    //           ...userinfo,
    //           accountInfo: {
    //             ...userinfo.accountInfo,
    //             usdBalance,
    //             lockedBalance,
    //             unlockedBalance,
    //             nearBalance,
    //             jargonBalance,
    //             exchangeRate,
    //           },
    //         })
    //       );

    //       showNotification("primary", "Transferred", "Trasaction Successfull!");

    //       // update states of other components
    //       setstorageUpdateFlag(!storageUpdateFlag);

    //       // reset inputs
    //       setamount("5");
    //       setreceiver("");
    //       return;
    //     }
    //     showNotification(
    //       "danger",
    //       "Failed",
    //       `Transaction failed! ${data.details ? data.details.type : data.err}`
    //     );
    //   })
    //   .catch((err) => {
    //     showNotification("danger", "Failed", err);
    //     console.log(err);
    //   });
  };

  const handleNearSigneIn = async () => {
    await loginNearAccount(userinfo.near_id);
  };

  // ================ for ui components ================ //
  const showNotification = (type, heading, body, time = 1500) => {
    setModalInfo({
      show: true,
      type,
      heading,
      body,
    });
    setTimeout(() => {
      handleModalClose();
    }, time);
  };

  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });
  const [loading, setLoading] = useState(false);
  const handleModalClose = () => {
    setModalInfo(false);
  };

  // to make form reusable
  const showTrasactionForm = (submitHanlder) => {
    return (
      <div className="edit-profile-wrapper">
        <h1 className="mb-20"> Transfer NEAR/Jargon to Any Account </h1>
        <h2> NEAR Account: {userinfo.near_id} </h2>

        <div className="content-wrapper">
          <form onSubmit={submitHanlder}>
            <div className="form-row mt-lg-5">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <Label mainLabel={"Transfer To"} for={"accountid"} />
                  <Input
                    type={"text"}
                    className={"form-control"}
                    name={"accountid"}
                    id={"accountid"}
                    placeholder={"reciever.near"}
                    value={receiver}
                    onChange={handleChange}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <Label mainLabel={"Currency"} for={"currency"} />
                  <select
                    id="currency"
                    className="form-control"
                    onChange={handleChange}
                    value={currency}
                    required
                  >
                    <option value="near">NEAR</option>
                    <option value="jargon">Jargon</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <Label mainLabel={"Amount"} for={"amount"} />
                  <Input
                    type={"number"}
                    className={"form-control"}
                    name={"amount"}
                    id={"amount"}
                    value={amount}
                    required
                    step={"any"}
                    min={0}
                    onChange={handleChange}
                    // defaultValue={"4562 1122 4594 7852"}
                  />
                </div>
              </div>
            </div>

            {loading ? (
              <div className="d-flex-fe">
                <button className="btn-primary-full updateBtn mr-0">
                  <LoaderComponent show={true} />{" "}
                </button>
              </div>
            ) : (
              <div className="d-flex-fe">
                <button
                  className="btn-primary-full updateBtn mr-0"
                  type="submit"
                >
                  Transfer
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />

      {userinfo.near_id ? (
        <div>
          {userinfo.near_id.includes(window.near.config.masterAccount) ? (
            <div className="container">
              <h2>
                You have a NEAR subaccount with us!: {"         "}
                {userinfo.near_id}
              </h2>
              {/* <h3>Developing</h3> */}
              {showTrasactionForm(handleSendFromSubaccount)}
            </div>
          ) : (
            <div className="container">
              {window.walletConnection.isSignedIn() ? (
                <div className="container">
                  <h2>
                    Your NEAR account is connected!: {"         "}
                    {userinfo.near_id}
                  </h2>
                  {showTrasactionForm(handleSendFromBrowser)}
                </div>
              ) : (
                <div className="container">
                  <h2>
                    You do not have a subaccount with us. Please Sign In To You
                    NEAR Account!
                    <br />
                    {userinfo.near_id}
                  </h2>
                  <button onClick={handleNearSigneIn}>Sign In</button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="container">
          {" "}
          <h1>
            You Do Not Have Any NEAR Account Connected With Your Account! <br />
            <br /> Please Go to Edit Profile Menu to Connect Your NEAR Account!
          </h1>
        </div>
      )}
    </div>
  );
}

export default TransferCrpto;
