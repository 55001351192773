import React, { Component } from "react";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { Link, Navigate } from "react-router-dom";
import { API_BASE_URL, post, postApi } from "../../../near/utils/localUtils";
import LoadingScreenComponent from "../../common/LoadingScreen";

class HistoryManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn:
        JSON.parse(localStorage.getItem("isLoggedIn")) ||
        JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
        false,
      isMember:
        JSON.parse(localStorage.getItem("isMember")) ||
        JSON.parse(sessionStorage.getItem("isMember")) ||
        false,
      isFan:
        JSON.parse(localStorage.getItem("isFan")) ||
        JSON.parse(sessionStorage.getItem("isFan")) ||
        false,
      userInfo:
        JSON.parse(localStorage.getItem("userinfo")) ||
        JSON.parse(sessionStorage.getItem("userinfo")),
      userotherinfo:
        JSON.parse(localStorage.getItem("otherinfo")) ||
        JSON.parse(sessionStorage.getItem("otherinfo")),
      usdTrxList: [],
      usdTrxListAll: [],
      nearTrxList: [],

      // for search bar
      trxType: "",
      trxTo: "",
      displayTrxType: "usd",
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleResetSearch = this.handleResetSearch.bind(this);
    this.handleTrxCurrency = this.handleTrxCurrency.bind(this);
  }

  componentDidMount() {
    if (this.state.isFan && this.state.isLoggedIn) {
      const formData = new FormData();
      formData.append(
        "user_id",
        this.state.userInfo.id || this.state.userInfo.user_id
      );
      formData.append("token", this.state.userotherinfo.token);

      // fetch usd payments history
      postApi("frontapi/payment_history", formData)
        .then((res) => res.json())
        .then((data) => {
          if (data[0].status == "success") {
            this.setState({
              ...this.state,
              usdTrxList: data[0].records_details,
              usdTrxListAll: data[0].records_details,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // fetch near payments history
      post(
        {
          fid: this.state.userInfo.id || this.state.userInfo.user_id,
          userType: "fan",
          token: this.state.userotherinfo.token,
          user_id: this.state.userInfo.id || this.state.userInfo.user_id,
        },
        "trx-hitory",
        "json"
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          this.setState({ ...this.state, nearTrxList: data });
        });
    }
  }

  // search
  handleSearch(e) {
    e.preventDefault();
    let filteredUsdPayments = this.state.usdTrxListAll;
    // payment type filter
    if (this.state.trxType.length) {
      filteredUsdPayments = filteredUsdPayments.filter(
        (trx) => trx.payment_type == this.state.trxType
      );
    }
    if (this.state.trxTo.length) {
      filteredUsdPayments = filteredUsdPayments.filter((trx) =>
        trx.musician.toLowerCase().includes(this.state.trxTo)
      );
    }
    this.setState({ ...this.state, usdTrxList: filteredUsdPayments });
  }

  handleResetSearch() {
    this.setState({
      ...this.state,
      usdTrxList: this.state.usdTrxListAll,
      trxType: "",
      trxTo: "",
    });
  }

  handleTrxCurrency(currency) {
    this.setState({ ...this.state, displayTrxType: currency });
  }

  handleChange(e) {
    const { id, value } = e.target;

    switch (id) {
      case "paymentType":
        this.setState({ ...this.state, trxType: value });
        break;
      case "paymentTo":
        this.setState({ ...this.state, trxTo: value });
        break;
      default:
        break;
    }
  }

  render() {
    if (!this.state.isLoggedIn || !this.state.isFan) {
      return <Navigate to="/fan/sign-in" />;
    }

    return (
      // <LoadingScreenComponent />
      <div>
        <Navbar
          isLoggedIn={this.state.isLoggedIn}
          isMember={this.state.isMember}
          isFan={this.state.isFan}
        />

        <main className="main-container">
          <div className="payment-history-wrapper container">
            <div className="section-header">
              <h1 className="section-title">Payment History</h1>
              <div className="d-flex-jc">
                <ul className="nav nav-tabs custom-nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      // href="#usdpayments"
                      // role="tab"
                      // className="nav-link active"
                      // data-toggle="tab"
                      onClick={() => {
                        this.handleTrxCurrency("usd");
                      }}
                    >
                      USD Payments
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      onClick={() => {
                        this.handleTrxCurrency("near");
                      }}
                    >
                      NEAR Payments
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="main-content">
              {this.state.displayTrxType == "usd" ? (
                <div className="filter-selection">
                  <form onSubmit={this.handleSearch}>
                    <div className="form-group filter-item">
                      <Label mainLabel={"Payment Type"} for={"paymentType"} />
                      <select
                        id="paymentType"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.trxType}
                      >
                        <option defaultValue readOnly={true} hidden={true}>
                          Select
                        </option>
                        <option value={"subscribe"}>Subscribe</option>
                        <option value={"tip"}>Tip</option>
                      </select>
                    </div>

                    <div className="form-group filter-item">
                      <Label mainLabel={"To"} for={"eventTo"} />
                      <Input
                        type={"text"}
                        className={"form-control"}
                        id={"paymentTo"}
                        placeholder={"To"}
                        value={this.state.trxTo}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="button-wrapper filter-item">
                      <button className="btn-primary-full mr-0" type="submit">
                        Search
                      </button>
                      <div
                        className="icon-wrapper filter-item"
                        onClick={this.handleResetSearch}
                      >
                        <i className="fa fa-redo"></i>
                      </div>
                    </div>
                  </form>
                </div>
              ) : null}

              {this.state.displayTrxType == "usd" ? (
                <div className="tab-pane" id="nearpayments" role="tabpanel">
                  <div className="row">
                    {this.state.usdTrxList.map((trx, i) => (
                      <div className="col-md-3" key={trx.id}>
                        <div className="small-card">
                          <img
                            className="card-img"
                            src={
                              trx.avatar.includes("http")
                                ? trx.avatar
                                : `${API_BASE_URL}/${trx.avatar}`
                            }
                            alt=""
                          />
                          <div className="card-content">
                            <Link
                              to={`/members/${trx.slug}`}
                              state={{
                                isLoggedIn: true,
                                isFan: true,
                                isMember: false,
                              }}
                            >
                              <div className="card-info">
                                <div className="payment-detail">
                                  <p className="title">Name:</p>
                                  <p className="value">{trx.musician}</p>
                                </div>
                                <div className="payment-detail">
                                  <p className="title">Price:</p>
                                  <p className="value">${trx.paid_amount}</p>
                                </div>
                                <div className="payment-detail">
                                  <p className="title">Order ID:</p>
                                  <p className="value">{trx.order_id}</p>
                                </div>
                                <div className="payment-detail">
                                  <p className="title">Date:</p>
                                  <p className="value">
                                    {trx.created.split(" ")[0]}
                                  </p>
                                </div>
                                <div className="payment-detail">
                                  <p className="title">Status:</p>
                                  <p className="value">
                                    {trx.payment_status == "succeeded"
                                      ? "Success"
                                      : "Error"}
                                  </p>
                                </div>
                                <p className="btn-primary-full"> Subscribed </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="tab-pane" id="nearpayments" role="tabpanel">
                  <div className="row">
                    {this.state.nearTrxList.map((trx, i) => (
                      <div className="col-md-3" key={trx.trxid}>
                        <div
                          className="small-card"
                          style={{ minWidth: "200px" }}
                        >
                          <Link
                            to={
                              trx.receiverSlug.includes("event/")
                                ? `/event/${
                                    trx.receiverSlug.split("event/")[1]
                                  }`
                                : `/members/${trx.receiverSlug}`
                            }
                            state={{
                              isLoggedIn: true,
                              isFan: true,
                              isMember: false,
                            }}
                          >
                            {" "}
                            <img
                              className="card-img"
                              src={
                                trx.description.includes("near")
                                  ? "/images/near-logo.png"
                                  : "/images/JargonTOKEN.svg"
                              }
                              alt=""
                            />
                          </Link>
                          <div
                            className="card-content"
                            // style={{ width: "100%" }}
                          >
                            <div className="card-info">
                              <Link
                                to={
                                  trx.receiverSlug.includes("event/")
                                    ? `/event/${
                                        trx.receiverSlug.split("event/")[1]
                                      }`
                                    : `/members/${trx.receiverSlug}`
                                }
                                state={{
                                  isLoggedIn: true,
                                  isFan: true,
                                  isMember: false,
                                }}
                              >
                                <div className="payment-detail">
                                  <p
                                    className="title"
                                    style={{ minWidth: "104px", width: "50%" }}
                                  >
                                    Description:
                                  </p>
                                  <p className="value">{trx.description}</p>
                                </div>
                                <div className="payment-detail">
                                  <p
                                    className="title"
                                    style={{ minWidth: "104px", width: "50%" }}
                                  >
                                    Amount:
                                  </p>
                                  <p className="value">
                                    {Math.round(trx.amount * 100) / 100}{" "}
                                  </p>
                                </div>
                                <div
                                  className="payment-detail"
                                  style={{ minWidth: "104px", width: "50%" }}
                                >
                                  <p
                                    className="title"
                                    style={{ minWidth: "104px", width: "50%" }}
                                  >
                                    Order ID:
                                  </p>
                                  <p className="value">
                                    {trx.trxid.substring(1, 5)}...
                                  </p>
                                </div>

                                <div className="payment-detail">
                                  <p
                                    className="title"
                                    style={{ minWidth: "104px", width: "50%" }}
                                  >
                                    Status:
                                  </p>
                                  <p className="value">Success</p>
                                </div>
                              </Link>
                              <a
                                href={`https://explorer.${window.nearConfig.networkId}.near.org/transactions/${trx.trxid}`}
                              >
                                <p className="btn-primary-full">
                                  {" "}
                                  View on Explorer{" "}
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
        <br />
        <br />
        <br />

        <Footer />
      </div>
    );
  }
}

export default HistoryManagement;
