import React from "react";

function Input(props) {
  return (
    <input
      type={props.type}
      name={props.name}
      className={props.className}
      id={props.id}
      placeholder={props.placeholder}
      autoComplete={props.autoComplete}
      defaultValue={props.defaultValue}
      disabled={props.isDisabled}
      value={props.value}
      onChange={props.onChange}
      required={props.isRequired}
      // required={props.value.toString()}
      // defaultChecked={props.isChecked}
      checked={props.isChecked}
      format={props.format}
      max={props.max}
      min={props.min}
      step={props.step}
      maxLength={props.maxLength}
      minLength={props.minlength}
    />
  );
}

export default Input;
