import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../include/Navbar";
import Footer from "../include/Footer";
import Label from "./Label";
import Input from "./Input";
import { postApi } from "../../near/utils/localUtils";
import LoaderComponent from "./LoaderComponent";

function ForgotPassword(props) {
  const navigate = useNavigate();

  const buttonPadding = {
    padding: "1rem 0",
    borderRadius: "4px",
    margin: "2rem 0 1rem 0",
  };

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  const [loading, setloading] = useState(false);

  const [email, setemail] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id == "mail") {
      setemail(value);
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    let api = "frontapi/check_email"; // for fans only
    if (sessionStorage.getItem("resetpwdUserType") == "member") {
      api = "frontapi/check_email_member"; // for members only
    }

    setloading(true);
    postApi(api, formData)
      .then((res) => {
        setloading(false);
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (data[0].status == "success") {
          sessionStorage.setItem("otpemail", email); // used for resending otp
          navigate("/otp-verification");
          sessionStorage.removeItem("resetpwdUserType");
        } else {
          alert("Invalid Email address!");
        }
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
    // }
  };

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="forgot-password container">
          <div className="section-header">
            <h1 className="section-title">Forgot Password</h1>
          </div>
          <div className="section-description">
            <p>
              No worries, it happens. Enter your email and we will send you a
              temporary OTP,
              <br /> which you can use to add your new password.
            </p>
          </div>

          <form onSubmit={handleResetPasswordSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <Label mainLabel={"Email"} for={"email"} />
                  <Input
                    type={"email"}
                    className={"form-control"}
                    id={"mail"}
                    placeholder={"Email Address"}
                    isRequired={true}
                    value={email}
                    onChange={handleChange}
                    name="email-address"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex-fe">
              {loading ? (
                <button
                  className="btn-primary-full mr-0"
                  style={buttonPadding}
                  disabled
                >
                  <LoaderComponent show={true} />
                </button>
              ) : (
                <button
                  onClick={handleResetPasswordSubmit}
                  className="btn-primary-full mr-0 text-center"
                  style={buttonPadding}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
