import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { FollowerCampaignHistoryTable } from "./FollowerCampaignHistory";
import { postApi } from "../../../near/utils/localUtils";
import ModalComponent from "../../common/ModalComponent";
import LoaderComponent from "../../common/LoaderComponent";

function CampaignTemplate(props) {
  const navigate = useNavigate();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );
  const [userinfo, setuserinfo] = useState(
    JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo")) ||
      false
  );
  const [userotherinfo, setuserotherinfo] = useState(
    JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo")) ||
      false
  );

  // prev templates
  const [myTemplates, setmyTemplates] = useState([]);
  const [updateTemplatesList, setupdateTemplatesList] = useState(false);

  // form fields
  const [subject, setsubject] = useState("");
  const [description, setdescription] = useState("");

  useEffect(() => {
    if (!isMember || !isLoggedIn) {
      navigate("/member/sign-in");
    }
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("user_id", userinfo.id);
    formData.append("token", userotherinfo.token);

    postApi("frontapi/follower_campaign", formData)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setmyTemplates(data[0].templates);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [updateTemplatesList]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "subject":
        setsubject(value);
        break;
      case "description":
        setdescription(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("campaign", description);
    formData.append("send", "submit");

    // auth
    formData.append("token", userotherinfo.token);
    formData.append("user_id", userinfo.id);

    setLoading(true);
    postApi("frontapi/campaign_template", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "success") {
          setupdateTemplatesList(!updateTemplatesList);
          setModalInfo({
            show: true,
            type: "primary",
            heading: "Successful",
            body: "Template added!",
          });
          setTimeout(() => {
            setModalInfo({
              show: false,
            });
          }, 1000);

          // clear inputs
          setsubject("");
          setdescription("");
          return;
        } else {
          setModalInfo({
            show: true,
            type: "danger",
            heading: "Failed",
            body: "Something went wrong!",
          });
          setTimeout(() => {
            setModalInfo({
              show: false,
            });
          }, 1000);
        }
        // failure notification here
      })
      .catch((err) => {
        console.log("Err: ", err);
        setModalInfo({
          show: true,
          type: "danger",
          heading: "Error",
          body: "Please check console for error report.",
        });
        setTimeout(() => {
          setModalInfo({
            show: false,
          });
        }, 1000);
      });
  };

  const handleDeleteTempelate = async (templateId) => {
    const formData = new FormData();
    formData.append("id", templateId);
    formData.append("token", userotherinfo.token);
    formData.append("user_id", userinfo.id);

    postApi("frontapi/del_template", formData)
      .then((res) => res.json())
      .then((data) => {
        if (data[0].status == "success") {
          setModalInfo({
            show: true,
            type: "primary",
            heading: "Successful",
            body: "Template Deleted!",
          });
          setTimeout(() => {
            setModalInfo({
              show: false,
            });
          }, 1000);
          setmyTemplates(
            myTemplates.filter((template) => template.id != templateId)
          );
        } else {
          setModalInfo({
            show: true,
            type: "danger",
            heading: "Error",
            body: "Something Went Wrong!",
          });
          setTimeout(() => {
            setModalInfo({
              show: false,
            });
          }, 1000);
        }
      })
      .catch((err) => {
        setModalInfo({
          show: true,
          type: "danger",
          heading: "Error",
          body: "Please check console for error report.",
        });
        setTimeout(() => {
          setModalInfo({
            show: false,
          });
        }, 1000);
      });
  };

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalInfo(false);
  };

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />
      <main className="main-container">
        <div className="supporters-dashboard-wrapper container">
          <div className="section-header">
            <h1 className="section-title"> Create Templates </h1>
          </div>
          <div className="d-flex-sb">
            <p className="font-italic">
              Note: Use{" "}
              <span dangerouslySetInnerHTML={{ __html: "{fname} " }} />
              for first name of fan,
              <span dangerouslySetInnerHTML={{ __html: " {lname} " }} /> for
              last name,
              <span dangerouslySetInnerHTML={{ __html: " {email} " }} /> for
              email in text
            </p>
          </div>
          <div className="create-campaign">
            <form onSubmit={handleSubmit}>
              <div className="dialog-box">
                <h1> Add New Package</h1>

                <div className="form-group">
                  <Label mainLabel={"Subject"} for={"subject"} />
                  <Input
                    type={"text"}
                    name={"subject"}
                    onChange={handleChange}
                    value={subject}
                    className={"form-control"}
                    id={"subject"}
                    isRequired={true}
                    placeholder={"Subject"}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description"> Description </label>
                  <textarea
                    className="form-control"
                    id="description"
                    value={description}
                    placeholder="Description"
                    onChange={handleChange}
                    col="6"
                    rows="6"
                    required
                  ></textarea>
                </div>

                <div className="d-flex-fe">
                  {loading ? (
                    <button className="btn-primary-full mr-0" disabled>
                      <LoaderComponent show={true} />
                    </button>
                  ) : (
                    <button className="btn-primary-full mr-0" type="submit">
                      Save Template
                    </button>
                  )}
                </div>
              </div>
            </form>
            <table className="table table-red">
              <thead>
                <tr>
                  <th> Subject</th>
                  <th> Text</th>
                  <th className="text-center"> Action</th>
                </tr>
              </thead>
              <tbody>
                {myTemplates.map((template) => (
                  <tr key={template.id}>
                    <td> {template.subject}</td>
                    <td>{template.text}</td>
                    <td className="text-right">
                      {/* <a href="#" className="btn-secondary-full m-0 mr-3">
                        {" "}
                        <i className="fa fa-edit mr-2"></i> Edit{" "}
                      </a> */}
                      <a
                        className="btn-error m-0"
                        onClick={() => {
                          handleDeleteTempelate(template.id);
                        }}
                      >
                        <i className="fa fa-trash-alt mr-2"></i> Delete{" "}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CampaignTemplate;
