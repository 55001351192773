import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

export default function ModalComponent({
  modalInfo,
  handleClose,
  oktext = "OK",
  center = false,
  large = false,
  proceedText = "",
  handleOk,
}) {
  let variant = "primary";

  const { show, type, heading, body } = modalInfo;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={type}>{body}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={type} onClick={handleOk || handleClose}>
            {oktext}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<Example />);
