const LOCALDEV = false;

const BASE_URL = "https://dev.tipjarlove.com/";

const URL = {
  LOCAL: "http://localhost:3000/dev/",
  SERVER: "https://k2n6132371.execute-api.us-east-2.amazonaws.com/dev/",
};

export async function post(data, apiEndpoint, contentType, method = "POST") {
  let headers = {};
  let body = data;

  if (contentType) {
    headers = {
      "Content-Type": "application/json",
    };
    body = JSON.stringify(data);
  }

  return await fetch(
    LOCALDEV ? URL.LOCAL + apiEndpoint : URL.SERVER + apiEndpoint,
    {
      method,
      headers,
      mode: "cors",
      body, // body data type must match "Content-Type" header
    }
  ).catch((err) => {
    console.log(err);
  });
}

export async function get(endpoint) {
  return await fetch(LOCALDEV ? URL.LOCAL + endpoint : URL.SERVER + endpoint);
}

export async function fetchMoonPay(
  url = "https://api.moonpay.com/v3/currencies/near/bid_price?apiKey=pk_test_SkPS7WIze94gNd05J9AmUj2Ws681V6B"
) {
  let nearRate = await fetch(url);
  nearRate = await nearRate.json();
  console.log(nearRate.USD);
  return nearRate.USD;
}

export const getApi = async (endpoint) => {
  try {
    let res = await fetch(BASE_URL + endpoint);
    res = res.json();
    return res;
  } catch (err) {
    console.log(`api: ${endpoint} err :${err}`);
  }
};

const a = {
  address: "1",
  card_name: "Fairy Tales Card",
  card_no: "1234567890",
  category_type: "Member / Band",
  city: "ABC",
  cvv: "123",
  dob: "1990-12-31",
  email: "dev@tipjar.com",
  exp_date: "2022-02-18",
  fname: "Test",
  industry: "Live Stream",
  lname: "member",
  near_id: "tipjardev.testnet",
  password: "123",
  pincode: "123254",
  ssn: "0123",
  state: "XYZ",
};

export const postApi = async (endpoint, body, sendJSON = false) => {
  if (sendJSON) {
    try {
      let res = await fetch(BASE_URL + endpoint, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log(res);
      return res;
    } catch (err) {
      console.log(err);
    }
  } else {
    try {
      let res = await fetch(BASE_URL + endpoint, {
        method: "POST",
        body,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  }
};

export const API_BASE_URL = "https://dev.tipjarlove.com/";
export const MASTERACCOUNT_ID = "alyvethedefiant.testnet";
