import React, { useEffect, useState } from "react";
import Navbar from "../include/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../include/Footer";
import NotificationItem from "../common/NotificationItem";
import { postApi } from "../../near/utils/localUtils";

const Notifications = () => {
  const navigate = useNavigate();
  const [isMember, setisMember] = useState(
    JSON.parse(sessionStorage.getItem("isMember")) || false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(sessionStorage.getItem("isFan")) || false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(sessionStorage.getItem("isLoggedIn")) || false
  );

  const [userInfo, setuserInfo] = useState(
    JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"))
  );
  const [userotherinfo, setuserotherinfo] = useState(
    JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"))
  );

  // notifications
  const [namedNofications, setnotifications] = useState([]);
  const [anonymousNotifications, setAnonymousNotifications] = useState([]);

  const [notificationUpdate, setnotificationUpdate] = useState(false);

  // for auth
  const [memberId, setmemberId] = useState("");
  const [authToken, setauthToken] = useState("");

  useEffect(async () => {
    if (!isLoggedIn) {
      navigate("/");
    }

    setauthToken(userotherinfo.token);
    setmemberId(userInfo.id || userInfo.user_id);
    // notifications
    const nformdata = new FormData();
    nformdata.append("user_id", userInfo.id || userInfo.user_id);
    nformdata.append("token", userotherinfo.token);

    let api = "frontapi/notification_list_mus";

    if (isFan) {
      api = "frontapi/notification_list";
    }

    postApi(api, nformdata)
      .then((res) => res.json())
      .then((data) => {
        if (data[0].status != "error") {
          // const records = data[0].records.filter((ntfcn) => ntfcn.view == 0);
          setnotifications(data[0].records);
          if (data[0].records_anonymous) {
            setAnonymousNotifications(data[0].records_anonymous);
          }
        }
      });
  }, [notificationUpdate]);

  const handleMarkAsRead = async () => {
    const formdata = new FormData();
    formdata.append("token", authToken);
    formdata.append("user_id", memberId);
    // https://dev.tipjarlove.com/frontapi/read_all_fan_notification

    let api = "frontapi/read_all_notification";
    if (isFan) {
      api = "frontapi/read_all_fan_notification";
    }

    postApi(api, formdata).then((res) =>
      res
        .json()
        .then((data) => {
          if (data[0].status == "succcess") {
            setnotificationUpdate(!notificationUpdate);
          }
        })
        .catch((err) => {
          console.log("Err: ", err);
        })
    );
  };

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container container">
        <div className="row">
          <div className="notification-detail-wrapper">
            <div className="header">
              <p> Notifications </p>
              <Link to="" onClick={handleMarkAsRead}>
                Mark all as read
              </Link>
            </div>
            <div className="notification-content">
              {NotificationWrapper({
                isFan,
                namedNofications,
                anonymousNotifications,
              })}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

// tablular data
export const NotificationWrapper = ({
  isFan = false,
  namedNofications,
  anonymousNotifications,
}) => {
  return (
    <div className="notification-wrapper">
      <div className="notifications-header">
        <div> Transaction Type</div>
        <div> Name</div>
        <div> Data & Time</div>
        <div> Info</div>
        <div> Currency</div>
      </div>

      {namedNofications.map((ntfctn) => (
        <NotificationItem
          view={ntfctn.view}
          key={`${ntfctn.id}}`}
          transactionType={ntfctn.page_title}
          name={ntfctn.fan}
          date={ntfctn.is_created}
          info={ntfctn.amount != "0" ? ntfctn.amount : "Sub Level 1"}
          imageURL={"/images/near-protocol-near-logo.png"}
        />
      ))}
      {anonymousNotifications.map((ntfctn) => (
        <NotificationItem
          view={ntfctn.view}
          key={`${ntfctn.id}}`}
          transactionType={ntfctn.page_title}
          name={"Anonymous"}
          date={ntfctn.is_created}
          info={ntfctn.amount != "0" ? ntfctn.amount : "Sub Level 1"}
          imageURL={"/images/near-protocol-near-logo.png"}
        />
      ))}
    </div>
  );
};

export default Notifications;
