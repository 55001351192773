import React, { useEffect, useState } from "react";
import Label from "../../../common/Label";
import Input from "../../../common/Input";
import {
  API_BASE_URL,
  getApi,
  postApi,
} from "../../../../near/utils/localUtils";
import ModalComponent from "../../../common/ModalComponent";
import LoaderComponent from "../../../common/LoaderComponent";
import { loginNearFunctional } from "../../../../near/utils/browserUtils";

function EditProfile({ setstorageUpdateFlag, storageUpdateFlag }) {
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [enableOtp, setenableOtp] = useState(false);

  const [accountNumber, setaccountNumber] = useState("");
  const [stripeAccountStatus, setStripeAccountStatus] = useState(false);
  const [avatar, setavatar] = useState(null);
  const [avatarlocation, setavatarlocation] = useState("");
  const [categoryType, setcategoryType] = useState("");
  const [skills, setskills] = useState([]);
  const [slug, setslug] = useState("");
  const [cardName, setcardName] = useState("");
  const [cardNumber, setcardNumber] = useState("");
  const [expDate, setexpDate] = useState("");
  const [cvv, setcvv] = useState("");
  const [isprivate, setPrivate] = useState("");
  const [displaName, setdisplayName] = useState("");
  const [nearid, setnearid] = useState("");
  const [memberId, setmemberId] = useState("");
  const [authToken, setauthToken] = useState("");
  // for form fields
  const [cateroyList, setcateroyList] = useState([]);
  const [skillsList, setskillsList] = useState([]);
  // for stripe verification only
  const [frontImg, setfrontImg] = useState(null);
  const [backImg, setbackImg] = useState(null);

  // const [userinfolocal, setuserinfolocal] = useState({});
  // const [userotherinfo, setuserotherinfo] = useState({});

  useEffect(async () => {
    // state update
    const userotherinfolocaltemp =
      JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"));

    const userinfolocaltemp =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));
    // setuserotherinfo(userinfolocaltemp);

    // setuserinfolocal(userotherinfolocaltemp);

    setauthToken(userotherinfolocaltemp.token);

    setfname(userinfolocaltemp.fname);
    setlname(userinfolocaltemp.lname);
    setmobile(userinfolocaltemp.mobile);
    setenableOtp(userinfolocaltemp.enable_otp);
    setemail(userinfolocaltemp.email);
    setslug(userinfolocaltemp.slug);
    setmemberId(userinfolocaltemp.id);
    setStripeAccountStatus(
      userinfolocaltemp.Stripe_account_status == 0 ? false : true
    );

    // change near id for account update
    if (
      window.walletConnection.isSignedIn() &&
      window.walletConnection.account().accountId != userinfolocaltemp.near_id
    ) {
      setnearid(window.walletConnection.account().accountId);
    } else {
      setnearid(userinfolocaltemp.near_id ? userinfolocaltemp.near_id : "");
    }

    setavatarlocation(
      userinfolocaltemp.avatar
        ? userinfolocaltemp.avatar.includes("http")
          ? `${userinfolocaltemp.avatar}`
          : `${API_BASE_URL}/${userinfolocaltemp.avatar}`
        : ""
    );
    setaccountNumber(userinfolocaltemp.Stripe_account_id || "");

    setskills(userinfolocaltemp.skill.split(","));
    setcategoryType(userinfolocaltemp.cid);

    const displayFormData = await getApi("frontapi/mus_signup");
    setcateroyList(displayFormData[0].category);
    setskillsList(displayFormData[0].skills);
  }, [storageUpdateFlag]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "fname":
        setfname(value);
        break;
      case "lname":
        setlname(value);
        break;
      case "avatar":
        setavatarlocation(URL.createObjectURL(e.target.files[0]));
        setavatar(e.target.files[0]);
        break;
      case "email":
        setemail(value);
        break;
      case "enableOtp":
        setenableOtp(e.target.checked);
        break;
      case "stipeAccStatus":
        setStripeAccountStatus(e.target.checked);
        break;
      case "categoryType":
        setcategoryType(value);
        break;
      case "skill":
        const newvalue = Array.from(
          e.target.selectedOptions,
          (option) => option.value
        );
        setskills(newvalue);
        break;
      case "slug":
        setslug(value);
        break;
      case "accountNumber":
        setaccountNumber(value);
        break;
      case "cardName":
        setcardName(value);
        break;
      case "cardNumber":
        setcardNumber(value);
        break;
      case "expiryDate":
        setexpDate(value);
        break;
      case "cvv":
        setcvv(value);
        break;
      case "displayName":
        setdisplayName(e.target.checked);
        break;
      case "private":
        setPrivate(e.target.checked);
        break;
      case "frontImg":
      // setfrontImg(e.target.files[0]);
      // break;
      case "backImg":
        setbackImg(e.target.files[0]);
        break;
      default:
        console.log("NA");
    }
  };

  const handleUpdateNearId = async (e) => {
    // e.preventDefault()
    await loginNearFunctional();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("enable_otp", enableOtp ? 1 : 0);
    formData.append("slug", slug);
    formData.append("near_id", nearid);
    formData.append("category_type", categoryType);
    // formData.append("category_type", "123");
    formData.append("social_security_number", "1234");
    formData.append("Stripe_account_status", stripeAccountStatus ? "1" : "0");
    formData.append("Stripe_account_id", accountNumber);
    formData.append("display_name", `${fname} ${lname}`);
    formData.append("facebook_id", "#");
    formData.append("twitter_id", "#");
    formData.append("instagram_id", "#");
    formData.append("tiktok_id", "#");
    formData.append("private", isprivate);
    // formData.append("Skills", skills);
    for (var i = 0; i < skills.length; i++) {
      formData.append("Skills[]", skills[i]);
    }

    // console.log(skills);
    formData.append("pic", avatar);
    // auth
    formData.append("user_id", memberId);
    formData.append("token", authToken);
    formData.append("user_type", "Member");
    formData.append("submit", "Update");
    // if (userinfolocal.Stripe_account_status == 0 && frontImg && backImg) {
    //   formData.append("identityfront", frontImg);
    //   formData.append("identityback", backImg);
    // }

    setLoading(true);

    postApi("frontapi/musician_dashboard", formData)
      .then((res) => {
        setLoading(false);

        return res.json();
      })
      .then((data) => {
        if (data[0].status == "success1" || data[0].status == "success") {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "primary",
            heading: "Successfull!",
            body: "Profile Update",
          });
          // update user data on update
          if (JSON.parse(localStorage.getItem("userinfo"))) {
            localStorage.setItem(
              "userinfo",
              JSON.stringify(data[0].musicians[0])
            );
            setstorageUpdateFlag(!storageUpdateFlag);
          } else {
            sessionStorage.setItem(
              "userinfo",
              JSON.stringify(data[0].musicians[0])
            );
            setstorageUpdateFlag(!storageUpdateFlag);
          }

          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
          }, 1000);
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Error",
            body: data[0].message || "Failed",
          });
          setTimeout(() => {
            setModalInfo({
              ...modalInfo,
              show: false,
            });
          }, 1000);
        }
      })
      .catch((err) => {
        setModalInfo({
          ...modalInfo,
          show: true,
          type: "danger",
          heading: "Error",
          body: "Something Went Wrong!",
        });
        setTimeout(() => {
          setModalInfo(
            {
              ...modalInfo,
              show: false,
            },
            1000
          );
        });
        console.log("Err: ", err);
      });
  };

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalInfo(false);
  };

  return (
    <div>
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />

      <div className="container">
        <div className="edit-profile-wrapper">
          <h1> Edit Profile </h1>

          <div className="content-wrapper">
            <form onSubmit={handleSubmit}>
              <div className="row profile-info">
                <div className="col-md-6">
                  <div className="member-detail-field">
                    <div className="row">
                      <div className="col-md-4 col-3">
                        <p> First Name : </p>
                        <p> Second Name : </p>
                        <p> Email : </p>
                        <p> Phone : </p>
                        <p> Enable OTP : </p>
                      </div>
                      <div className="col-md-8 col-9">
                        <div className="form-group">
                          <Input
                            type={"text"}
                            className={"form-control"}
                            name={"fname"}
                            id={"fname"}
                            value={fname}
                            onChange={handleChange}
                            isRequired={true}
                          />
                        </div>

                        <div className="form-group">
                          <Input
                            type={"text"}
                            className={"form-control"}
                            name={"lname"}
                            id={"lname"}
                            value={lname}
                            onChange={handleChange}
                            isRequired={true}
                          />
                        </div>

                        <div className="form-group">
                          <Input
                            type={"email"}
                            className={"form-control"}
                            name={"email"}
                            id={"email"}
                            placeholder={"Email"}
                            value={email}
                            onChange={handleChange}
                            isRequired={true}
                          />
                        </div>

                        <div className="form-group">
                          <Input
                            type={"text"}
                            className={"form-control"}
                            name={"mobile"}
                            id={"mobile"}
                            value={mobile}
                            onChange={handleChange}
                            isRequired={true}
                          />
                        </div>
                        <div className="form-group">
                          <Input
                            type={"checkbox"}
                            className={"form-control"}
                            name={"enableOtp"}
                            id={"enableOtp"}
                            onChange={handleChange}
                            isChecked={
                              enableOtp == "1" || enableOtp == true
                                ? true
                                : false
                            }
                            // isRequired={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 member-image">
                  <div className="avatar-upload">
                    <input
                      id="avatar"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleChange}
                    />

                    <div className="avatar-preview">
                      <img id="imagePreview" src={`${avatarlocation}`}></img>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-row mt-lg-5">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"NEAR Account ID"} for={"nearid"} />
                    <Input
                      type={"text"}
                      className={"form-control"}
                      name={"nearid"}
                      id={"nearid"}
                      value={nearid}
                      onChange={handleChange}
                      // isRequired={true}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div
                    className="form-group btn-secondary-full updateBtn mr-10"
                    onClick={handleUpdateNearId}
                    style={{ textAlign: "center" }}
                  >
                    Update NEAR ID
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="form-check">
                  <Input
                    type={"checkbox"}
                    className={"form-check-input"}
                    id={"displayName"}
                  />
                  <Label
                    mainLabel={
                      "Display first name and last name in musician list"
                    }
                    for={"display"}
                    className={"form-check-label text-dark"}
                  />
                </div>
              </div>

              <div className="form-group mb-lg-5">
                <div className="form-check">
                  <Input
                    type={"checkbox"}
                    className={"form-check-input"}
                    id={"private"}
                    // isRequired={true}
                  />
                  <Label
                    mainLabel={"Make my profile private"}
                    for={"private"}
                    className={"form-check-label text-dark"}
                  />
                </div>
              </div>

              <div className="form-row mt-lg-5">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <Label
                      mainLabel={"Make my profile private"}
                      for={"categoryType"}
                    />
                    <select
                      id="categoryType"
                      className="form-control"
                      onChange={handleChange}
                      required
                      value={categoryType}
                    >
                      <option
                        disabled={true}
                        defaultValue={"Select"}
                        hidden={true}
                      >
                        Select
                      </option>
                      {cateroyList.map((ctgry) => (
                        <option key={ctgry.id} value={ctgry.id}>
                          {" "}
                          {ctgry.title}{" "}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Category"} for={"category"} />

                    <select
                      id={"skill"}
                      className="form-control"
                      onChange={handleChange}
                      required
                      name={"Skills"}
                      multiple
                      value={skills}
                    >
                      <option
                        defaultValue={skills}
                        readOnly={true}
                        hidden={true}
                      >
                        Select
                      </option>
                      {skillsList.map((skill) => (
                        <option
                          key={skill.id}
                          value={skill.id}
                          defaultValue={skills.includes(skill.id)}
                        >
                          {skill.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-row mt-lg-5">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Slug"} for={"cardName"} />
                    <Input
                      type={"text"}
                      className={"form-control"}
                      name={"slug"}
                      id={"slug"}
                      value={slug}
                      onChange={handleChange}
                      isRequired={true}
                    />
                  </div>
                </div>
              </div>
              <hr />

              <div className="form-row mt-lg-5">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Card Name"} for={"cardName"} />
                    <Input
                      type={"text"}
                      className={"form-control"}
                      name={"cardName"}
                      id={"cardName"}
                      // defaultValue={"John Doe"}
                      value={cardName}
                      onChange={handleChange}
                      // isRequired={true}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <Label mainLabel={"Card Number"} for={"cardNumber"} />
                    <Input
                      type={"text"}
                      className={"form-control"}
                      name={"cardNumber"}
                      id={"cardNumber"}
                      value={cardNumber}
                      onChange={handleChange}
                      // isRequired={true}
                    />
                  </div>
                </div>
              </div>

              <div className="form-row mt-lg">
                <div className="col-md-6 col-6">
                  <div className="form-group">
                    <Label mainLabel={"Expiry Date"} for={"expiryDate"} />
                    <Input
                      type={"date"}
                      className={"form-control"}
                      name={"expiryDate"}
                      id={"expiryDate"}
                      value={expDate}
                      onChange={handleChange}
                      // isRequired={true}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-6">
                  <div className="form-group">
                    <Label mainLabel={"CVV"} for={"cvv"} />
                    <Input
                      type={"text"}
                      className={"form-control"}
                      name={"cvv"}
                      id={"cvv"}
                      placeholder={"CVV"}
                      value={cvv}
                      onChange={handleChange}
                      // isRequired={true}
                    />
                  </div>
                </div>
              </div>

              {loading ? (
                <div className="d-flex-fe">
                  <button className="btn-primary-full updateBtn mr-0" disabled>
                    <LoaderComponent show={true} />{" "}
                  </button>{" "}
                </div>
              ) : (
                <div className="d-flex-fe">
                  <button
                    className="btn-primary-full updateBtn mr-0"
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
