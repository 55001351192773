import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import ReactTooltip from "react-tooltip";
import ToolTip from "../../common/ToolTip";
import Label from "../../common/Label";
import Input from "../../common/Input";
import Select from "../../common/Select";
import { getApi, post, postApi } from "../../../near/utils/localUtils";
import ModalComponent from "../../common/ModalComponent";
import LoaderComponent from "../../common/LoaderComponent";
import BlankComponent from "../../common/BlankCompo";
import { loginNearFunctional } from "../../../near/utils/browserUtils";
// import Stripe from "stripe";
import MonthPickerInput from "react-month-picker-input";
import "react-month-picker-input/dist/react-month-picker-input.css";

// const stripe = new Stripe("sk_test_4eC39HqLyjWDarjtT1zdp7dc");
// const stripe = new Stripe("'sk_test_...'");
// const stripe = require("stripe")("sk_test_4eC39HqLyjWDarjtT1zdp7dc");

function SignUp(props) {
  const navigate = useNavigate();

  // for form fields
  const [cateroyList, setcateroyList] = useState([]);
  const [skillsList, setskillsList] = useState([]);
  const [tooltips, settooltips] = useState({
    address: "",
    last_four_digit: "",
    debit_card_only: "",
  });

  useEffect(async () => {
    if (isLoggedIn) {
      navigate("/");
    }
    const signupFormData = await getApi("frontapi/mus_signup");
    setcateroyList(signupFormData[0].category);
    setskillsList(signupFormData[0].skills);
    // store tooltips are JS object
    let tooltipsData = {};
    signupFormData[0].tooltips.forEach((tip) => {
      tooltipsData = { ...tooltipsData, [tip.tooltip_key]: tip };
    });
    if (signupFormData) {
      settooltips(tooltipsData);
    }

    // route near signup (after connecting near id)
    if (
      sessionStorage.getItem("membernearsignup") &&
      window.walletConnection.isSignedIn()
    ) {
      navigate("/member/sign-in");
    } else {
      sessionStorage.clear();
    }

    if (window.localStorage) {
      if (!localStorage.getItem("reload")) {
        localStorage["reload"] = true;
        window.location.reload();
      } else {
        localStorage.removeItem("reload");
      }
    }
  }, []);

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  // user signup data
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [dob, setdob] = useState("");
  const [mobile, setmobile] = useState("");
  const [nearAccountAction, setnearAccountAction] = useState("");
  const [ssn, setssn] = useState("");
  const [address, setaddress] = useState({
    address: "",
    city: "",
    state: "",
    zipCode: "",
    referralCode: "",
  });
  const [category, setcategory] = useState({ type: "", category: [] });
  const [avatar, setavatar] = useState(null);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [cardDetails, setcardDetails] = useState({
    name: "",
    cardNumber: "",
    expiryDate: "2023-03", // dafault value
    cvv: "",
  });
  const [avatarLocation, setavatarLocation] = useState("#");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [nearaccountid, setnearaccountid] = useState("");
  const [userRefCode, setuserRefCode] = useState("");

  // for validation
  const [currentStep, setcurrentStep] = useState(1);

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalInfo(false);
  };

  // const [nearAccountAction, setnearAccountAction] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id == "fName") {
      setfname(value);
    } else if (id == "lName") {
      setlname(value);
    } else if (id == "mobile") {
      setmobile(value);
    } else if (id == "user_ref_code") {
      setuserRefCode(value);
    } else if (id == "nearaccountaction") {
      setnearAccountAction(value);
    } else if (id == "nearaccountid") {
      setnearaccountid(value);
    } else if (id == "DOB") {
      setdob(value);
    } else if (id == "socialSecurity") {
      setssn(value);
    }
    // storing address as object
    else if (id == "address") {
      setaddress({ ...address, address: value });
    } else if (id == "city") {
      setaddress({ ...address, city: value });
    } else if (id == "state") {
      setaddress({ ...address, state: value });
    } else if (id == "zipCode") {
      setaddress({ ...address, zipCode: value });
    } else if (id == "referralCode") {
      setaddress({ ...address, referralCode: value });
    }
    // member type as object
    else if (id == "categoryType") {
      setcategory({ ...category, type: value });
    } else if (id == "category") {
      let value = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setcategory({ ...category, category: value });
    }
    // avatar
    else if (id == "upload") {
      setavatarLocation(URL.createObjectURL(e.target.files[0]));
      setavatar(e.target.files[0]);
    }
    // credentials
    else if (id == "email") {
      setemail(value);
    } else if (id == "password") {
      setpassword(value);
    } else if (id == "cPassword") {
      setconfirmPassword(value);
    }
    // card details
    else if (id == "cardName") {
      setcardDetails({ ...cardDetails, name: value });
    } else if (id == "cardNumber") {
      setcardDetails({ ...cardDetails, cardNumber: value });
    } else if (id == "cvv") {
      setcardDetails({ ...cardDetails, cvv: value });
    } else if (id == "expiryDate") {
      // current format => month/year
      // required format => year-month
      const valSplit = value.split("/");

      setcardDetails({
        ...cardDetails,
        expiryDate: `${valSplit[1]}-${valSplit[0]}`,
      });
    } else {
      setAcceptTerms(e.target.checked);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // step 6 validation
    document.getElementById(`step6btndisplay`).removeAttribute("disabled");

    let formData = new FormData();
    formData.append("fname", fname);
    formData.append("lname", lname);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("cpassword", confirmPassword);
    formData.append("dob", dob);
    formData.append("ssn", ssn);
    formData.append("address", address.address);
    formData.append("city", address.city);
    formData.append("state", address.state);
    formData.append("pincode", address.zipCode);
    formData.append("reffral_code", address.referralCode || "");
    formData.append("ref_code", userRefCode); // this is user's own ref code
    formData.append("country", "US");
    formData.append("category_type", category.type);
    formData.append("industry", "");

    for (var i = 0; i < category.category.length; i++) {
      formData.append("skills[]", category.category[i]);
    }
    formData.append("card_name", cardDetails.name);
    formData.append("card_no", cardDetails.cardNumber);
    formData.append(
      "exp_date",
      `${cardDetails.expiryDate.split("-")[1]}/${
        cardDetails.expiryDate.split("-")[0]
      }`
    );
    formData.append("cvv", cardDetails.cvv);
    // send empty string for subacount creation
    if (nearAccountAction == "own") {
      formData.append("near_id", nearaccountid);
    } else if (nearAccountAction == "no") {
      formData.append("near_id", "NULL");
    } else {
      formData.append("near_id", "-");
    }
    formData.append("mobile", mobile);
    formData.append("pic", avatar);
    if (password != confirmPassword) {
      setModalInfo({
        ...modalInfo,
        show: true,
        type: "danger",
        heading: "Password Error",
        body: "Passwords must match",
      });
    } else {
      // post signup data
      const postSignUpfrom = async (formData) => {
        postApi("front/mus_signup_action", formData)
          .then((res) => {
            setLoading(false);
            return res.json();
          })
          .then((data) => {
            if (data.status == "success1" || data.status == "success") {
              setModalInfo({
                ...modalInfo,
                show: true,
                type: "primary",
                heading: "Account Created!",
                body: "Please Check Your Email To Activate Your Account!!",
              });

              setTimeout(() => {
                setModalInfo({
                  ...modalInfo,
                  show: false,
                });
                navigate("/member/sign-in");
              }, 1000);
            } else if (data.status == "error") {
              setModalInfo({
                ...modalInfo,
                show: true,
                type: "danger",
                heading: "Error",
                body: data.message,
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            setModalInfo({
              ...modalInfo,
              show: true,
              type: "danger",
              heading: "Error",
              body: "Something Went Wrong!",
            });
            console.log("Err: ", err);
          });
      };

      setLoading(true);

      // send user data directly to server if there is no referral code used
      if (!address.referralCode.length) {
        formData.append("valid_reffral_flag", "");
        setModalInfo({
          show: true,
          type: "danger",
          heading: "Attention",
          body: "Siging Up without a referral code!",
        });
        setTimeout(() => {
          setModalInfo({
            show: false,
          });
        }, 1000);

        postSignUpfrom(formData);
        return;
      }

      const refCode = new FormData();
      refCode.append("referral", address.referralCode);
      // check if referral code is valid
      postApi(`Frontapi/check_referral`, refCode)
        .then((res) => res.json())
        .then((data) => {
          if (data.status == "success") {
            formData.append("valid_reffral_flag", 1);
            postSignUpfrom(formData);
          } else {
            setModalInfo({
              show: true,
              type: "danger",
              heading: "Attention",
              body: "Invalid Referral Code! But You can still signup!",
            });
            setTimeout(() => {
              setModalInfo({
                show: false,
              });
            }, 1000);

            formData.append("valid_reffral_flag", 0);
            postSignUpfrom(formData);
          }
        })
        .catch((err) => {
          setLoading(false);
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Error",
            body: "Something went wrong!",
          });
        });
    }
  };

  const handleMoveNextTab = (activatedTab) => {
    // move to next step when all inputs are ok
    const moveToNextStep = (step) => {
      setcurrentStep(step);
      if (currentStep < step) {
        document.getElementById(`step${currentStep + 1}btn`).click();
        document
          .getElementById(`step${currentStep}btndisplay`)
          .removeAttribute("disabled");
      } else {
        document.getElementById(`step${currentStep}btn`).click();
      }
    };

    // alert and stay on current page (for invalid/empty input)
    const invalidInputAlert = (alertTxt) => {
      alert(alertTxt);
      document.getElementById(`step${currentStep}btndisplay`).click();
    };

    // step 1-6
    if (activatedTab == "1") {
      document.getElementById("step1btn").click();
    } else if (activatedTab == "2") {
      if (!fname) {
        invalidInputAlert("Please enter the First Name.");
      } else if (!lname) {
        invalidInputAlert("Please enter the Last Name.");
      } else if (!mobile) {
        invalidInputAlert("Please enter the mobile number.");
        // } else if (!address.referralCode) {
        //   invalidInputAlert("Please enter the Referral Code.");
      } else if (!avatar) {
        invalidInputAlert("Please select a Display Photo.");
      } else {
        moveToNextStep(2);
      }
    } else if (activatedTab == "3") {
      if (!email) {
        invalidInputAlert("Please enter the email address");
      } else if (!password) {
        invalidInputAlert("Please enter a password");
      } else if (!confirmPassword) {
        invalidInputAlert("Please enter a password");
      } else if (password != confirmPassword) {
        invalidInputAlert("passwords do not match!");
      } else {
        moveToNextStep(3);
      }
    } else if (activatedTab == "4") {
      if (!address.address) {
        invalidInputAlert("Please enter your address.");
      } else if (!address.city) {
        invalidInputAlert("Please enter your city name.");
      } else if (!address.state) {
        invalidInputAlert("Please enter your state name.");
      } else if (!address.zipCode) {
        invalidInputAlert("Please enter your zip code.");
      } else {
        moveToNextStep(4);
      }
    } else if (activatedTab == "5") {
      if (!dob) {
        invalidInputAlert("Please enter your data of birth.");
      } else if (ssn.length < 4) {
        invalidInputAlert(
          "Please enter last 4 digits of your social security number."
        );
      } else if (!cardDetails.name) {
        invalidInputAlert("Please enter the credit card name.");
      } else if (cardDetails.cardNumber.length < 16) {
        invalidInputAlert("Please enter the 16 digits credit card number.");
      } else if (cardDetails.cvv.length < 3) {
        invalidInputAlert("Please enter the 3 digits credit card CVV.");
      } else if (!cardDetails.expiryDate) {
        invalidInputAlert("Please enter the credit card expiry date.");
      } else {
        moveToNextStep(5);
      }
    } else if (activatedTab == "6") {
      if (!nearAccountAction) {
        invalidInputAlert("Please select NEAR account action.");
      } else if (nearAccountAction == "own" && !nearaccountid) {
        invalidInputAlert("Please enter your NEAR wallet ID.");
      } else {
        moveToNextStep(6);
      }
    }
  };

  // modal for T&C
  const [showModal, setshowModal] = useState(false);
  const handlShowModal = (e, show = false) => {
    setshowModal(show);
  };

  return (
    <div>
      <BlankComponent
        show={showModal}
        handleClose={handlShowModal}
        endpoint="frontapi/cms/terms-conditions"
      />

      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />

      <main className="main-container">
        <div className="fan-container member-signup container">
          <div className="form-wrapper">
            <div className="ribbon-heading d-flex-sb">
              <h2> Member SignUp </h2>
              <div className="sign-up-link">
                <p className="mb-0"> Already have an account? </p> &nbsp;&nbsp;
                <Link to="/member/sign-in" className="link">
                  Sign In
                </Link>
              </div>
            </div>
            <div
              className="section-title"
              style={{ cursor: "pointer" }}
              onClick={() => {
                sessionStorage.setItem("membernearsignup", true);
                loginNearFunctional();

                sessionStorage.setItem(
                  "userinfo",
                  JSON.stringify({
                    membernearid: true,
                    redirecting: true,
                  })
                );
              }}
            >
              Simple &nbsp; <img src="/images/near-logo.svg" /> &nbsp; Sign Up
            </div>
            <h1 className="section-subtitle" style={{ textAlign: "center" }}>
              OR
            </h1>
            <h1 className="section-title">Regular Sign Up</h1>
            <p>
              Log back in to continue giving your fans exclusive content and the
              ability to support your career!
            </p>
            <div className="container">
              <div className="stepwizard col-md-offset-3">
                <div className="stepwizard-row setup-panel">
                  <div className="stepwizard-step">
                    <a
                      id="step1btndisplay"
                      type="button"
                      className="btn btn-primary btn-circle"
                      disabled="disabled"
                      onClick={() => {
                        handleMoveNextTab(1);
                      }}
                    >
                      1
                    </a>
                    <p>Personal</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      id="step2btndisplay"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      onClick={() => {
                        handleMoveNextTab(2);
                      }}
                    >
                      2
                    </a>
                    <p>Login</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      id="step3btndisplay"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      onClick={() => {
                        handleMoveNextTab(3);
                      }}
                    >
                      3
                    </a>
                    <p>Address</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      id="step4btndisplay"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      onClick={() => {
                        handleMoveNextTab(4);
                      }}
                    >
                      4
                    </a>
                    <p>Sensitive Information</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      id="step5btndisplay"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      onClick={() => {
                        handleMoveNextTab(5);
                      }}
                    >
                      5
                    </a>
                    <p>NEAR Options</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      id="step6btndisplay"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      onClick={() => {
                        handleMoveNextTab(6);
                      }}
                    >
                      6
                    </a>
                    <p>Wrapping up Details</p>
                  </div>
                </div>

                {/* to move to next tab - to be clicked with code */}
                <div
                  className="stepwizard-row setup-panel"
                  style={{ display: "none" }}
                >
                  <div className="stepwizard-step">
                    <a
                      href="#step-1"
                      type="button"
                      className="btn btn-primary btn-circle"
                      id="step1btn"
                    >
                      1
                    </a>
                    <p>Personal</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      href="#step-2"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      id="step2btn"
                    >
                      2
                    </a>
                    <p>Login</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      href="#step-3"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      id="step3btn"
                    >
                      3
                    </a>
                    <p>Address</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      href="#step-4"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      id="step4btn"
                    >
                      4
                    </a>
                    <p>Sensitive Information</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      href="#step-5"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      id="step5btn"
                    >
                      5
                    </a>
                    <p>NEAR Options</p>
                  </div>
                  <div className="stepwizard-step">
                    <a
                      href="#step-6"
                      type="button"
                      className="btn btn-default btn-circle"
                      disabled="disabled"
                      id="step6btn"
                    >
                      6
                    </a>
                    <p>Wrapping up Details</p>
                  </div>
                </div>
              </div>

              <form className="step-form" onSubmit={handleSubmit}>
                <div className="row setup-content" id="step-1">
                  <div className="col-md-12">
                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"First Name"} for={"fName"} />
                          <input
                            type={"text"}
                            name={"fname"}
                            className={"form-control"}
                            id={"fName"}
                            placeholder={"First Name"}
                            value={fname}
                            required
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"Last Name"} for={"lName"} />
                          <input
                            type={"text"}
                            name={"lname"}
                            className={"form-control"}
                            id={"lName"}
                            placeholder={"Last Name"}
                            value={lname}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"Phone Number"} for={"mobile"} />
                          <input
                            type="tel"
                            name={"mobile"}
                            className={"form-control"}
                            id={"mobile"}
                            required
                            placeholder={"Phone Number"}
                            value={mobile}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label
                            mainLabel={"Verification Referral Code"}
                            for={"referralCode"}
                          />

                          <ToolTip
                            toolTipText={
                              "This is the code you received from a member of TipJarLive.  With this code you have been given exclusive access to the TipJarLive platform and ecosystem."
                            }
                          />

                          <Input
                            type={"text"}
                            name={"reffral_code"}
                            minlength={2}
                            className={"form-control"}
                            id={"referralCode"}
                            placeholder={"Verification Referral Code"}
                            value={address.referralCode}
                            onChange={handleChange}
                            // isRequired={true}
                          />
                          <small className="help-text">
                            This is the code you received from a member of
                            TipJarLive. With this code you have been given
                            exclusive access to the TipJarLive platform and
                            ecosystem.
                          </small>
                        </div>
                      </div>

                      <div className="form-row mb-5 display-picture">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="picture">Display Picture</label>
                            <div className="image-upload">
                              {/* <img src="/images/image-placeholder.svg" alt="" /> */}
                              <p>Add Display Photo</p>
                              <p>(up to 12 MB)</p>
                              {/*Upload image input */}

                              <div className="input-group rounded-pill bg-white shadow-sm">
                                <input
                                  id="upload"
                                  type="file"
                                  name="pic"
                                  onChange={handleChange}
                                  className="form-control mb-0"
                                  reqruired
                                />
                                <label
                                  id="upload-label"
                                  htmlFor="upload"
                                  className="font-weight-light text-muted"
                                >
                                  Choose file
                                </label>
                                <div className="input-group-append">
                                  <label
                                    htmlFor="upload"
                                    className="btn btn-light m-0 rounded-pill px-4"
                                  >
                                    <i className="fas fa-cloud-upload-alt mr-2 text-muted"></i>
                                    <small className="text-uppercase font-weight-bold text-muted">
                                      Choose file
                                    </small>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="uploaded-image">
                            {/*Uploaded image area*/}
                            <p className="font-italic text-center mb-3">
                              The image uploaded will be rendered inside the box
                              below.
                            </p>
                            <div className="image-area">
                              <img
                                id="imageResult"
                                src={avatarLocation}
                                alt=""
                                className="img-fluid rounded shadow-sm mx-auto d-block"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex-fe">
                      <button
                        className="btn-primary-full mr-0"
                        type="button"
                        onClick={() => {
                          handleMoveNextTab(2);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row setup-content" id="step-2">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Label mainLabel={"Email"} for={"email"} />
                      <input
                        type={"email"}
                        name={"email"}
                        className={"form-control"}
                        id={"email"}
                        placeholder={"Email"}
                        value={email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"Password"} for={"password"} />
                          <input
                            type={"password"}
                            name={"password"}
                            className={"form-control"}
                            id={"password"}
                            placeholder={"Password"}
                            value={password}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label
                            mainLabel={"Confirm Password"}
                            for={"cPassword"}
                          />
                          <input
                            type={"password"}
                            name={"cpassword"}
                            className={"form-control"}
                            id={"cPassword"}
                            placeholder={"Confirm Password"}
                            value={confirmPassword}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex-sb d-flex-ai">
                      <a className="link prevBtn">Previous</a>
                      <button
                        className="btn-primary-full mr-0"
                        type="button"
                        onClick={() => {
                          handleMoveNextTab(3);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row setup-content" id="step-3">
                  <div className="col-md-12">
                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="address">
                            Address
                            <ToolTip
                              toolTipText={tooltips["address"].text || ""}
                            />
                          </label>
                          <input
                            type={"text"}
                            name={"address"}
                            className={"form-control"}
                            id={"address"}
                            placeholder={"Address"}
                            value={address.address}
                            onChange={handleChange}
                            required
                          />
                          <small className="help-text">
                            {tooltips["address"].text}
                          </small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"City"} for={"city"} />
                          <input
                            type={"text"}
                            name={"city"}
                            className={"form-control"}
                            id={"city"}
                            placeholder={"City"}
                            value={address.city}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"State"} for={"state"} />
                          <input
                            type={"text"}
                            name={"state"}
                            className={"form-control"}
                            id={"state"}
                            placeholder={"State"}
                            value={address.state}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"ZIP Code"} for={"zipCode"} />
                          <input
                            type={"text"}
                            name={"pincode"}
                            className={"form-control"}
                            id={"zipCode"}
                            placeholder={"ZIP Code"}
                            value={address.zipCode}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex-sb d-flex-ai">
                      <a className="link prevBtn">Previous</a>
                      <button
                        className="btn-primary-full mr-0"
                        type="button"
                        onClick={() => {
                          handleMoveNextTab(4);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row setup-content" id="step-4">
                  <div className="col-md-12">
                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"Date of Birth"} for={"lName"} />
                          <input
                            type={"date"}
                            name={"dob"}
                            className={"form-control"}
                            id={"DOB"}
                            placeholder={"Date of Birth"}
                            // autoComplete={"off"}
                            value={dob}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="socialSecurity">
                            Last four of Social Security Number
                            <ToolTip
                              toolTipText={
                                tooltips["last_four_digit"].text || ""
                              }
                            />
                          </label>
                          <input
                            type={"text"}
                            name={"ssn"}
                            maxLength={4}
                            className={"form-control"}
                            id={"socialSecurity"}
                            placeholder={"XXXX"}
                            max={9999}
                            value={ssn}
                            onChange={handleChange}
                            required
                          />
                          <small className="help-text">
                            {tooltips["last_four_digit"].text}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 debit-card">
                    <h3> Debit Card Only</h3>
                    <p>
                      Why do we need this?
                      <ToolTip
                        toolTipText={
                          tooltips["debit_card_only"].text || ""
                          // "This is needed for you to receive your money."
                        }
                      />
                      <small className="help-text mt-3">
                        {tooltips["debit_card_only"].text}
                      </small>
                    </p>

                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"Card Name"} for={"cardName"} />
                          <input
                            type={"text"}
                            name={"card_name"}
                            className={"form-control"}
                            id={"cardName"}
                            placeholder={"Card Name"}
                            value={cardDetails.name}
                            onChange={handleChange}
                            // isRequired={true}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"Card Number"} for={"cardNumber"} />
                          <input
                            type={"text"}
                            name={"card_no"}
                            className={"form-control"}
                            id={"cardNumber"}
                            placeholder={"Card Number"}
                            maxLength="16"
                            value={cardDetails.cardNumber}
                            onChange={handleChange}
                            // isRequired={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"Expiry Date"} for={"expiryDate"} />
                          {/* <Input
                            type={"month"}
                            name={"exp_date"}
                            className={"form-control"}
                            id={"expiryDate"}
                            placeholder={"Expiry Date"}
                            value={cardDetails.expiryDate}
                            onChange={handleChange}
                            // isRequired={true}
                          /> */}
                          <MonthPickerInput
                            year={2023}
                            month={2}
                            id="expiryDate"
                            onChange={(data, month, yr) => {
                              handleChange({
                                target: {
                                  id: "expiryDate",
                                  value: data,
                                },
                              });
                            }}
                            inputProps={{
                              className: "form-control",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"CVV"} for={"cvv"} />
                          <input
                            type={"text"}
                            name={"cvv"}
                            className={"form-control"}
                            id={"cvv"}
                            placeholder={"CVV"}
                            value={cardDetails.cvv}
                            onChange={handleChange}
                            maxLength={3}
                            // isRequired={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex-sb d-flex-ai">
                      <a className="link prevBtn">Previous</a>
                      <button
                        className="btn-primary-full mr-0"
                        type="button"
                        onClick={() => {
                          handleMoveNextTab(5);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>

                  {/*</div>*/}
                </div>
                <div className="row setup-content" id="step-5">
                  <div className="col-md-12">
                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group">
                          <Label
                            mainLabel={"Choose NEAR Account Action"}
                            for={"nearaccount"}
                          />
                          <select
                            id="nearaccountaction"
                            className="form-control"
                            onChange={handleChange}
                            required
                          >
                            <option
                              defaultValue={"Select"}
                              readOnly={true}
                              hidden={true}
                            >
                              Select
                            </option>
                            <option value="no">
                              Signup without NEAR account
                            </option>
                            <option value="own">Use my own NEAR account</option>
                            <option value="create">
                              Create a NEAR account for me
                            </option>
                          </select>
                        </div>
                      </div>

                      {nearAccountAction == "own" ? (
                        <div className="col-6">
                          <div className="form-group">
                            <Label
                              mainLabel={"Your NEAR account ID"}
                              for={"nearaccountid"}
                            />
                            <input
                              type={"text"}
                              name={"nearaccountid"}
                              className={"form-control"}
                              id={"nearaccountid"}
                              placeholder={"your.mainnet"}
                              value={nearaccountid}
                              onChange={handleChange}
                              required
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="d-flex-sb d-flex-ai">
                      <a className="link prevBtn">Previous</a>
                      <button
                        className="btn-primary-full mr-0"
                        type="button"
                        onClick={() => {
                          handleMoveNextTab(6);
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row setup-content" id="step-6">
                  <div className="col-md-12">
                    <div className="form-row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="ref_code">
                            Your Referral Code
                            <ToolTip
                              toolTipText={
                                "This is your permanent referral code. Give this code to other creators and give them exclusive access to TipJarLive and participate in the TJL referral and rewards program!"
                              }
                            />
                          </label>

                          <input
                            type="text"
                            name={"ref_code"}
                            className={"form-control"}
                            id={"user_ref_code"}
                            required
                            placeholder={"Referral Code"}
                            value={userRefCode}
                            onChange={handleChange}
                          />
                          <small className="help-text mt-3">
                            This is your permanent referral code. Give this code
                            to other creators and give them exclusive access to
                            TipJarLive and participate in the TJL referral and
                            rewards program!
                          </small>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label
                            mainLabel={"Category Type"}
                            for={"categoryType"}
                          />
                          <select
                            id="categoryType"
                            name={"category_type"}
                            className="form-control"
                            onChange={handleChange}
                            required
                          >
                            <option
                              defaultValue={"Select"}
                              readOnly={true}
                              hidden={true}
                              //   value={category.type}
                            >
                              Select
                            </option>
                            {cateroyList.map((ctgry) => (
                              <option key={ctgry.id} value={ctgry.id}>
                                {" "}
                                {ctgry.title}{" "}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-12">
                        <div className="form-group">
                          <Label mainLabel={"Category"} for={"category"} />
                          <select
                            id={"category"}
                            className="form-control"
                            onChange={handleChange}
                            required
                            name={"skills[]"}
                            multiple
                            value={category.category}
                          >
                            <option
                              defaultValue={"Select"}
                              readOnly={true}
                              hidden={true}
                            >
                              Select
                            </option>
                            {skillsList.map((skill) => (
                              <option key={skill.id} value={skill.id}>
                                {skill.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-check">
                        <input
                          type={"checkbox"}
                          className={"form-check-input"}
                          id={"terms-conditions"}
                          onChange={handleChange}
                          checked={acceptTerms}
                          rquired
                        />
                        <label
                          className="form-check-label"
                          htmlFor="terms-conditions"
                        >
                          <a
                            onClick={(e) => {
                              handlShowModal(e, true);
                            }}
                            className="link text-decoration-none"
                          >
                            Terms & Conditions
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <div className="d-flex-sb d-flex-ai w-100 mt-5 submit-step">
                      <a className="link prevBtn"></a>
                      <button
                        className="btn-success-full mr-0"
                        type="submit"
                        disabled
                      >
                        <LoaderComponent show={true} />{" "}
                      </button>{" "}
                    </div>
                  ) : (
                    <div className="submit-step w-100">
                      <div className="d-flex-sb d-flex-ai w-100 mt-5">
                        <a className="link prevBtn">Previous</a>
                        <button className="btn-success-full mr-0" type="submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default SignUp;
