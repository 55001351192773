import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { postApi } from "../../../near/utils/localUtils";
import LoaderComponent from "../../common/LoaderComponent";

function FollowerSettings(props) {
  const navigate = useNavigate();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  // available packages list
  const [subscribePackageList, setsubscribePackageList] = useState([
    {
      id: "1",
      amount: "59",
      is_created: "2022-03-09 02:05:38",
      status: "1",
      mid: "18",
      fid: "0",
      title: "Package 1",
      duration: "Weekly",
      description: "Test Package 1",
    },
  ]);

  // add package form data
  const [title, settitle] = useState("");
  const [amount, setamount] = useState("");
  const [terms, setterms] = useState("");
  const [description, setdescription] = useState("");

  // for auth
  const [userotherinfo, setuserotherinfo] = useState("");
  const [userid, setUserid] = useState("");

  // loader
  const [loading, setloading] = useState(false);

  useEffect(async () => {
    if (!isMember || !isLoggedIn) {
      navigate("/member/sign-in");
      return;
    }
    const userinfo =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));
    const userotherinfo =
      JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"));

    setUserid(userinfo.id);
    setuserotherinfo(userotherinfo);
    setsubscribePackageList(userotherinfo.subscribe_package);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "title":
        settitle(value);
        break;
      case "terms":
        setterms(value);
        break;
      case "amount":
        setamount(value);
        break;
      case "description":
        setdescription(value);
        break;
    }
  };

  const handleAddPackageSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("terms", terms);
    formData.append("amount", amount);
    formData.append("description", description);
    formData.append("submit", "Save");

    // auth
    formData.append("token", userotherinfo.token);
    formData.append("user_id", userid);

    setloading(true);

    postApi("frontapi/follower_settings", formData)
      .then((res) => {
        setloading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "success") {
          const updatedUserOtherInfo = {
            ...userotherinfo,
            subscribe_package: data[0].subscribe_package,
          };
          setsubscribePackageList(data[0].subscribe_package);
          sessionStorage.setItem(
            "otherinfo",
            JSON.stringify(updatedUserOtherInfo)
          );
          if (localStorage.getItem("otherinfo")) {
            localStorage.setItem(
              "otherinfo",
              JSON.stringify(updatedUserOtherInfo)
            );
          }
          alert("Added");

          settitle("");
          setamount("");
          setdescription("");

          // success notification to be added here
          return;
        }
        // failure notification to be added here
        return;
      })
      .catch((err) => {
        console.log("Err: ", err);
        // error notification to be added here
      });
  };

  return (
    <div>
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="supporters-dashboard-wrapper container">
          <div className="section-header">
            <h1 className="section-title"> Follower Package Settings </h1>
          </div>

          <div className="main-content mb-lg-5">
            <table className="table table-red table-responsive">
              <thead>
                <tr>
                  <th scope="col"> Title of Package</th>
                  <th scope="col"> Amount</th>
                  <th scope="col"> Terms</th>
                  <th scope="col"> Description</th>
                  <th scope="col"> Status</th>
                </tr>
              </thead>
              {subscribePackageList.length ? (
                <tbody>
                  {subscribePackageList.map((pkg) => (
                    <tr key={pkg.id}>
                      <td> {pkg.title}</td>
                      <td> {pkg.amount}</td>
                      <td> {pkg.duration}</td>
                      <td>{pkg.description} </td>
                      <td>{pkg.status == "1" ? "Active" : "Inactive"}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody></tbody>
              )}
            </table>

            {/* form */}
            <div className="dialog-box">
              <h1> Add New Package </h1>

              <form onSubmit={handleAddPackageSubmit}>
                <div className="form-row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <Label mainLabel={"Title of the Package"} for={"title"} />
                      <Input
                        type={"text"}
                        name={"title"}
                        className={"form-control"}
                        id={"title"}
                        value={title}
                        onChange={handleChange}
                        placeholder={"Title of the Package"}
                        isRequired={true}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <Label mainLabel={"Terms"} for={"category"} />
                      <select
                        id="terms"
                        className="form-control"
                        onChange={handleChange}
                        isRequired={true}
                      >
                        <option
                          defaultValue={"Select"}
                          readOnly={true}
                          hidden={true}
                        >
                          Select
                        </option>
                        <option>Weekly</option>
                        <option>Monthly</option>
                        <option>Yearly</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-row">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <Label mainLabel={"Amount"} for={"amount"} />
                      <Input
                        type={"text"}
                        name={"amount"}
                        className={"form-control"}
                        id={"amount"}
                        value={amount}
                        isRequired={true}
                        onChange={handleChange}
                        placeholder={"Amount"}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <Label mainLabel={"Description"} for={"description"} />
                      <textarea
                        className="form-control"
                        id="description"
                        placeholder="Description"
                        col="6"
                        rows="6"
                        value={description}
                        onChange={handleChange}
                        // isRequired={true}
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="d-flex-fe">
                  {loading ? (
                    <button className="btn-primary-full mr-0" disabled>
                      {" "}
                      <LoaderComponent show={true} />{" "}
                    </button>
                  ) : (
                    <button className="btn-primary-full mr-0" type="submit">
                      {" "}
                      Save
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default FollowerSettings;
