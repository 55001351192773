import React from "react";

function Select(props) {
  return (
    <select
      id={props.id}
      className={props.className}
      onChange={props.onChange}
      required={props.isRequired || false}
    >
      <option defaultValue={"Select"} readOnly={true} hidden={true}>
        Select
      </option>
      <option>{props.state.option1}</option>
      <option>{props.state.option2}</option>
      <option>{props.state.option3}</option>
      <option>{props.state.option4}</option>
      <option>{props.state.option5}</option>
      <option>{props.state.option6}</option>
      <option>{props.state.option7}</option>
      <option>{props.state.option8}</option>
      <option>{props.state.option9}</option>
      <option>{props.state.option10}</option>
      <option>{props.state.option11}</option>
      <option>{props.state.option12}</option>
      <option>{props.state.option13}</option>
      <option>{props.state.option14}</option>
      <option>{props.state.option15}</option>
    </select>
  );
}

export default Select;
