import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import { Link, useNavigate } from "react-router-dom";
import Label from "../../common/Label";
import Input from "../../common/Input";
import { useEffect, useState } from "react";

import { post, postApi } from "../../../near/utils/localUtils";
import ModalComponent from "../../common/ModalComponent";
import LoaderComponent from "../../common/LoaderComponent";

function SignUp(props) {
  const navitator = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navitator("/");
    }
  }, []);

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  const [fname, setfirstName] = useState("");
  const [lname, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTerms, setacceptTerms] = useState(false);
  const [avatar, setavatar] = useState(null);

  const paddingForm = {
    padding: "0 4rem",
  };

  const paddingTitle = {
    padding: "3rem 0",
  };

  const buttonPadding = {
    padding: "1rem 0",
    borderRadius: "4px",
    margin: "2rem 0 1rem 0",
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id == "password") {
      setpassword(value);
    } else if (id == "fName") {
      setfirstName(value);
    } else if (id == "lName") {
      setlastName(value);
    } else if (id == "email") {
      setemail(value);
    } else if (id == "cPassword") {
      setConfirmPassword(value);
    } else if (id == "avatar") {
      setavatar(e.target.files[0]);
    } else if (id == "mobile") {
      setmobile(value);
    } else {
      setacceptTerms(e.target.checked);
    }
  };

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "",
    body: "",
  });

  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setModalInfo(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // check if any input is empty
    if (password != confirmPassword) {
      setModalInfo({
        ...modalInfo,
        show: true,
        type: "danger",
        heading: "Error",
        body: "Passwords do not match!",
      });
    } else {
      // post to server

      let formData = new FormData();
      formData.append("fname", fname);
      formData.append("lname", lname);
      formData.append("email", email);
      formData.append("mobile", mobile);
      formData.append("password", password);
      formData.append("pic", avatar);

      setLoading(true);

      postApi("front/fan_signup", formData)
        .then((res) => {
          setLoading(false);
          return res.json();
        })
        .then((data) => {
          if (data.status == "success" || data.status == "success1") {
            setModalInfo({
              ...modalInfo,
              show: true,
              type: "primary",
              heading: "Successful",
              body: "Account Created!",
            });
            signinFan();
            // setTimeout(() => {
            //   navitator("/fan/sign-in");
            // }, 1000);
          } else if (data.status == "error") {
            setModalInfo({
              ...modalInfo,
              show: true,
              type: "danger",
              heading: "Error",
              body: data.message,
            });
          }
          console.log(data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Error",
            body: "Something Went Wrong!",
          });
        });

      // signin after signup
      const signinFan = async () => {
        let formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);
        formData.append("submit", "login");

        postApi("frontapi/fan_login", formData)
          .then((res) => {
            setLoading(false);
            return res.json();
          })
          .then((data) => {
            if (data[0].status == "success") {
              sessionStorage.setItem(
                "userinfo",
                JSON.stringify(data[0].fan[0])
              );
              sessionStorage.setItem(
                "otherinfo",
                JSON.stringify({ token: data[0].token })
              );

              sessionStorage.setItem("isMember", false);
              sessionStorage.setItem("isLoggedIn", true);
              sessionStorage.setItem("isFan", true);
              navitator("/fan");
            }
          })
          .catch((err) => {
            console.log("Err: ", err);
          });
      };
    }
  };

  return (
    <div>
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />

      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />

      <main className="main-container">
        <div className="fan-container container">
          <div className="form-wrapper">
            <div className="ribbon-heading">
              <h2> Fan Sign Up </h2>
            </div>

            <h1 className="section-title">Sign Up</h1>
            <p> Enter your personal details and start your journey with us </p>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="col-6">
                  <div className="form-group">
                    <Label for={"fName"} mainLabel={"First Name"} />
                    <Input
                      isRequired={true}
                      type={"text"}
                      className={"form-control"}
                      id={"fName"}
                      placeholder={"First Name"}
                      value={fname}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <Label for={"lName"} mainLabel={"Last Name"} />
                    <Input
                      type={"text"}
                      isRequired={true}
                      className={"form-control"}
                      id={"lName"}
                      placeholder={"Last Name"}
                      value={lname}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <Label for={"email"} mainLabel={"Your Email"} />
                <Input
                  type={"email"}
                  isRequired={true}
                  className={"form-control"}
                  id={"email"}
                  placeholder={"Email"}
                  value={email}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <Label for={"email"} mainLabel={"Your Phone"} />
                <Input
                  type={"tel"}
                  isRequired={true}
                  className={"form-control"}
                  placeholder={"Your Phone"}
                  id={"mobile"}
                  value={mobile}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <Label for={"password"} mainLabel={"Password"} />
                <Input
                  type={"password"}
                  isRequired={true}
                  className={"form-control"}
                  id={"password"}
                  placeholder={"Password"}
                  value={password}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <Label for={"cPassword"} mainLabel={"Confirm Password"} />
                <Input
                  type={"password"}
                  isRequired={true}
                  className={"form-control"}
                  id={"cPassword"}
                  placeholder={"Confirm Password"}
                  value={confirmPassword}
                  onChange={handleChange}
                />
              </div>

              <div className="form-group">
                <Label for={"avatar"} mainLabel={"Avatar"} />
                <Input
                  type={"file"}
                  isRequired={true}
                  className={"form-control"}
                  id={"avatar"}
                  onChange={handleChange}
                />
              </div>

              <div className="d-flex-sb">
                <div className="form-group">
                  <div className="form-check">
                    <Input
                      type={"checkbox"}
                      className={"form-check-input"}
                      id={"terms-conditions"}
                      isChecked={acceptTerms}
                      onChange={handleChange}
                      isRequired={true}
                    />

                    <label
                      className="form-check-label"
                      htmlFor="terms-conditions"
                    >
                      <a href="#" className="link">
                        {" "}
                        Terms & Conditions{" "}
                      </a>
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                {loading ? (
                  <button
                    className="btn-lg-primary-full"
                    type="submit"
                    disabled
                  >
                    <LoaderComponent show={true} />
                  </button>
                ) : (
                  <button className="btn-lg-primary-full" type="submit">
                    Sign Up
                  </button>
                )}
              </div>
            </form>
            <div className="line-with-text">
              <p>{/* <span>or continue with</span> */}</p>
            </div>

            {/* <div className="near-login">
              <a href="#" data-toggle="modal" data-target="#nearLoginModal">
                <img src="/images/near-logo.svg" />
                Sign Up with NEAR
              </a>
            </div> */}

            <div className="sign-up-link">
              <p> Already have an account? </p> &nbsp;&nbsp;
              <Link to="/fan/sign-in" className="link">
                Sign In
              </Link>
            </div>
          </div>
          <div className="form-info-wrapper">
            <h1 className="section-title"> Already A Fan? </h1>
            <p>
              {" "}
              Log back in and enjoy music, videos, events, live streamed event
              and much more.{" "}
            </p>

            <ul className="fa-ul list">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Subscribe
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Tip
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                View Exclusive Content
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                View Pay-Per-View Live Streamed Events
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Share
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                & Keep up with your favorite Live Entertainers
              </li>
            </ul>

            <div className="d-flex">
              <Link
                to="/fan/sign-in"
                className="btn-lg-white-outline sign-up-btn"
              >
                {" "}
                Login{" "}
              </Link>
            </div>
            <img src="/images/sign_up_image.svg" alt="Sign Up Image" />
          </div>
        </div>
      </main>

      <div
        className="modal fade"
        id="nearLoginModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="nearLoginModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="form-wrapper" style={paddingForm}>
                <h1 className="section-title" style={paddingTitle}>
                  Register with
                  <img
                    src="/images/near-logo.png"
                    className="login-image"
                  />{" "}
                  NEAR
                </h1>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <Label mainLabel={"Near address"} for={"near"} />
                        <Input
                          type={"text"}
                          className={"form-control"}
                          id={"near"}
                          placeholder={"Near Address"}
                          onChange={handleChange}
                          isRequired={true}
                          name="near-address"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <Label mainLabel={"Email"} for={"email"} />
                        <Input
                          type={"email"}
                          className={"form-control"}
                          id={"email"}
                          name="email"
                          placeholder={"Email"}
                          onChange={handleChange}
                          isRequired={true}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <Label mainLabel={"Name"} for={"name"} />
                        <Input
                          type={"text"}
                          className={"form-control"}
                          id={"name"}
                          name="name"
                          placeholder={"Name"}
                          onChange={handleChange}
                          isRequired={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex-fe">
                    <button
                      className="btn-primary-full mr-0"
                      style={buttonPadding}
                      type="submit"
                    >
                      Sign Up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SignUp;
