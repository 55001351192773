import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import Input from "../../common/Input";
import Label from "../../common/Label";
import ToolTip from "../../common/ToolTip";
import {
  API_BASE_URL,
  fetchMoonPay,
  getApi,
  post,
  postApi,
} from "../../../near/utils/localUtils";
import LoaderComponent from "../../common/LoaderComponent";
import {
  getNearAccountInfo,
  sendJargon,
  sendNear,
} from "../../../near/utils/browserUtils";
import {
  calculateNearToJargon,
  jargonDecimals,
  jargonToUsd,
} from "../../../near/utils/exchangRates";
import ModalComponent from "../../common/ModalComponent";
import Events from "../Events";
import StripeComponent from "../../../stripe/Stripe";
import LoadingScreenComponent from "../../common/LoadingScreen";
import { RadioButton, RadioGroup } from "react-radio-buttons";
var QRCode = require("qrcode.react");

function MemberDetails(props) {
  const { slug } = useParams();

  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );

  const [memberDetails, setmemberDetails] = useState({});
  const [myfollowers, setmyfollowers] = useState("");
  const [myfollowerslist, setmyfollowerslist] = useState([]);

  const [myEventTypes, setmyEventTypes] = useState([]);

  // notification modal
  const [modalInfo, setModalInfo] = useState({});

  // tip
  const [jargonTipAmount, setjargonTipAmount] = useState("10");
  const [nearTipAmount, setnearTipAmount] = useState("0.5");

  const [customtip, setcustomtip] = useState("5");

  // tip conversions
  const [spentNear, setspentNear] = useState(0);
  const [spentUsd, setspentUsd] = useState(0);
  const [spentJargon, setspentJargon] = useState(0);
  const [nearExchangeRate, setnearExchangeRate] = useState("");
  const [nearToJargonRate, setnearToJargonRate] = useState(0);

  // for auth and filter
  const [userid, setuserid] = useState("");
  const [authToken, setauthToken] = useState("");

  // stripe public key from server
  const [stripePubKey, setstripePubKey] = useState("");

  // for subscription
  const [subscriptionPackageList, setSubscriptionPackageList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [packgecurrncy, setpackgecurrncy] = useState("near");
  const [nearToUsdRates, setNearToUsdRates] = useState(0);

  useEffect(async () => {
    const usdToNear = await fetchMoonPay();
    setNearToUsdRates(usdToNear);
    // fetch stripe pub key from server
    const stripeKey = await getApi("frontapi/get_stripe_key");
    setstripePubKey(stripeKey[0].stripe_public_key);

    // fan info
    const faninfo =
      JSON.parse(localStorage.getItem("userinfo")) ||
      JSON.parse(sessionStorage.getItem("userinfo"));

    const fanotherinfo =
      JSON.parse(localStorage.getItem("otherinfo")) ||
      JSON.parse(sessionStorage.getItem("otherinfo"));
    // get auth info if user is logged in
    if (faninfo && fanotherinfo) {
      setuserid(faninfo.id || faninfo.user_id);
      setauthToken(fanotherinfo.token);
    }

    const memberData = await getApi(`frontapi/musician/${slug}`);
    setSubscriptionPackageList(memberData[0].tbl_subscribe);
    setmemberDetails(memberData[0].row);
    setmyfollowers(memberData[0].followers);
    setmyfollowerslist(memberData[0].fan_followers);
    setmyEventTypes(memberData[0].typeList);

    // tip reward to referral chain
    if (window.location.href.includes("transactionHashes")) {
      const txHash = window.location.href.split("transactionHashes=")[1];
      // send reward if trx was a tip
      if (
        localStorage.getItem("trxtype") == "tip" ||
        localStorage.getItem("trxtype") == "subscription"
      ) {
        localStorage.removeItem("trxtype");
        setpaymentLoading(true);
        const refFormdata = new FormData();
        refFormdata.append("userId", faninfo.id || faninfo.user_id);
        refFormdata.append("token", fanotherinfo.token);
        refFormdata.append("fanName", `${faninfo.fname} ${faninfo.lname}`);
        refFormdata.append("memberId", memberData[0].row.id);
        refFormdata.append("memberSlug", memberData[0].row.slug);
        refFormdata.append("refCode", memberData[0].row.reffral_code);
        refFormdata.append("txHash", txHash);
        refFormdata.append("memberNearId", memberData[0].row.near_id);
        refFormdata.append(
          "accountId",
          window.walletConnection.account().accountId
        );

        // send ref chain inputs and near trx hash for reward distribution
        post(refFormdata, "near-tip")
          .then((res) => {
            setpaymentLoading(false);
            return res.json();
          })
          .then((data) => {
            console.log(data);
            if (data.status == "success" || data.status == "successful") {
              window.location.href = window.location.href.split("?")[0];
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (localStorage.getItem("trxtype") == "jargontip") {
        const jargonAmount = localStorage.getItem("jargonamount");
        setpaymentLoading(true);

        const refFormdata = new FormData();
        refFormdata.append("userId", faninfo.id || faninfo.user_id);
        refFormdata.append("fanName", `${faninfo.fname} ${faninfo.lname}`);
        refFormdata.append("token", fanotherinfo.token);
        refFormdata.append("memberId", memberData[0].row.id);
        refFormdata.append("refCode", memberData[0].row.reffral_code);
        refFormdata.append("memberSlug", memberData[0].row.slug);
        refFormdata.append("txHash", txHash);
        refFormdata.append("jargonamount", jargonAmount);
        refFormdata.append("memberNearId", memberData[0].row.near_id);
        refFormdata.append(
          "accountId",
          window.walletConnection.account().accountId
        );

        // send ref chain inputs and near trx hash for reward distribution
        post(refFormdata, "jargon-tip")
          .then((res) => {
            setpaymentLoading(false);
            return res.json();
          })
          .then((data) => {
            if (data.status == "success" || data.status == "successful") {
              window.location.href = window.location.href.split("?")[0];
            }
          })
          .catch((err) => {
            console.log(err);
          });

        localStorage.removeItem("trxtype");
        localStorage.removeItem("jargonamount");
      }
    } else if (window.location.href.includes("errorCode=")) {
      window.location.href = window.location.href.split("?")[0];

      localStorage.removeItem("tip");
      localStorage.removeItem("jargontip");
      localStorage.removeItem("jargonticket");
      localStorage.removeItem("ticket");
      localStorage.removeItem("jargonamount");
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "jargonTipAmount":
        setjargonTipAmount(value);
        updateSpentAmount(value, "jargon");
        break;
      case "nearTipAmount":
        setnearTipAmount(value);
        updateSpentAmount(value, "near");
        break;
      case "subscrption":
        setSelectedPackage(
          subscriptionPackageList.find((pckg) => pckg.id == value)
        );
        break;
      case "packgecurrncy":
        setpackgecurrncy(value);
        break;
      case "customtip":
        setcustomtip(value);
        break;

      default:
        break;
    }
  };

  // custom tip
  const handleCustomTip = async (e) => {
    e.preventDefault();
    document.getElementById("usd_tip_modal_btn").click();
  };

  // ui components
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setpaymentLoading] = useState(false); // for trx only

  const handleModalClose = () => {
    setModalInfo(false);
  };

  const showNotification = (err = "") => {
    if (err) {
      setLoading(false);

      setModalInfo({
        show: true,
        type: "danger",
        heading: "Failed",
        body: err,
      });
    } else {
      setLoading(false);
      setModalInfo({
        show: true,
        type: "primary",
        heading: "Thank you!",
        body: "Tip Transferred",
      });
      setjargonTipAmount("10");
      setnearTipAmount("0.5");
    }
    setTimeout(() => {
      handleModalClose();
    }, 1000);
  };

  // send Tip handlers
  const handleNearTrx = async (e, trxtype, amount) => {
    // for tip and jargon purchase
    localStorage.setItem("trxtype", trxtype || "tip");
    e.preventDefault();
    setLoading(true);
    sendNear(window.near.config.masterAccount, amount || nearTipAmount);
  };

  const handleJargonTrx = async (e) => {
    e.preventDefault();
    localStorage.setItem("trxtype", "jargontip");
    localStorage.setItem("jargonamount", jargonTipAmount * jargonDecimals);

    setLoading(true);
    sendJargon(
      window.near.config.masterAccount,
      jargonTipAmount * jargonDecimals
    );
  };

  // closing stripemodal
  const handlStripeModalClose = () => {
    document.getElementById("stipe-modal-close-btn").click();
    document.getElementById("strip-close-btn").click();
  };

  // func to update spent near-usd-jargon conversion
  const updateSpentAmount = (amount, currency) => {
    switch (currency) {
      case "usd":
        setspentJargon((amount * (1 / jargonToUsd)).toFixed(3));
        setspentNear((amount * (1 / nearExchangeRate)).toFixed(3));
        setspentUsd(amount);
        break;
      case "near":
        setspentJargon((amount * nearToJargonRate).toFixed(3));
        setspentNear(amount);
        setspentUsd((amount * nearExchangeRate).toFixed(3));
        break;
      case "jargon":
        setspentJargon(amount);
        setspentNear((amount * (1 / nearToJargonRate)).toFixed(3));
        setspentUsd((amount * jargonToUsd).toFixed(3));
        break;
    }
  };

  // for tip conversion to currencies
  const handlGetNearAccountInfo = async (e) => {
    const { id } = e.target;
    const nearInfo = await getNearAccountInfo();
    const usdToNear = await fetchMoonPay();
    const liveNearToJargonRate = await calculateNearToJargon(usdToNear);
    setnearExchangeRate(usdToNear);
    setnearToJargonRate(liveNearToJargonRate);
    if (liveNearToJargonRate) {
      switch (id) {
        case "near":
          updateSpentAmount(nearTipAmount, id);
          break;
        case "jargon":
          updateSpentAmount(jargonTipAmount, id);
          break;
      }
    }
  };

  // follow/unfollow member
  const handleFollow = (e) => {
    if (!isLoggedIn || !isFan) {
      alert("Please Login first!");
      return;
    }

    const formData = new FormData();
    formData.append("musician_id", memberDetails.id);
    // auth
    formData.append("user_id", userid);
    formData.append("token", authToken);

    //dev.tipjarlove.com/frontapi/follow
    let api = "frontapi/follow";
    if (e.target.innerHTML.includes("Unfollow")) {
      api = "frontapi/unfollow";
    }

    postApi(api, formData)
      .then((res) => res.json())
      .then((data) => {
        setmyfollowerslist(data[0].followers);
      })
      .catch((err) => {
        console.log("Err: ", err);
      });
  };

  //API

  if (memberDetails.id) {
    if (paymentLoading) {
      return <LoadingScreenComponent />;
    } else {
      return (
        <div>
          <ModalComponent
            modalInfo={modalInfo}
            handleClose={handleModalClose}
          />
          <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
          <div className="member-profile-wrapper">
            <div className="profile">
              <img
                className="cover-image"
                src="/images/background_1.png"
                alt=""
              />
              <div className="container">
                <div className="profile-wrapper-mobile">
                  <div className="">
                    {memberDetails.avatar ? (
                      <img
                        className="avatar"
                        src={
                          memberDetails.avatar.includes("http")
                            ? `${memberDetails.avatar}`
                            : `${API_BASE_URL}/${memberDetails.avatar}`
                        }
                        alt=""
                      />
                    ) : null}
                    <h1 className="name">{memberDetails.name} </h1>
                    <p className="category-type">{memberDetails.category}</p>
                    <p className="category-type">
                      {" "}
                      Musician / Band {memberDetails.cid}
                    </p>
                    <p className="supporters">
                      {" "}
                      {myfollowerslist.length} <span> Followers </span>
                    </p>
                    <div className="amount pt-lg-3">
                      {/* <img src="/images/member-qr-reader.png" alt="" /> */}
                      <QRCode value={window.location.href} />
                      <p>
                        {" "}
                        Scan the QR code to share {memberDetails.name}'s profile{" "}
                      </p>
                      {/* <p> Scan QR code to leave a Quick $5 Tip </p> */}
                    </div>
                  </div>

                  <div className="pt-lg-3 action-items">
                    {memberDetails.Stripe_account_status == 0 ? (
                      <a
                        className="btn-secondary-full"
                        data-toggle="modal"
                        data-backdrop="static"
                        onClick={() => {
                          alert("This musician does not accept USD payments!");
                        }}
                      >
                        {" "}
                        Quick Tip ($5)
                      </a>
                    ) : (
                      <a
                        className="btn-secondary-full"
                        data-toggle="modal"
                        id="usd_tip_modal_btn"
                        data-target="#tips_modal"
                        data-backdrop="static"
                      >
                        {" "}
                        Quick Tip ($5)
                      </a>
                    )}

                    {!memberDetails.near_id ? (
                      <p>NEAR ID NOT FOUND!</p>
                    ) : (
                      <div>
                        {window.walletConnection.isSignedIn() ? (
                          <div>
                            <a
                              className="btn-secondary-full"
                              data-toggle="modal"
                              data-target="#near_tips_modal"
                              data-backdrop="static"
                              id="near"
                              onClick={handlGetNearAccountInfo}
                            >
                              {" "}
                              Quick Tip (0.5 NEAR)
                            </a>

                            <a
                              className="btn-secondary-full"
                              data-toggle="modal"
                              data-target="#jargon_tips_modal"
                              data-backdrop="static"
                              id="jargon"
                              onClick={handlGetNearAccountInfo}
                            >
                              {" "}
                              Quick Tip (10 Jargon)
                            </a>
                          </div>
                        ) : (
                          <p className="text-danger">
                            Please Connect Your NEAR Account to Send NEAR/Jargon
                            as Tip!
                          </p>
                        )}
                      </div>
                    )}
                    <a className="btn-primary-full" onClick={handleFollow}>
                      {" "}
                      {myfollowerslist.find((flwr) => flwr.fid == userid)
                        ? "Unfollow"
                        : "Follow"}
                      {/* Follow{" "} */}
                    </a>

                    <a
                      className="btn-secondary-full"
                      data-toggle="modal"
                      data-target="#subscription"
                      data-backdrop="static"
                      id="subscriptionbtn"
                      // onClick={handlGetNearAccountInfo}
                    >
                      {" "}
                      Become a Fan
                    </a>
                    <div className="form-group">
                      <form onSubmit={handleCustomTip}>
                        <Input
                          type={"number"}
                          id={"customtip"}
                          onChange={handleChange}
                          min={1}
                          value={customtip}
                          className={"form-control"}
                          placeholder={"Custom Tip"}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="profile-wrapper">
                  <div className="">
                    {memberDetails.avatar ? (
                      <img
                        className="avatar"
                        src={
                          memberDetails.avatar.includes("http")
                            ? `${memberDetails.avatar}`
                            : `${API_BASE_URL}/${memberDetails.avatar}`
                        }
                        alt=""
                      />
                    ) : null}
                    <h1 className="name">{memberDetails.name} </h1>
                    <p className="category-type">{memberDetails.category}</p>
                    <p className="supporters">
                      {" "}
                      {myfollowerslist.length} <span> Followers </span>
                    </p>
                  </div>
                  <div className="amount pt-lg-3">
                    {/* <img src="/images/member-qr-reader.png" alt="" /> */}
                    <QRCode value={window.location.href} />
                    <p>
                      {" "}
                      Scan QR code to share {memberDetails.name}'s profile{" "}
                    </p>
                    <a
                      className="btn-secondary-full"
                      data-toggle="modal"
                      data-target="#tips_modal"
                      data-backdrop="static"
                    >
                      {" "}
                      Quick Tip ($5)
                    </a>
                    {!memberDetails.near_id ? (
                      <p>NEAR ID NOT FOUND!</p>
                    ) : (
                      <div>
                        {window.walletConnection.isSignedIn() ? (
                          <div>
                            <a
                              className="btn-secondary-full"
                              data-toggle="modal"
                              data-target="#near_tips_modal"
                              data-backdrop="static"
                              id="near"
                              onClick={handlGetNearAccountInfo}
                            >
                              {" "}
                              Quick Tip (0.5 NEAR)
                            </a>

                            <a
                              className="btn-secondary-full"
                              data-toggle="modal"
                              data-target="#jargon_tips_modal"
                              data-backdrop="static"
                              id="jargon"
                              onClick={handlGetNearAccountInfo}
                            >
                              {" "}
                              Quick Tip (10 Jargon)
                            </a>
                          </div>
                        ) : (
                          <p className="text-danger">
                            Please Connect Your NEAR Account to Send NEAR/Jargon
                            as Tip!
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="pt-lg-3 action-items">
                    <a className="btn-primary-full" onClick={handleFollow}>
                      {" "}
                      {myfollowerslist.find((flwr) => flwr.fid == userid)
                        ? "Unfollow"
                        : "Follow"}
                      {/* Follow{" "} */}
                    </a>
                    <a
                      className="btn-primary-outline"
                      // className="btn-secondary-full"
                      data-toggle="modal"
                      data-target="#subscription"
                      data-backdrop="static"
                      id="subscriptionbtn"
                    >
                      {" "}
                      Become a Fan
                    </a>
                    <div className="form-group">
                      <form onSubmit={handleCustomTip}>
                        <Input
                          type={"number"}
                          id={"customtip"}
                          min={1}
                          value={customtip}
                          onChange={handleChange}
                          className={"form-control"}
                          placeholder={"Custom Tip"}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />

            {/* <div className="container">
              <hr />
              {memberDetails.name ? (
                <Events listMapButton={false} memberName={memberDetails.name} />
              ) : null}
            </div> */}
          </div>

          {/* modals  */}

          <div
            className="modal fade"
            id="tips_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close p-lg-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="stipe-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>

                <div className="modal-wrapper">
                  {memberDetails.id && stripePubKey.length ? (
                    <StripeComponent
                      memberDetails={memberDetails}
                      amount={Number(customtip) || 5}
                      handlStripeModalClose={handlStripeModalClose}
                      stripePubKey={stripePubKey}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* for near tip */}

          <div
            className="modal fade"
            id="near_tips_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close p-lg-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-wrapper">
                  <h2 className="heading">
                    Payment Information for a tip for{" "}
                    <span>{/* {memberinfo.fullname} */}</span>
                  </h2>

                  {/* tip form */}
                  <form onSubmit={handleNearTrx}>
                    <div className="form-group mt-lg-5">
                      <Label mainLabel={"Tip Amount"} for={"nearTipAmount"} />
                      <Input
                        type={"number"}
                        step={0.00001}
                        name={"nearTipAmount"}
                        className={"form-control"}
                        id={"nearTipAmount"}
                        onChange={handleChange}
                        min={0.0001}
                        value={nearTipAmount}
                        isRequired={true}
                      />
                    </div>
                    <h3>
                      Tip amount: <br />${spentUsd}
                      <br /> {spentNear} NEAR <br />
                      {spentJargon} Jargon{" "}
                    </h3>

                    {loading ? (
                      <div className="mt-lg-5 d-flex-fe">
                        <button className="btn-success-full mr-0">
                          {" "}
                          <LoaderComponent show={true} />
                        </button>
                      </div>
                    ) : (
                      <div className="mt-lg-5 d-flex-fe">
                        <button className="btn-success-full mr-0" type="submit">
                          {" "}
                          Pay Now{" "}
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* for jargon tip */}
          <div
            className="modal fade"
            id="jargon_tips_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close p-lg-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-wrapper">
                  <h2 className="heading">
                    Payment Information for a tip for{" "}
                    <span>{/* {memberinfo.fullname} */}</span>
                  </h2>

                  {/* tip form */}
                  <form onSubmit={handleJargonTrx}>
                    <div className="form-group mt-lg-5">
                      <Label mainLabel={"Tip Amount"} for={"jargonTipAmount"} />
                      <Input
                        type={"number"}
                        step={0.00001}
                        name={"jargonTipAmount"}
                        className={"form-control"}
                        id={"jargonTipAmount"}
                        onChange={handleChange}
                        min={0.0001}
                        value={jargonTipAmount}
                        isRequired={true}
                      />
                    </div>
                    <h3>
                      Tip amount: <br />${spentUsd}
                      <br /> {spentNear} NEAR <br />
                      {spentJargon} Jargon{" "}
                    </h3>
                    {/* <button className="btn-success-full mr-0" type="submit">
                      {" "}
                      Pay Now{" "}
                    </button> */}
                    {loading ? (
                      <div className="mt-lg-5 d-flex-fe">
                        <button className="btn-success-full mr-0">
                          {" "}
                          <LoaderComponent show={true} />
                        </button>
                      </div>
                    ) : (
                      <div className="mt-lg-5 d-flex-fe">
                        <button className="btn-success-full mr-0" type="submit">
                          {" "}
                          Pay Now{" "}
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* for Subscription */}

          <div
            className="modal fade"
            id="subscription"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <button
                  type="button"
                  className="close p-lg-3 text-right"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="strip-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                {/* <form onSubmit={}></form> */}

                <div className="modal-wrapper pt-0">
                  <h1 className="section-title"> Subscription Menu </h1>
                  <div className="form-row">
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="subscrption" className="text-black-50">
                          {" "}
                          Package{" "}
                        </label>
                        <select
                          id="subscrption"
                          className="form-control"
                          onChange={handleChange}
                          required
                        >
                          <option
                            defaultValue={"Select"}
                            readOnly={true}
                            hidden={true}
                          >
                            Select
                          </option>
                          {subscriptionPackageList.map((pckg) => (
                            <option value={pckg.id} key={pckg.id}>
                              {pckg.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <label className="text-black-50"> Duration </label>
                        <select
                          className="form-control"
                          onChange={handleChange}
                          disabled
                        >
                          <option defaultValue>
                            {selectedPackage.duration}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="reqsongcurrency"
                          className="text-black-50"
                        >
                          {" "}
                          Currency{" "}
                        </label>
                        <RadioGroup
                          value={setpackgecurrncy}
                          onChange={(value) => {
                            handleChange({
                              target: { id: "packgecurrncy", value },
                            });
                          }}
                          horizontal
                        >
                          {!memberDetails.near_id ? (
                            <label>NEAR payment unavailable!</label>
                          ) : (
                            <RadioButton pointColor={"#1e989e"} value="near">
                              NEAR
                            </RadioButton>
                          )}

                          {memberDetails.Stripe_account_status == 0 ? (
                            <label>USD payment unavailable!</label>
                          ) : (
                            <RadioButton pointColor={"#1e989e"} value="usd">
                              USD
                            </RadioButton>
                          )}
                        </RadioGroup>
                      </div>
                    </div>
                  </div>

                  {selectedPackage.amount ? (
                    <div className="form-group">
                      {packgecurrncy == "near" ? (
                        <div>
                          <h1>
                            Buy this song for just{" "}
                            {Math.round(
                              (selectedPackage.amount / nearToUsdRates) * 100
                            ) / 100}
                            {} NEAR
                          </h1>
                          {loading ? (
                            <button
                              className="btn-lg-primary-full mr-0"
                              disabled
                            >
                              <LoaderComponent />
                            </button>
                          ) : (
                            <button
                              className="btn-lg-primary-full mr-0"
                              onClick={(e) => {
                                handleNearTrx(
                                  e,
                                  "subscription",
                                  (
                                    Math.round(
                                      (selectedPackage.amount /
                                        nearToUsdRates) *
                                        100
                                    ) / 100
                                  ).toString()
                                );
                              }}
                            >
                              Subscribe
                            </button>
                          )}
                        </div>
                      ) : (
                        <StripeComponent
                          memberDetails={{
                            id: memberDetails.id,
                            name: `${memberDetails.fname} ${memberDetails.lname}`,
                          }}
                          handlStripeModalClose={handlStripeModalClose}
                          stripePubKey={stripePubKey}
                          // 10 usd is placeholder
                          amount={Number(selectedPackage.amount)}
                          btntext={"Subscribe"}
                          trxData={{
                            page: "subscribe",
                            id: selectedPackage.playId,
                          }}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      );
    }
  } else {
    return <LoadingScreenComponent text="Loading..." />;
  }
}

export default MemberDetails;
