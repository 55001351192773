import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../include/Navbar";
import Label from "../../common/Label";
import Input from "../../common/Input";
import Footer from "../../include/Footer";
import { post, postApi } from "../../../near/utils/localUtils";
import LoaderComponent from "../../common/LoaderComponent";
import ModalComponent from "../../common/ModalComponent";
import { GoogleLogin } from "react-google-login";
import {  loginNearFunctional } from "../../../near/utils/browserUtils";


function SignIn(props) {
  const navigate = useNavigate();

  const rerouteNEARlogin = async ()=>{
    if (window.walletConnection.isSignedIn()) {

      // check if near user
      if (sessionStorage.getItem("userinfo")) {

        const {fannearid, redirecting} = JSON.parse(sessionStorage.getItem("userinfo"))
        // if near account is logged in after redirecting to near site
        // log-in the fan near acc
        if(fannearid && redirecting && !JSON.parse(sessionStorage.getItem("isLoggedIn")) ){     
          const formData= new FormData()
          formData.append("near_id", window.walletConnection.account().accountId)
          postApi("frontapi/fan_near_login", formData).then(res=>res.json()).then(data=>{
            console.log(data);

           // ask for info if near id is not already registed
           if(data[0].status =="error"&& data[0].message == "not found"){
              document.getElementById("get-near-reg-info").click()
              return
            } 
            else{
              const {token, user_id} = data[0]
              sessionStorage.setItem(
                "userinfo",
                JSON.stringify({
                  id:user_id,
                  near_id: window.walletConnection.account().accountId,
                  fannearid: true,
                })
              );
              sessionStorage.setItem("otherinfo", JSON.stringify({
                token
              }))
              sessionStorage.setItem("isMember", false);
              sessionStorage.setItem("isLoggedIn", true);
              sessionStorage.setItem("isFan", true);
            }
            setModalInfo({
              ...modalInfo,
              show: true,
              type: "primary",
              heading: "Logged In",
              body: "Welcome Back! We Missed You",
            });
             setTimeout(() => {
                handleModalClose();
                navigate("/fan");
            }, 1000);

          }).catch(err=>{
            console.log(err);
          })
  
  
  
          // to be fixed
  
          // if(!res.error){
          //   const {givenName, familyName,email,imageUrl} = res.profileObj
      
          //   const formData = new FormData()
          //   formData.append("fname", givenName)
          //   formData.append("lname", familyName)
          //   formData.append("email", email)
          //   formData.append("mobile", " ")
          //   // fname, lname, email and phone
          //   // Fan Login with Google		Function	https://dev.tipjarlove.com/frontapi/fan_googlelogin
          //   postApi("frontapi/fan_googlelogin", formData).then(res=>{
          //     return res.json()
          //   }).then(data=>{
          //     if(data[0].status =="success"){
          //       sessionStorage.setItem("userinfo", JSON.stringify({
          //       ...data[0],
          //         fname:givenName, lname:familyName, email, avatar:imageUrl, oAuth:"google"                        
          //       }));
          //       sessionStorage.setItem("otherinfo", JSON.stringify({
          //         token:data[0].token
          //       }));
          //       sessionStorage.setItem("isMember", false);
          //       sessionStorage.setItem("isLoggedIn", true);
          //       sessionStorage.setItem("isFan", true);
      
          //       setModalInfo({
          //         ...modalInfo,
          //         show: true,
          //         type: "primary",
          //         heading: "Logged In",
          //         body: "Welcome Back! We Missed You",
          //       });
          //       setTimeout(() => {
          //         handleModalClose();
          //         navigate("/fan");
          //       }, 1000);
          //     } else {
          //       setModalInfo({
          //         ...modalInfo,
          //         show: true,
          //         type: "danger",
          //         heading: "Failed",
          //         body: "Invalid Credentials",
          //       });
          //       setTimeout(() => {
          //         handleModalClose();
          //       }, 1000);
          //     }
          //   }).catch(err=>{
          //     console.log(err);
          //     setModalInfo({
          //       ...modalInfo,
          //       show: true,
          //       type: "danger",
          //       heading: "Failed",
          //       body: "Invalid Credentials",
          //     });
          //     setTimeout(() => {
          //       handleModalClose();
          //     }, 1000);
          //   })    
          // }
      
        }


      }
      // if near account is connected but not user not logged-in (or just connected the account)
      
    }
  }



  useEffect(async () => {

    
    if (isLoggedIn ) {
      navigate("/");
    }
    rerouteNEARlogin()
    

    // if (window.walletConnection.isSignedIn()) {
    //   if (sessionStorage.getItem("userinfo")) {
    //     if (JSON.parse(sessionStorage.getItem("userinfo")).fannearid) {
    //       sessionStorage.setItem(
    //         "userinfo",
    //         JSON.stringify({
    //           near_id: await window.walletConnection.account().accountId,
    //           fannearid: true,
    //         })
    //       );
    //       sessionStorage.setItem("isMember", false);
    //       sessionStorage.setItem("isLoggedIn", true);
    //       sessionStorage.setItem("isFan", true);

    //       setModalInfo({
    //         ...modalInfo,
    //         show: true,
    //         type: "primary",
    //         heading: "Logged In",
    //         body: "Welcome Back! We Missed You",
    //       });
    //       setTimeout(() => {
    //         handleModalClose();
    //         navigate("/fan");
    //       }, 1000);
    //     }
    //   }
    // }
  }, []);



  const [isMember, setisMember] = useState(
    JSON.parse(localStorage.getItem("isMember")) ||
      JSON.parse(sessionStorage.getItem("isMember")) ||
      false
  );
  const [isFan, setisFan] = useState(
    JSON.parse(localStorage.getItem("isFan")) ||
      JSON.parse(sessionStorage.getItem("isFan")) ||
      false
  );
  const [isLoggedIn, setisLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn")) ||
      JSON.parse(sessionStorage.getItem("isLoggedIn")) ||
      false
  );
  const [email, setemail] = useState("");
  const [emailNear, setemailNear] = useState("");
  const [password, setpassword] = useState("");
  const [firstname, setfirstname] = useState("");
  const [firstnameNear, setfirstnameNear] = useState("");
  const [lastnameNear, setlastnameNear] = useState("");
  const [lastname, setlastname] = useState("");
  const [nearAddress, setnearAddress] = useState("");
  const [rememberMe, setrememberMe] = useState(false)

  const handleChange = (e) => {
    if (e.target.id == "email") {
      setemail(e.target.value);
    } else if (e.target.id == "password") {
      setpassword(e.target.value);
    } else if (e.target.id == "firstName") {
      setfirstname(e.target.value);
    } else if (e.target.id == "lastName") {
      setlastname(e.target.value);
    } else if (e.target.id == "firstNamenear") {
      setfirstnameNear(e.target.value);
    } else if (e.target.id == "lastNamenear") {
      setlastnameNear(e.target.value);
    } else if (e.target.id == "emailnear") {
      setemailNear(e.target.value);
    } else if (e.target.id == "near") {
      setnearAddress(e.target.value);
    } else if (e.target.id == "rememberMe") {
      setrememberMe(e.target.checked);
    }
  };

  // for ui components
  const [modalInfo, setModalInfo] = useState({
    show: false,
    type: "primary",
    heading: "Hey!",
    body: "Welcome Body",
  });

  const [loading, setLoading] = useState(false);
  const [loadingNear, setloadingNear] = useState(false);

  const handleModalClose = () => {
    setModalInfo({
      ...modalInfo,
      show: false,
    });
  };

  const paddingForm = {
    padding: "0 4rem",
  };

  const paddingTitle = {
    padding: "3rem 0",
  };

  const buttonPadding = {
    padding: "1rem 0",
    borderRadius: "4px",
    margin: "2rem 0 1rem 0",
  };

  const handleSigninWithNear = async () => {
    // e.preventDefault();
    loginNearFunctional()
    // localStorage.setItem(
    //   "userinfo",
    //   JSON.stringify({
    //     fannearid: true,
    //   })
    // );
    sessionStorage.setItem(
      "userinfo",
      JSON.stringify({
        fannearid: true,
        redirecting:true
      })
    );
  };


  const handleRegisterWithNear = async(e)=>{
    e.preventDefault()
    const formData= new FormData()
    formData.append("fname", firstnameNear)
    formData.append("lname", lastnameNear)
    formData.append("email", emailNear)
    formData.append("near_id", window.walletConnection.account().accountId)
    setloadingNear(true)
    
    postApi("frontapi/fan_near_signup", formData).then(res=>{
      setloadingNear(false)
      return res.json()
    }).then(async data=>{

      if(data[0].status =="success"){
        sessionStorage.setItem("userinfo", JSON.stringify({
        ...data[0],
          fannearid: true,
          fname:firstname, lname:lastname, email, avatar:"", 
          near_id: await window.walletConnection.account().accountId,
        }));
        sessionStorage.setItem("otherinfo", JSON.stringify({
          token:data[0].token
        }));
        sessionStorage.setItem("isMember", false);
        sessionStorage.setItem("isLoggedIn", true);
        sessionStorage.setItem("isFan", true);

        setModalInfo({
          ...modalInfo,
          show: true,
          type: "primary",
          heading: "Logged In",
          body: "Welcome Back! We Missed You",
        });
        setTimeout(() => {
          handleModalClose();
          navigate("/fan");
        }, 1000);
      } else {
        setModalInfo({
          ...modalInfo,
          show: true,
          type: "danger",
          heading: "Failed",
          body: "Invalid Credentials",
        });
        setTimeout(() => {
          handleModalClose();
        }, 1000);
      }
    }).catch(err=>{
      console.log(err);
      setModalInfo({
        ...modalInfo,
        show: true,
        type: "danger",
        heading: "Failed",
        body: "Invalid Credentials",
      });
      setTimeout(() => {
        handleModalClose();
      }, 1000);
    })    

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("submit", "login");

    postApi("frontapi/fan_login", formData)
      .then((res) => {
        setLoading(false);
        return res.json();
      })
      .then((data) => {
        if (data[0].status == "success") {

          localStorage.clear();
          sessionStorage.clear();

          //  request otp if enabled
          if(!data[0].fan){
            sessionStorage.setItem("otpemail", email)
            sessionStorage.setItem("isFan", true)
            if(rememberMe){
              localStorage.setItem("otpemail", email)
              localStorage.setItem("isFan", true)
            }
            navigate("/otp-verification");
            return
          }

          sessionStorage.setItem("userinfo", JSON.stringify(data[0].fan[0]));
          sessionStorage.setItem("otherinfo", JSON.stringify({token:data[0].token}));
          sessionStorage.setItem("isFan", true);     
          sessionStorage.setItem("isMember", false);
          sessionStorage.setItem("isLoggedIn", true);
          
          if(rememberMe){
            localStorage.setItem("userinfo", JSON.stringify(data[0].fan[0]));
            localStorage.setItem("otherinfo", JSON.stringify({token:data[0].token}));
            localStorage.setItem("isFan", true);
            localStorage.setItem("isMember", false);
            localStorage.setItem("isLoggedIn", true);
          }
         
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "primary",
            heading: "Logged In",
            body: "Welcome Back! We Missed You",
          });
          setTimeout(() => {
            handleModalClose();
            navigate("/fan");
          }, 1000);
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Failed",
            body: "Invalid Credentials",
          });
          setTimeout(() => {
            handleModalClose();
          }, 1000);
        }
      })
      .catch((err) => {
        setModalInfo({
          ...modalInfo,
          show: true,
          type: "danger",
          heading: "Failed",
          body: "Invalid Credentials",
        });
        setTimeout(() => {
          handleModalClose();
        }, 1000);
        console.log("Err: ", err);
      });
  };

  // oAuth
  const responseGoogle = (res) => {
    
    if(!res.error){
      const {givenName, familyName,email,imageUrl} = res.profileObj

      const formData = new FormData()
      formData.append("fname", givenName)
      formData.append("lname", familyName)
      formData.append("email", email)
      formData.append("mobile", " ")
      // fname, lname, email and phone
      // Fan Login with Google		Function	https://dev.tipjarlove.com/frontapi/fan_googlelogin
      postApi("frontapi/fan_googlelogin", formData).then(res=>{
        return res.json()
      }).then(data=>{
        if(data[0].status =="success"){
          sessionStorage.setItem("userinfo", JSON.stringify({
          ...data[0],
            fname:givenName, lname:familyName, email, avatar:imageUrl, oAuth:"google"                        
          }));
          sessionStorage.setItem("otherinfo", JSON.stringify({
            token:data[0].token
          }));
          sessionStorage.setItem("isMember", false);
          sessionStorage.setItem("isLoggedIn", true);
          sessionStorage.setItem("isFan", true);

          setModalInfo({
            ...modalInfo,
            show: true,
            type: "primary",
            heading: "Logged In",
            body: "Welcome Back! We Missed You",
          });
          setTimeout(() => {
            handleModalClose();
            navigate("/fan");
          }, 1000);
        } else {
          setModalInfo({
            ...modalInfo,
            show: true,
            type: "danger",
            heading: "Failed",
            body: "Invalid Credentials",
          });
          setTimeout(() => {
            handleModalClose();
          }, 1000);
        }
      }).catch(err=>{
        console.log(err);
        setModalInfo({
          ...modalInfo,
          show: true,
          type: "danger",
          heading: "Failed",
          body: "Invalid Credentials",
        });
        setTimeout(() => {
          handleModalClose();
        }, 1000);
      })    
    }


  };

  return (
    <div>
      <ModalComponent modalInfo={modalInfo} handleClose={handleModalClose} />
      <Navbar isLoggedIn={isLoggedIn} isMember={isMember} isFan={isFan} />
      <main className="main-container">
        <div className="fan-container container">
          <div className="form-wrapper">
            <div className="ribbon-heading">
              <h2> Fan Login </h2>
            </div>

            <h1 className="section-title">Sign In</h1>
            <p> Welcome, we missed you! </p>
            <form
              onSubmit={handleSubmit}

              // action="https://dev.tipjarlove.com/front/fan_login"
              // method="POST"
              // enctype="multipart/form-data"
            >
              <div className="form-group">
                <Label mainLabel={"Email address"} for={"email"} />
                <Input
                  type={"email"}
                  className={"form-control"}
                  id={"email"}
                  placeholder={"Email"}
                  value={email}
                  onChange={handleChange}
                  isRequired={true}
                  name="email"
                />
              </div>

              <div className="form-group">
                <Label mainLabel={"Password"} for={"password"} />
                <Input
                  type={"password"}
                  className={"form-control"}
                  id={"password"}
                  name="password"
                  placeholder={"Password"}
                  value={password}
                  onChange={handleChange}
                  isRequired={true}
                />
              </div>

              <div className="d-flex-sb">
                <div className="form-group">
                  <div className="form-check">
                    <Input
                      type={"checkbox"}
                      className={"form-check-input"}
                      id={"rememberMe"}
                      onChange={handleChange}
                      checked = {rememberMe}
                    />
                    <Label
                      className={"form-check-label"}
                      for={"rememberMe"}
                      mainLabel={"Remember Me"}
                    />
                  </div>
                </div>

                <Link to="/forgot-password" className="link" onClick={() => {
                    sessionStorage.setItem("resetpwdUserType", "fan");                  }}>
                  Forgot Password?
                </Link>
              </div>
              <div className="d-flex">
                {loading ? (
                  <button
                    className="btn-lg-primary-full"
                    type="submit"
                    disabled
                  >
                    <LoaderComponent show={true} />{" "}
                  </button>
                ) : (
                  <button
                    className="btn-lg-primary-full"
                    type="submit"
                    name="submit"
                    value="login"
                  >
                    Sign In
                  </button>
                )}
              </div>
            </form>

            <div className="line-with-text">
              <p>
                <span>or continue with</span>
              </p>
            </div>

            <div className="google-login">
              <GoogleLogin
                clientId=
                // "473312171246-dp1pdrp6to8if8guv8tficu78si2rspk.apps.googleusercontent.com"
                "971061989682-98kh2cub0o86tho64484dq61juruc77v.apps.googleusercontent.com"
                buttonText="Log in with Google!"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={"single_host_origin"}
              />
            </div>
            <div className="near-login">
              <a href="#" data-toggle="modal" 
              // data-target="#nearLoginModal" 
              onClick={handleSigninWithNear}>
                <img src="/images/near-logo.svg" />
                Log in with NEAR
              </a>
            </div>
              <div style={{display:"none"}}>
              <a href="#" data-toggle="modal" 
              data-target="#nearRegisterModal" 
              id="get-near-reg-info"
              >
              </a>
              </div>


{/* register with NEAR modal */}
<div
        className="modal fade"
        id="nearRegisterModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="nearRegisterModalLabel"
        aria-hidden="true"
        

      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <button
              type="button"
              className="close p-lg-3 text-right"
              data-dismiss="modal"
              aria-label="Close"
              id="near-reg-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body">
              <div className="form-wrapper" style={paddingForm}>
                <h1 className="section-title" style={paddingTitle}>
                  Fill this form to Register with {"   "}
                  <img
                    src="/images/near-logo.svg"
                    className="login-image"
                  />{" "}
                  {/* NEAR */}
                </h1>
                <form onSubmit={handleRegisterWithNear}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <Label mainLabel={"Near ID"} for={"near"} />
                        <Input
                          type={"text"}
                          className={"form-control"}
                          id={"near"}
                          value={window.walletConnection.account().accountId}
                          
                          name="near-address"
                        />
                      </div>
                    </div>
                    </div>
                    <div className="form-row mt-lg-5">
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"First Name"} for={"firstName"} />
                          <Input
                            type={"text"}
                            name={"firstName"}
                            className={"form-control"}
                            id={"firstNamenear"}
                            placeholder={"First Name"}
                            // defaultValue={"Austin"}
                            value={firstnameNear}
                            onChange={handleChange}
                            isRequired={true}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Label mainLabel={"Last Name"} for={"lastName"} />
                          <Input
                            type={"text"}
                            name={"lastName"}
                            className={"form-control"}
                            id={"lastNamenear"}
                            placeholder={"Last Name"}
                            // defaultValue={"Axtell"}
                            value={lastnameNear}
                            onChange={handleChange}
                            isRequired={true}
                          />
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <Label mainLabel={"Email"} for={"email"} />
                        <Input
                          type={"email"}
                          className={"form-control"}
                          id={"emailnear"}
                          name="email"
                          placeholder={"Email"}
                          onChange={handleChange}
                          value={emailNear}
                          isRequired={true}
                        />
                      </div>   
                      </div>   
                  </div>


                  <div className="d-flex-fe">
                  {loadingNear ? (
                  <button
                  className="btn-primary-full mr-0"
                  style={buttonPadding}
                  disabled
                >
                  <LoaderComponent show={true} />
                </button>
                  
                ) : (
                  <button
                      className="btn-primary-full mr-0"
                      style={buttonPadding}
                      type="submit"
                    >
                      Sign Up
                    </button>
                )}
                    
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

            
            <div className="sign-up-link">
              <p> Don’t have an account? </p> &nbsp;&nbsp;
              <Link to="/fan/sign-up" className="link">
                Sign Up
              </Link>
            </div>
          </div>
          <div className="form-info-wrapper">
            <h1 className="section-title">Sign Up Today and Show Some Love</h1>

            <ul className="fa-ul list">
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Subscribe
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Tip
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                View Exclusive Content
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                View Pay-Per-View Live Streamed Events
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                Share
              </li>
              <li>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>{" "}
                & Keep up with your favorite Live Entertainers
              </li>
            </ul>

            <div className="d-flex">
              <Link
                to="/fan/sign-up"
                className="btn-lg-white-outline sign-up-btn"
              >
                Register Now
              </Link>
            </div>
            {/* <div className="d-flex">
              <a
                href="#" data-toggle="modal" data-target="#nearRegisterModal"
                className="btn-lg-white-outline sign-up-btn"
              >
                <img src="/images/near-logo.svg" />
              
               Register with NEAR
  
              </a>
            </div> */}
            
          </div>
        </div>




      </main>
      <Footer />
        </div>
  );
}

export default SignIn;
