import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getApi } from "../../near/utils/localUtils";
import BlankComponent from "../common/BlankCompo";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, endpoint: "" };
    // this.handleClose = this.handleClose
  }

  handleClose = () => {
    this.setState({ ...this.state, showModal: false });
  };

  showCopyrights = () => {
    this.setState({
      ...this.state,
      showModal: true,
      endpoint: "frontapi/cms/copyright",
    });
  };

  showPrivacyPolicy = () => {
    this.setState({
      ...this.state,
      showModal: true,
      endpoint: "frontapi/cms/privacy-policy",
    });
  };

  showTnC = () => {
    this.setState({
      ...this.state,
      showModal: true,
      endpoint: "frontapi/cms/terms-conditions",
    });
  };

  render() {
    let isLoggedIn = this.props.isLoggedIn;
    let isMember = this.props.isMember;
    let isFan = this.props.isFan;
    let link;

    if (isFan && isLoggedIn && !isMember) {
      link = "/fan/faqs";
    } else if (isMember && isLoggedIn && !isFan) {
      link = "/member/faqs";
    } else {
      link = "/faqs";
    }

    return (
      <footer>
        <BlankComponent
          show={this.state.showModal}
          handleClose={this.handleClose}
          endpoint={this.state.endpoint}
        />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 footer-logo">
              <img src="/images/footer_logo.png" alt="Footer Logo" />
              <p>
                Be sure to take a look at our Terms of Use and Privacy Policy
              </p>
            </div>
            <div className="col-md-5 row">
              <div className="col-md-6 links">
                <h2> Quick Links </h2>
                <ul>
                  <li>
                    <a href="#"> Report a Bug </a>
                  </li>
                  <li>
                    <Link
                      to={link}
                      state={{
                        isLoggedIn: isLoggedIn,
                        isFan: isFan,
                        isMember: isMember,
                      }}
                      className="nav-link"
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <a href="#"> Team </a>
                  </li>
                  <li>
                    <a href="#"> Career </a>
                  </li>
                  <li>
                    <a href="#"> Contact </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 links">
                <h2> Policies </h2>
                <ul>
                  <li>
                    <a onClick={this.showTnC}> Terms & Conditions </a>
                  </li>
                  <li>
                    <a onClick={this.showCopyrights}> Copyright </a>
                  </li>
                  <li>
                    <a onClick={this.showPrivacyPolicy}> Privacy Policy </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 links">
              <h2> Subscribe our Newsletters </h2>
              <form className="subscribe_form">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    placeholder="Enter your email"
                  />
                  <span className="input-group-btn">
                    <button className="btn btn-default" type="button">
                      Subscribe
                    </button>
                  </span>
                </div>
              </form>
              <div className="social-icons">
                <a href="#">
                  {" "}
                  <i className="fab fa-twitter"></i>{" "}
                </a>
                <a href="#">
                  {" "}
                  <i className="fab fa-facebook-square"></i>{" "}
                </a>
                <a href="#">
                  {" "}
                  <i className="fab fa-telegram-plane"></i>{" "}
                </a>
                <a href="#">
                  {" "}
                  <i className="fab fa-discord"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>© 2021 Tip Jar Live All Rights Reserved.</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
